console.log('./core/models.js');
import * as THREE   from "three";
import {FBXLoader}  from "three/examples/jsm/loaders/FBXLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {characters} from '../src/constants/characters';

const fbxLoader  = new FBXLoader();
const gltfLoader = new GLTFLoader();

let models = {};
export default function () {

      return (
        Promise.all(
          characters.map(
            model => ( new Promise(
              (res, rej) => {
                fbxLoader.load(`public/assets/characters/${model}.fbx`, obj => res(obj))
                // if (/\.fbx$/.test(model)) fbxLoader.load(`${window.location.pathname}public/assets/characters/${model}`, obj => res(obj))
                // else if (/\.glb$/.test(model)) gltfLoader.load(`${window.location.pathname}public/assets/characters/${model}`, obj => {
                //   console.log(obj)

                //   obj.scene.children[0].animations = obj.animations;

                //   obj.scene.children[0].traverse((child) => {
                //     if (child.isMesh && child.material) child.material.metalness = 0;
                //   })

                //   res(obj.scene.children[0])
                // })
                // else rej(model + " is not in a valid format")
              }
            )
            .then(
              (obj) => {
                models[model] = obj;
                obj.scale.set(0.008,0.008,0.008);
                obj.mixer   = new THREE.AnimationMixer( obj );
                obj.actions = {};

                obj.animations && obj.animations.forEach(
                  animation => {
                    const name = animation.name.split("|").pop();
                    obj.actions[name] = obj.mixer.clipAction(animation);
                    obj.actions[name].setLoop( THREE.LoopRepeat );
                    obj.actions[name].clampWhenFinished = true;
                  }
                )

                !obj.animations && console.log(obj)
                obj.getNewOne = function () { return obj; };

                return obj;
              }
            )
          )))
      )
}

export function getModels() {
  return models;
}
