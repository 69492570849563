console.log('./src/scene/colliders/special/index.js');
import StairsClass  from "./stairs";
import * as THREE   from "three";
import StageClass   from "./stage";
import RoomClass    from "./room";
import SeatClass    from "./seat";
import PortalClass  from "./portal";
import RotatedClass from "./rotated";


export const Stairs  = StairsClass;
export const Stage   = StageClass;
export const Room    = RoomClass;
export const Seat    = SeatClass;
export const Portal  = PortalClass;
export const Rotated = RotatedClass;

/*

export default [
  // Escalera principal
  new Stairs({
    min: new THREE.Vector3(-3.4, 0, -38.2),
    max: new THREE.Vector3( 3.4, 9.5, -28),
    direction: "-z",
  }),
  new Stairs({
    min: new THREE.Vector3(-9.85, 8.5, -54),
    max: new THREE.Vector3( -7.6, 11.6, -38.5),
    direction: "-x",
  }),
  new Stairs({
    min: new THREE.Vector3( 7.6, 8.5, -54),
    max: new THREE.Vector3(9.85, 11.6, -38.5),
    direction: "+x",
  }),
  // Escalera al Escenario
  new Stairs({
    min: { x: 20.5, z: 34.7, y: 0},
    max: { x: 23, z: 39.15, y: 3.3},
    direction: "-x",
  }),
  new Stairs({
    min: { x: -23, z: 34.7, y: 0},
    max: { x: -20.5, z: 39.15, y: 3.3},
    direction: "+x",
  }),
  new Stage({
    name: "main",
    min: { x: -16, z: -2, y: 0},
    max: { x: 16, z: 42, y: 5},
  }),
]

*/
