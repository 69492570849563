import React, { useState } from 'react';
import styled              from 'styled-components';

const Tab = styled.button`
  font-size: 20px;
  padding: auto;
  margin:auto;
  min-width: 6em;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

const keyDefaultData = {
  keyText: {
    title: "x",
    content: "y"
  }
};

function TabContainer({tabs}) {
  const [active,  setActive]  = useState( Object.values(tabs||keyDefaultData)[0] );
  const [tabKeys, setTabKeys] = useState( Object.keys  (tabs||keyDefaultData) );

  return (
    <>
      <ButtonGroup>
        {tabKeys.map(
          tabdata => (
            <Tab
              key    ={tabdata}
              active ={active === tabs[tabdata]}
              onClick={() => setActive(tabs[tabdata])}
            >
              {tabs[tabdata].title}
            </Tab>
          )
        )}
      </ButtonGroup>
      <div>{active.content}</div>
    </>
  );
}

export default TabContainer
