console.log('./ui/welcome/components/title.js');
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import texts from "../../text";

const titleText = texts.name;

export default ({ setFormMode }) => {
  const title    = useRef(null);
  const subTitle = useRef(null);
  const buttons  = useRef(null);

  useEffect(() => {

    const eachDelay = 100;
    let i   = 0,
        max = () => i >= titleText.length;

    let interval = setInterval(
      () => {
        if (max()) return clearInterval(interval);

        title.current.children[i].classList.add("appear");
        i++;
      },
      eachDelay
    );

    let timeout1 = setTimeout(
      () => {
        subTitle.current.classList.add("appear");
      },
      eachDelay*(titleText.length+4)
    );


    let timeout2 = setTimeout(
      () => {
        buttons.current.classList.add("appear");
      },
      eachDelay*(titleText.length+6)
    );

    return () => {
      clearInterval(interval);
      clearTimeout (timeout1);
      clearTimeout (timeout2);
    }
  }, [title]);

  function next(mode) {
    title   .current.classList.add("shrink");
    subTitle.current.classList.add("done");
    buttons .current.classList.add("done");

    setFormMode(mode);
  }

  return <div>
    <Title ref={title}> {
      titleText.split("").map(
        (letter, i) => (
          <Letter key={i}>{letter}</Letter>
        ))
    }</Title>
    <SubTitle ref={subTitle}>{texts.ui.forms.subtitle}</SubTitle>
    <ButtonContainer ref={buttons}>
      <Local onClick={() => next("local")}>Ingresar como invitado</Local>
      {/* <LogIn onClick={() => next("login")}>Usar cuenta</LogIn> */}
    </ButtonContainer>
  </div>
}

const Title = styled.h1`
  font-weight: 110;
  color: white;
  font-size: 85px;
  letter-spacing: 2.2px;
  margin-bottom: -99px;

  transition: transform 300ms ease-out;

  &.shrink {
    transform: translateY(-33vh) scale(0.8);
  }
`;

const Letter = styled.span`
  position: relative;
  display: inline-block;
  transform: translateY(35px);
  opacity: 0;

  transition: transform 300ms ease-out, opacity 300ms ease-out;

  &.appear {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const SubTitle = styled.h2`
  position: relative;
  display: inline-block;
  transform: translateX(-80px);
  opacity: 0;
  color: white;
  height: 2em;

  transition: transform 600ms ease-out, opacity 600ms ease-out;

  &.appear {
    transform: translateX(0px);
    opacity: 1;
  }

  &.done {
    transition-timing-function: ease-in;
    opacity: 0;
  }
`;

const Local = styled.button`
  background-color: #0000002b;
  border: none;
  padding: 14px 22px;
  border-radius: 100px;
  color: white;
  font-weight: bold;
  font-size: 17px;
  box-shadow: 0px 3px 13px 13px #0000000f;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const LogIn = styled(Local)`
  filter: brightness(1);
  background-color: transparent;
  box-shadow: none;
`;

const ButtonContainer = styled.div`
  transform: translateY(40px);
  opacity: 0;

  transition: transform 600ms ease-out, opacity 600ms ease-out;

  &.appear {
    transform: translateY(0px);
    opacity: 1;
  }

  &.done {
    transition-timing-function: ease-in;
    opacity: 0;
  }

  justify-content: center;
  display: flex;
  margin-top: 20px;
`;
