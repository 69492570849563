console.log('./core/player/positionCamera.js');
import { camera } from "../index";
import { getControl } from "../controls";

let controls = new getControl();

export default function () {

  if (process.env.VIEW == "FIRSTPERSON") {
    // this.position.set(0, 3.4, 0);
    // camera.direction3d.position.set(0,3.4,0);
    camera.position.set(0,3.4,0)
  } else {
    // this.position.set(0,0,0);
    camera.direction3d.lookAt(this.position);
    //
    camera.direction3d.position.x += -1.8;
    camera.direction3d.position.y += 2;
  }
}
