console.log('./core/player/handleDistantCharacters.js');
export default function (characters) {

  //console.log(characters
  //  .reduce((acc, {name}, i) => acc+ name && `${name} [${i < this.maximumActiveCharacters ? "✔️" : "❌"}], `),"")

  for (const index in characters) {
    const character = characters[index];
    
    if (index < this.maximumActiveCharacters) {
      character.restore && character.restore();
    }
    else {
      character.minify && character.minify();
    }
  }
}