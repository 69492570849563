console.log('./ui/welcome/components/form.js');
import React, { createRef, useEffect, useRef, useState, useCallback } from "react";
import styled  from "styled-components";
import { api } from "../../../src/helpers/serverApi";
import texts   from "../../text";

import { validateGuest, validateRegister, validateLogin } from "../validations";

export default ({ mode, setUserData }) => {

  const [login, setLogin]       = useState(true);
  const [error, setError]       = useState(null);
  const [reselect, setReselect] = useState(false);
  const [loading, setLoading]   = useState(false);

  const loginForm    = useRef(null);
  const registerForm = useRef(null);
  const formRef      = useRef({
    name            : createRef(),
    email           : createRef(),
    loginEmail      : createRef(),
    loginPassword   : createRef(),
    registerName    : createRef(),
    registerEmail   : createRef(),
    registerPassword: createRef(),
    confirmPassword : createRef()
  });

  function setForm (dissapear = false) {
    console.log(`setForm ${dissapear}`);
    setError(null);

    const eachDelay = 100;
    let   i         = 0;

    const interval  = setInterval(
      () => {
        if (login ^ dissapear) { // XOR

          const registerLength = registerForm.current?.children.length || 0;
          console.log({registerLength})
          if (i < registerLength)
            registerForm.current.children[i].classList.remove("appear");
          else if (!dissapear && i - registerLength < loginForm.current.children.length)
            loginForm.current.children[i - registerLength].classList.add("appear");
          else
            clearInterval(interval);

        } else {

          if (i < loginForm.current.children.length)
            loginForm.current.children[i].classList.remove("appear");
          else if (!dissapear && i - loginForm.current.children.length < registerForm.current.children.length)
            registerForm.current.children[i - loginForm.current.children.length].classList.add("appear");
          else
            clearInterval(interval);

        }

        i++;
      },
      eachDelay
    ); // const interval  = setInterval(
  } // function setForm (dissapear = false) {




  useEffect(setForm, [login, loginForm, registerForm]);

  useEffect(() => { setTimeout(setForm, 1000) }, [loginForm, registerForm]);

  function register(data) {
    return new Promise((res, rej) => {
      const errors = validateRegister(data);

      if (errors) {
        setError(errors);
        return rej(errors);
      }

      setLoading(true);

      console.log("REGISTER fetch ", `${api}/users/register`);
      fetch(
        `${api}/users/register`,
        {
          method : "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            username: data.registerName,
            email   : data.registerEmail,
            password: data.registerPassword,
          })
        }
      )
      .then(data => {
        return data.json()
          .then((result) => [
            result,
            data.headers.get("auth-token")
          ]);
      })
      .then(([result, token]) => {
        console.log("Then",result);
        if (result.error == "email-already-in-use") {
          setError({registerEmail: texts.ui.forms.errors.emailInUse});
          return rej();
        }
        if (token) window.sessionStorage.setItem("auth-token", token);
        res(result.user);
      })
      .catch((err) => {
        console.log("Error",err);
        rej(err);
      })
    });
  }

  function loginUser(data) {
    return new Promise((res, rej) => {
      const errors = validateLogin(data);

      console.log(data, errors);

      if (errors) {
        setError(errors);
        return rej(errors);
      }

      console.log("LOGIN fetch ", `${api}/users/login`);
      fetch(
        `${api}/users/login`,
        {
          method : "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email   : data.loginEmail,
            password: data.loginPassword,
          })
        }
      )
      .then(data => data.json())
      .then(({token, user, error }) => {
        if (error == "wrong-credentials") {
          setError({loginPassword: texts.ui.forms.errors.wrongCredentials});
          return rej(error);
        }
        window.sessionStorage.setItem("auth-token", token);
        res(user);
      })
      .catch((err) => {
        console.log("Error",err);
        rej(err);
      });
    });
  }

  function localUser (data) {
    return new Promise((res, rej) => {
      const errors = validateGuest(data);

      console.log(data, errors);

      if (errors) {
        setError(errors);
        return rej(errors);
      }

      res(data);
    });
  }

  const next = useCallback((ev) => {
    ev.preventDefault();

    const data = Object.keys(formRef.current).reduce((acc, key) => {
      if (formRef.current[key]?.current?.value) return {
        ...acc,
        [key]: formRef.current[key]?.current?.value
      }
      return acc;
    }, {});

    let auth;

    if (mode == "local") auth = localUser;
    else                 auth = login ? loginUser : register;

    console.log(auth.name)

    auth(data)
    .then((user) => {
      setForm(true);
      setLoading(false);
      setTimeout(() => {
        if (registerForm.current) registerForm.current.style.display = "none";
        loginForm.current.style.display = "none";

        if (reselect && login) user.model = null;

        setUserData(user);
      }, 2000);
    })
    .catch( () => setLoading(false) );

  }, [ login, mode, formRef, reselect ]); //const next = useCallback((ev) => {


  if (mode == "local") return (
    <Wrapper>
      <Form className="left" ref={loginForm} onSubmit={next}>
        <h1>{texts.ui.forms.titles.guest}</h1>
        <Field placeholder={texts.ui.forms.labels.email}    name="email" formRef={formRef} error={error} setError={setError}/>
        <Field placeholder={texts.ui.forms.labels.userName} name="name"  formRef={formRef} error={error} setError={setError} />
        <div><Submit type="submit" value={texts.ui.forms.labels.send} /></div>
      </Form>
    </Wrapper>
  )

  return <Wrapper>
      <Form className="left" ref={loginForm} onSubmit={next}>
        <h1>{texts.ui.forms.titles.login}</h1>
        <div><p onClick={() => setLogin(false)}>{texts.ui.forms.createAccount}</p></div>
        <Field placeholder={texts.ui.forms.labels.email}    name="loginEmail"    formRef={formRef} error={error} setError={setError}/>
        <Field placeholder={texts.ui.forms.labels.password} name="loginPassword" formRef={formRef} error={error} setError={setError} type="password"/>
        <div><CheckboxLabel><Checkbox type="checkbox" checked={reselect}  onClick={() => setReselect(c => !c)}/>{texts.ui.forms.labels.chooseModelAgain}</CheckboxLabel></div>
        <div><Submit type="submit" value={texts.ui.forms.labels.send} /></div>
      </Form>

      <Form className="right" ref={registerForm} onSubmit={next}>
        <h1>{texts.ui.forms.titles.register}</h1>
        <p onClick={() => setLogin(true)}>{texts.ui.forms.loginAccount}</p>
        <Field placeholder={texts.ui.forms.labels.email}    name="registerEmail"    formRef={formRef} error={error} setError={setError}/>
        <Field placeholder={texts.ui.forms.labels.userName} name="registerName"     formRef={formRef} error={error} setError={setError}/>
        <Field placeholder={texts.ui.forms.labels.password} name="registerPassword" formRef={formRef} error={error} setError={setError} type="password"/>
        <Field placeholder="Confirmar contraseña"           name="confirmPassword"  formRef={formRef} error={error} setError={setError} type="password"/>
        <div><Submit type="submit" value={texts.ui.forms.labels.send} disabled={loading}/></div>
      </Form>
  </Wrapper>
}

const Field = ({name, formRef, placeholder, error, setError, type }) => {

  return <>
    <Input
      placeholder={placeholder}
      ref        ={formRef.current[name]}
      onChange   ={ () => error && error[name] && setError(null)}
      type       ={type || "text"}
    />
    { error && error[name]
      ? <ErrorText className="appear">{error[name]}</ErrorText>
      : null }
  </>
}

const Wrapper = styled.div`
  position: absolute;
  width: 97vw;
  max-width: 400px;
  height: 50vh;
`;


const Form = styled.form`

  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;

  position: absolute;

  &.left {
    transform: translateX(-100%);
  }

  &.right {
    transform: translateX(100%);
  }

  & > * {
    opacity: 0;
    transform: translateX(0px);
    transition: transform 600ms ease-out, opacity 600ms ease-out;
    width: 100%;
    color: white;
    pointer-events:none;
  }

  &.left > *.appear {
    transform: translateX(100%);
  }

  &.right > *.appear {
    transform: translateX(-100%);
  }


  & > *.appear {
    opacity: 1;

    pointer-events: all;
  }
`;

const Input = styled.input`
  border: none;
  width: 100%;
  background-color: #0000002b;
  margin: 0px;
  margin-bottom: 24px;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 20px 36px;
  font-size: 18px;

  &::placeholder {
    color: #ffffff6e;
  }

  &:focus {
    outline: none;
  }
`;

const Submit = styled.input`
  background-color: #0000002b;
  border: none;
  padding: 14px 22px;
  border-radius: 100px;
  font-weight: bold;
  font-size: 17px;
  box-shadow: 0px 3px 13px 13px #0000000f;
  cursor: pointer;
  color: white;

  width: auto;
  margin: auto;

  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.p`
  font-size: 16px;
  color: red;
  margin: -16px 0px 10px 24px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: -12px;
  user-select: none;
  cursor: pointer;
`;

const Checkbox = styled.input`
  height: 20px;
  width: 20px;
`;
