console.log('./src/scene/colliders/special/room.js');
import * as THREE from "three";
import getSocket from "../../../../core/socket";

export default class Room extends THREE.Box3 {
  constructor({name, colliders, min, max}) {

    if (colliders) {
      const points = colliders
        .reduce((acc, box) => [
          ...acc, 
          new THREE.Vector3(
            box.min.x, 
            box.min.y, 
            box.min.z
          ),
          new THREE.Vector3(
            box.max.x, 
            box.max.y, 
            box.max.z
          ),
        ], [])
      
      console.log(points)

      super();


      this.setFromPoints(points);

      this.colliders = colliders.map((box) => {
        const collider = new THREE.Box3(box.min, box.max)

        collider.through = () => {
          collider.inside = true;
          return true;
        }
        collider.left = () => collider.inside = false;

        return collider;
      });

    }
    else {
      super(min, max);
    }
    this.name = name;
    this.inside = false;
    this.socket = null;
    this.type = "room";

  }

  through(player) {

    if (!this.socket) this.socket = getSocket();
    if (!this.socket) return;

    // if (this.inside) this.socket.emit("enterroom", this.name);
    // this.socket.emit("leaveroom", this.name);

    if (!player.rooms) player.rooms = [];

    if (this.colliders) {
      if (this.colliders.some(collider => collider.inside)) {
        if (!this.inside) {
          this.socket.emit("enterroom", this.name);
          player.rooms.push(this.name);
          this.inside = true;
        }
      } else {
        if (this.inside) {
          this.socket.emit("leaveroom", this.name);
          player.rooms = player.rooms.filter(n => n != this.name);
          this.inside = false;
        }
      }
    }
    else if (!this.inside){
      this.inside = true;
      this.socket.emit("enterroom", this.name);
      player.rooms.push(this.name);
    }

    return true;
  }
  
  left(player) {
    
    
    if (!this.inside) return;

    this.socket.emit("leaveroom", this.name);
    player.rooms = player.rooms.filter(n => n != this.name);
    this.inside = false;
  }
}