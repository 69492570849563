console.log('./src/scene/decorations/item.js');
import * as THREE from "three";

import { scene, camera } from "../../../core";
import { addToTick } from "../../../core/tick";
import getPlayer from "../../../core/player";

import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

import { addSpecialCollider } from "../colliders";

import UiComponent from "../../../ui";
import ItemUi from "../../../ui/item";

class Item extends THREE.Box3 {
  constructor(data) {

    const boxSize = 16;
    
    super(
      new THREE.Vector3(data.x - boxSize, data.y - boxSize + data.iconHeight || 0, data.z - boxSize),
      new THREE.Vector3(data.x + boxSize, data.y + boxSize + data.iconHeight || 0, data.z + boxSize),
    );

    this.type = "item";

    this.position = new THREE.Vector3(data.x, data.y, data.z);
    this.rotateAxis = data.rotate;
    this.focused = false;
    this.focusedSize = data.focusedSize || data.size || 1;
    this.focusedHeight = data.focusedHeight || 0;
    this.iconHeight = data.iconHeight || 0;

    this.description = data.description;
    this.title = data.title;
    this.src = data.src;
    this.video = data.video;
    this.elements = data.elements;
    this.more = data.more;

    const textureLoader = new THREE.TextureLoader();
    const objLoader = new OBJLoader();
    const fbxLoader = new FBXLoader();
    const mtlLoader = new MTLLoader();
    const gltfLoader = new GLTFLoader();



    this.tick = this.tick.bind(this);
    this.left = this.left.bind(this);
    this.focus = this.focus.bind(this);
    this.unfocus = this.unfocus.bind(this);
    this.rotate = this.rotate.bind(this);

    addSpecialCollider(this);

    this.icon = document.createElement("img");

    if (data.video) this.icon.src = window.location.pathname+"public/icons/play.png";
    else this.icon.src = window.location.pathname+"public/icons/see.png";

    this.icon.classList.add("icon");
    this.icon.style.display = "none";
    this.icon.setAttribute("draggable", false)

    document.getElementById("app").insertAdjacentElement("beforeend", this.icon);

    this.icon.addEventListener("mousedown", this.focus);

    if (!data.src) return this;

    const ext = data.src.slice(-3);

    if (ext == "jpg") {

      textureLoader.load(data.src, (texture) => {

        const aspectRatio = texture.image.width / texture.image.height

        this.object = new THREE.Mesh(
          new THREE.PlaneGeometry(aspectRatio,1),
          new THREE.MeshBasicMaterial({map:texture, side: THREE.DoubleSide})
        );
        
        this.object.rotation.y = data.rotation;
        
        this.object.position.copy(this.position);
        
        this.object.scale.set(data.size, data.size, data.size)
        
        scene.add(this.object);
      })
    }
    else if (ext == "glb" || ext == "fbx" || ext == "obj") {
      let load;

      if (ext == "obj") {
        load = new Promise((res, rej) => {
          mtlLoader.load(data.src.replace(".obj",".mtl"), (material) => {
            objLoader.setMaterials(material);
            objLoader.load(data.src, res)
          });
        });
      } 
      else if (ext == "fbx") {
        load = new Promise((res, rej) => fbxLoader.load(data.src, res));
      }
      else if (ext == "glb") {
        load = new Promise((res, rej) => gltfLoader.load(data.src, model => res(model.scene)));
      }

      load.then((object) => {
        this.object = object;
        this.object.position.copy(this.position);
        this.object.rotation.set(0,data.rotation,0);
        this.object.scale.set(data.size, data.size, data.size)

        
        scene.add(this.object);
      })
    }
  }

  through () {
    return true;
  }

  tick (player, { frustum }) {

    const iconVector = this.position.clone();

    iconVector.y += this.iconHeight;

    if(!this.focused && frustum && frustum.containsPoint(iconVector)) {
      
      iconVector.project(camera);
      const x = (iconVector.x *  .5 + .5) * window.innerWidth;
      const y = (iconVector.y * -.5 + .5) * window.innerHeight;

      this.icon.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
      this.icon.style.display = "";
    } else {
      this.icon.style.display = "none";

    }
  }


  left () {
    this.icon.style.display = "none";
  }

  focus (e) {

    this.startX = e.clientX;
    this.startY = e.clientY;

    const player = getPlayer();
    player.disableMovement(true);

    this.ui = new UiComponent(document.getElementById("app"), ItemUi, {
      init: () => ({ 
        description: this.description,
        src: this.src,
        video: this.video,
        title: this.title,
        more: this.more,
        elements: this.elements,
      }),
      unfocus: () => {
        this.unfocus();
      }
    });    

    this.focused = true;

    if (process.env.VIEW == "FIRSTPERSON") {
      player.lookAround = false;
      //this.startX = 0;
      //this.startY = 0;
    }


    if (!this.object || this.video || this.elements) return;

    this.target = this.object.clone();
    this.object.visible = false;

    if (process.env.VIEW == "FIRSTPERSON") {
      player.lookAround = false;

      let focusedVec = player.camera.localToWorld(new THREE.Vector3(0.35,0,-1));

      focusedVec.y += this.focusedHeight;

      // const playerCameraDistTo = player.camera.position.distanceToSquared
      //   .bind(player.camera.position);
      
      // let targetVec = focusedVec;
      // if (playerCameraDistTo(focusedVec) < playerCameraDistTo(this.position)) {
      //   targetVec = this.position;
      //   console.log("cerquita")
      // }


      this.target.position.copy(focusedVec);
      this.target.rotation.y = player.camera.rotation.y - Math.PI/2;
    }
    else {
      this.target.position.copy(player.localToWorld(new THREE.Vector3(-1.8, 2.5, -2)));
      this.target.rotation.y = player.rotation.y - Math.PI;
    }

    this.target.scale.set(this.focusedSize*0.5, this.focusedSize*0.4, this.focusedSize*0.4);

    scene.add(this.target);

    
    if (this.rotateAxis) window.addEventListener("mousemove", this.rotate)



    

  }

  rotate (e) {
    const player = getPlayer();

    const x = e.clientX;
    const y = e.clientY;
    
    let xRot = this.target.rotation.y;

    // if (process.env.VIEW == "FIRSTPERSON") {
    //   xRot += (e.movementX || e.mozMovementX || e.webkitMovementX || 0)/1000;
    // } else {
    this.target.rotation.y = player.camera.rotation.y - Math.PI/2 + (x - this.startX)*(Math.PI/(0.5*window.innerWidth));
    //}

    //this.target.rotation.y = xRot;

    // if (this.rotateAxis.includes("y")) {
    //   this.target.rotation.z = Math.sin(this.target.rotation.y) * deltaY + Math.cos(this.target.rotation.y) * deltaY;
    //   this.target.rotation.x = Math.sin(this.target.rotation.y) * deltaY + Math.cos(this.target.rotation.y) * deltaY;
    // }
  }

  unfocus () {
    this.focused = false;
    const player = getPlayer();
    player.disableMovement(false);

    if (this.target && this.object) { 
      scene.remove(this.target);
      this.object.visible = true;
    }


    if (this.rotateAxis) window.removeEventListener("mousemove", this.rotate)

    if (process.env.VIEW == "FIRSTPERSON") {
      player.lookAround = true;
    }

    this.ui && this.ui.close();
    this.ui = null;
  }
}

export default Item;