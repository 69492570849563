console.log('./core/teletransport.js');
import * as THREE from "three";

import { scene, camera, player, origin } from "../core";

let ray         = null;
let plane       = null;
let destination = null;
let cursor      = null;

const textureLoader = new THREE.TextureLoader();

textureLoader.load(window.location.pathname+"public/assets/fade.jpg", (texture) => {
  cursor = new THREE.Mesh(
    new THREE.CylinderGeometry(1, 1, 2, 12, 1, true),
    new THREE.MeshBasicMaterial({ color: 0x5bff58, side: THREE.DoubleSide, alphaMap: texture, transparent: true, depthTest: false })
  );
})

let target = new THREE.Vector3(0,0.1,0);
let vec    = new THREE.Vector3();

function checkColliders(colliders, distance, targetVec) {
  target.set(0,0.1,0).add(targetVec);
  for (let collider of colliders) {
    if (    collider.type == "room"
        ||  collider.type == "stage"
        ||  collider.type == "decoration"
    ) continue;

    ray.intersectBox(collider, vec);

    if (vec) {

      if (collider.colliders) {
        if(!checkColliders(collider.colliders, distance, targetVec)) return false;
        else continue;
      }

      if (collider.type == "stairs") {
        if (collider.containsPoint(target)) {
          return false;
        } else continue;
      }

      const dist = ray.origin.distanceTo(vec);
      if (dist < distance) return false;
    }
  }
  return true;
}

export default function (target) {
  if (!ray) {
    ray = new THREE.Ray();
    window.ray= ray
  }

  ray.set(
    camera.getWorldPosition (new THREE.Vector3()),
    camera.getWorldDirection(new THREE.Vector3())
  );

  let { intersection, distance } = this.floors.reduce(
    (acc, floor) => {
      ray.intersectBox(floor, vec);

      if (!vec) return acc;

      const dist = ray.origin.distanceTo(vec);

      if (vec.y > ray.origin.y) return acc;

      if (dist > 40) return acc;

      if (dist < acc.distance) {
        floor.distance = dist;
        floor.intersection = vec;
        return floor;
      }

      return acc;

    },
    { distance: Infinity, intersection: null }
  );

  if (  intersection
    &&  !checkColliders.call(
          this,
          this.colliders,
          distance,
          intersection
        )
  ) intersection = null;

  if (intersection && !destination) {
    destination = cursor;
    // change to move world instead of camera
    scene.add(destination);
    // origin.add(destination);
  }

  if (intersection) {
    destination.position.copy(intersection);
  } else if (destination) {
    scene.remove(destination)
    destination = null;
  }

}

export function confirm() {
  if (destination) {
    this.position.copy(destination.position);

    this.colliderCheck();

    this._teletransported = true;

    this._emittedIdle = false;

    scene.remove(destination);
    destination = null;
  }
}
