console.log('./core/index.js');
import * as THREE from 'three';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

import { animate } from "./tick";
import playerObj from "./player";

export const player = playerObj;

export const container = document.getElementById("renderer");
export const camera    = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 1000 );
export const renderer  = new THREE.WebGLRenderer({antialias:true,   powerPreference: "high-performance", });
export const scene     = new THREE.Scene();
export const dolly    = new THREE.Object3D();

dolly.add(camera);
dolly.add(player);

const cube = new THREE.Mesh(
  new THREE.BoxGeometry( 1, 1, 1 ),
  new THREE.MeshBasicMaterial( {color: 0x00ff00} )
);
dolly.add( cube );
scene.add(dolly)
// export const origin    = new THREE.Object3D();
// export const pivot     = new THREE.Object3D();
// export const scene     = new THREE.Object3D();

/* these are the relevant positions
  camera position is the actual THREEjs camera which can be modified when entering in VR
  player position is the mesh object which keeps the backend in game position
  origin position is the 3d point which keeps the whole scene's position
  pivot rotation  is the rotation of the world relative to the camera, so that the player can look to the sides without the world changing drastically

*/
// camera.direction3d = new THREE.Object3D();
// camera.direction3d.position.y=3;
// camera.direction3d.add(camera);


/*

const cube = new THREE.Mesh(
  new THREE.BoxBufferGeometry(2, 2, 2),
  new THREE.MeshBasicMaterial()
);

origin.add(cube.clone());

pivot.rotation.set(0,0,1);
pivot.add(cube.clone());
pivot.add(origin);
scene.add(pivot);

// change to move world instead of camera
// pivot.add(origin);
// scene.add(origin);

// scene.add hijack hax to make world group -- does not work
scene.scene_add = scene.add;
scene.add = function (obj){
  // console.trace("Added obj");
  origin.add(obj);
}
*/

// realscene.add(origin);
// origin.add(camera.direction3d);


export function init() {

  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight);
  renderer.setClearColor(0xb2f2ff, 1);

  renderer.xr.enabled = true;
  if(renderer.xr.enabled === true) renderer.setAnimationLoop( animate );

  container.appendChild( renderer.domElement );
  document.body.appendChild( VRButton.createButton( renderer ) );

  window.addEventListener( 'resize', onWindowResize, false );
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize( window.innerWidth, window.innerHeight );
}
