console.log('./src/scene/colliders/special/seat.js');
import * as THREE from "three";

import { scene, camera } from "../../../../core";
import { addToTick } from "../../../../core/tick";
import getPlayer from "../../../../core/player";
import { getControl } from "../../../../core/controls";

import interpolate, { interpolateRot } from "../../../../utils/interpolate";

export default class Seat extends THREE.Box3 {
  constructor(data) {

    const boxSize = 1.2;

    const xOffset = 0.7*Math.sin(data.rotation);
    const zOffset = 0.7*Math.cos(data.rotation);

    super(
      new THREE.Vector3(data.x - boxSize + xOffset, data.y - boxSize, data.z - boxSize + zOffset),
      new THREE.Vector3(data.x + boxSize + xOffset, data.y + boxSize, data.z + boxSize + zOffset),
    );

    this.type = "seat";


    this.colliders  = [
      new THREE.Box3(
        new THREE.Vector3(data.x - 0.4, data.y - 1.7, data.z - 0.4),
        new THREE.Vector3(data.x + 0.4, data.y, data.z + 0.4),
      )
    ];

    this.position = new THREE.Vector3(data.x, data.y, data.z);
    this.rotation = data.rotation || 0;

    this.delta = 0;

    this.tick = this.tick.bind(this);
    this.left = this.left.bind(this);
    this.focus = this.focus.bind(this);
    this.unfocus = this.unfocus.bind(this);


    this.icon = document.createElement("img");
    this.icon.src = window.location.pathname+"public/icons/sit.png";
    this.icon.classList.add("icon");
    this.icon.style.display = "none";
    this.icon.setAttribute("draggable", false)

    document.getElementById("app").insertAdjacentElement("beforeend", this.icon);

    this.icon.addEventListener("mousedown", this.focus);

    return this;
  }

  through () {
    return true;
  }

  tick (player, { frustum, delta }) {

    const iconVector = this.position.clone();

    if(!this.focused && frustum && frustum.containsPoint(iconVector)) {

      iconVector.project(camera);
      const x = (iconVector.x *  .5 + .5) * window.innerWidth;
      const y = (iconVector.y * -.5 + .5) * window.innerHeight;

      this.icon.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
      this.icon.style.display = "";
    } else {
      this.icon.style.display = "none";

    }


    if (this._to && this.delta < this._to.in) {
      this.delta += delta;

      if (this._to.lat !== undefined) {
        var latVal = THREE.MathUtils.radToDeg(interpolateRot(this._from.lat, this._to.lat, this.delta, this._to.in));
        this.controls.setLat(latVal, false);
      }

      if (this._to.lon !== undefined) {
        var lonVal = THREE.MathUtils.radToDeg(interpolateRot(this._from.lon, this._to.lon, this.delta, this._to.in));
        this.controls.setLon(lonVal, false);
      }

      player.position.x = interpolate(this._from.x, this._to.x, this.delta, this._to.in);
      player.position.y = interpolate(this._from.y, this._to.y, this.delta, this._to.in);
      player.position.z = interpolate(this._from.z, this._to.z, this.delta, this._to.in);

      //********
      // player.camera.position.y = interpolate(this._from.cameraY, this._to.cameraY, this.delta, this._to.in);

      player._emittedIdle = false;

      if (this.focused && this.delta > 0.6) player.animation = {
        name: "sit",
        timeScale: 1,
      };


      if (this.delta >= this._to.in) {

        if (this._to.lat !== undefined) this.controls.setLat(THREE.MathUtils.radToDeg(this._to.lat));
        if (this._to.lon !== undefined) this.controls.setLon(THREE.MathUtils.radToDeg(this._to.lon));

        player.lookAround = true;



        if (this.focused) {
          player.animation = {
            name: "sitting",
            timeScale: 1,
          };

        }

        if (!this.focused) {
          player.animation =  null;

          player.disableMovement(false);
        }
      }
    }
  }


  left () {
    this.icon.style.display = "none";
  }

  focus (e) {
    const player = getPlayer();

    this.controls = new getControl();
    this.focused = true;

    console.log("sitting down!")

    player.lookAround = false;
    player.disableMovement(true);

    player.animation = {
      name: "walk",
      timeScale: 1,
    };

    //this.controls.setLat(0);
    //this.controls.setLon(90);

    window.controls = this.controls;

    this.delta = 0;

    this._from = {
      lat: THREE.MathUtils.degToRad(this.controls.getLat()),
      lon: THREE.MathUtils.degToRad(this.controls.getLon()),
      x: player.position.x,
      y: player.position.y,
      z: player.position.z,
      cameraY: player.camera.position.y,
    };

    this._to = {
      lon: this.rotation + Math.PI/2,
      lat: 0,
      x: this.position.x,
      y: this.position.y - 1.2,
      z: this.position.z,
      cameraY: player.camera.position.y - 0.8,
      in: 1.6,
    }

    window.addEventListener("keyup", this.unfocus)

  }


  unfocus (e) {
    if (e.key !== "w" && !e.key == "a" && !e.key == "s" && !e.key == "d") return;

    const player = getPlayer();

    if (player.focusingPesentation) return;

    window.removeEventListener("keyup", this.unfocus);

    console.log("UP!")




    this.focused = false;


    player.lookAround = false;

    //this.controls.setLat(0);
    //this.controls.setLon(90);

    window.controls = this.controls;

    this.delta = 0;

    const oldYVal = this._from.y;
    const oldCameraYVal = this._from.cameraY;

    this._from = this._to;
    this._from.lat = THREE.MathUtils.degToRad(this.controls.getLat());
    this._to = {
      x: this.position.x + 1.4*Math.sin(this.rotation),
      y: oldYVal,
      z: this.position.z + 1.4*Math.cos(this.rotation),
      lat: 0,
      cameraY: oldCameraYVal,
      in: 0.3,
    };

    player.animation = {
      name: "sit",
      timeScale: -1.6,
    };
  }



}
