console.log('./ui/welcome/validations.js');
import texts from "../text";

export function validateGuest (data) {
  switch (true) {
    case !data.name            : return { name : texts.ui.forms.errors.required};
    case data.name.length < 3  : return { name : texts.ui.forms.errors.shortName};
    case data.name.length >= 15: return { name : texts.ui.forms.errors.longName};
    case !data.email           : return { email: texts.ui.forms.errors.required};
    case !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
          .test(data.email)    : return { email: texts.ui.forms.errors.invalidEmail};
    default                    : return null;
  }
};

export function validateLogin (data) {
  switch (true) {
    case !data.loginEmail       : return {loginEmail   : texts.ui.forms.errors.required};
    case !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
          .test(data.loginEmail): return {loginEmail   : texts.ui.forms.errors.invalidEmail};
    case !data.loginPassword    : return {loginPassword: texts.ui.forms.errors.required};
    default                     : return null;
  }
};


export function validateRegister (data) {
  switch (true) {
    case !data.registerEmail              : return {registerEmail   : texts.ui.forms.errors.required};
    case !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
          .test(data.registerEmail)       : return {registerEmail   : texts.ui.forms.errors.invalidEmail};

    case !data.registerName               : return {registerName    : texts.ui.forms.errors.required};
    case data.registerName.length < 3     : return {registerName    : texts.ui.forms.errors.shortName};
    case data.registerName.length >= 15   : return {registerName    : texts.ui.forms.errors.longName};

    case !data.registerPassword           : return {registerPassword: texts.ui.forms.errors.required};
    case data.registerPassword.length < 8 : return {registerPassword: texts.ui.forms.errors.shortPassword};
    case data.registerPassword.length > 50: return {registerPassword: texts.ui.forms.errors.longPassword};

    case !data.confirmPassword            : return {confirmPassword : texts.ui.forms.errors.required};
    case (data.confirmPassword !== data.registerPassword)
                                          : return {confirmPassword : texts.ui.forms.errors.passwordMissmatch};
    default                               : return null;
  }
}
