console.log('./ui/stand/video.js');
import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";

export default function ({ callbacks, parent }) {

  const [data, setData] = useState(null);
  const descRef = useRef(null);

  useEffect(() => {
    const data = callbacks.init();
    setData(data);
    console.log(descRef.current)
    if (descRef.current) descRef.current.innerHTML = data.description;
  }, [descRef]);

  if (!data) return null;

  function stop() {
    callbacks.stop();
    console.log("calling callbacks.top")
    parent.close();
  }

  return (
    <Wrapper>
      <Background>
        <Centered>
          <PlayIcon src="public/icons/close.png" onClick={stop}/>
          <Title>{data.title}</Title>
        </Centered>
        <Description>{data.description}</Description>
      </Background>
      <VideoPlayer 
        crossorigin="annonymus" 
        autoPlay 
        src={data.url} 
        controls
        >
        Your browser does not support the video tag.
      </VideoPlayer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  width: 87vh;
  left: calc(50vw - 43vh);
  bottom: 24vh;
  color: white;
`;

const Background = styled.div`
  padding: 5px;
  border-radius: 3px;
  background-color: #00000066;
  margin-bottom: 12px;
`;

const Title = styled.h1`
  font-size: 36px; 
  margin: 2px 0px;
  font-weight: 100;

`;

const Centered = styled.div`
  display: flex;
  margin-top: 12px;
  align-items: center;
`;

const Description = styled.p`
  font-size: 18px;
  flex: 1;
`;

const PlayIcon = styled.img`
  height: 28px;
  width: 28px;
  padding: 12px;
  background-color: #00000022;
  border-radius: 60px;
  margin-right: 12px;

  &:hover {
    filter: brightness(0.6);
  }
`;

const VideoPlayer = styled.video`  
  display: block;
  border: solid 8px black;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  min-height: 50vh;
`;
