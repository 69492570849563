console.log('./core/player/input.js');
import intoDomain     from "../../utils/intoDomain";
import { getControl } from "../controls";

import * as inputController       from "../inputController";
import { inputdevice_events }     from "../../utils/constants";
import { addEvent, removeEvent }  from "../events";
import { dolly }                  from "../index";

if (!process.env.FPS || isNaN(process.env.FPS)) throw new Error(".env FPS is not a valid number");

const speed    = (0.03*60)/process.env.FPS;
const controls = new getControl();


/* polling function called from tick each frame update */
export default function () {

  if(!this._receiveInput) return;

  inputController.poll_gamepads();
  let action = inputController.get_state();

  if (process.env.DEBUG == "true") {
    if (this._keys["shift"]) this._dash = true;
    else this._dash = false;
  }

  if(action.rotateview) {
    // console.log("roting", action.rotateview);
    controls.setLon(controls.getLon() + 5 * (action.rotateview));
  }

  // if(   process.env.VIEW == "FIRSTPERSON"
  //   &&  (     action.updown    !==0
  //         ||  action.leftright !==0
  //       )
  // ){
  //   this._moving       = true;
  //   this._fakeRotation = 0;
  //
  //   this.camera.direction3d.position.z += Number(action.updown   ||0)*0.1;
  //   this.camera.direction3d.position.x += Number(action.leftright||0)*0.1;
  //
  //
  //   if (this.controls == "mouse_rotate" || process.env.VIEW == "FIRSTPERSON") {
  //     // mouse camera movement
  //
  //     this.moveForward(
  //       Math.atan2(
  //         action.leftright||0,
  //         action.updown   ||0
  //       ),
  //       1
  //     );
  //
  //   } else {
  //
  //     if (!this._fakeRotation) {
  //       this._realRotation = this.rotation.y;
  //       this._fakeRotation = 0;
  //     }
  //
  //     this._fakeRotation -= speed * -sideWays;
  //     this._fakeRotation %= 2*Math.PI;
  //
  //
  //     this.rotation.y = this._realRotation + this._fakeRotation;
  //
  //     if (process.env.VIEW !== "FIRSTPERSON") {
  //
  //       // this.model.rotation.y += speed * -sideWays;
  //       // this.model.rotation.y %= 2*Math.PI;
  //       this.camera.direction3d.rotation.y += speed * -sideWays;
  //       this.camera.direction3d.rotation.y %= 2*Math.PI;
  //     }
  //
  //
  //     //this.rotation.y = intoDomain(this.rotation.y + (speed * sideWays));
  //
  //
  //     this.moveForward(0, 1);
  //   }
  //
  //
  //   if (this._emittedIdle && process.env.VIEW != "FIRSTPERSON") {
  //     this.fadeToAction("walk");
  //   }
  //
  //   this._emittedIdle = false;
  //   if(this.onMove) this.onMove.call(this.camera.direction3d);
  // } else if (!this._emittedIdle) {
  //   this._moving      = false;
  //   this._emittedIdle = true;
  //
  //   if(this.onMove) this.onMove.call(this.camera.direction3d);
  //   // this.onMove
  //   // && typeof this.onMove == "function"
  //   // && setTimeout(
  //   //   () => this.onMove.call(this),
  //   //   251
  //   // );
  //
  //   if (process.env.VIEW != "FIRSTPERSON") this.fadeToAction("idle");
  // }
  // //
  // return;

  if( action.updown || action.leftright){
      // console.log("input move", action.updown, action.leftright)
      this._moving       = true;
      this._fakeRotation = 0;

      let frontWays =  action.updown;
      let sideWays  = -action.leftright;
      let angle;

      if (this.controls == "mouse_rotate" || process.env.VIEW == "FIRSTPERSON") {
        // mouse camera movement

        this.moveForward(
          Math.atan2(
            action.leftright,
            action.updown
          ),
          1
        );
        // let angle = Math.atan2(
        //   action.updown,
        //   action.leftright
        // );

        // this.camera.direction3d.position.z += ((action.updown*0.1) * (this.camera.direction3d.rotation.z));
        // this.camera.direction3d.position.x += ((action.updown*0.1) * (this.camera.direction3d.rotation.x));


      } else {
        //
        // if (!this._fakeRotation) {
        //   this._realRotation = this.camera.direction3d.rotation.y;
        //   this._fakeRotation = 0;
        // }
        //
        // this._fakeRotation -= speed * -sideWays;
        // this._fakeRotation %= 2*Math.PI;
        //
        //
        // // this.camera.direction3d.rotation.y = this._realRotation + this._fakeRotation;
        // pivot.rotation.y = this._realRotation + this._fakeRotation;

        if (process.env.VIEW !== "FIRSTPERSON") {

          dolly.rotation.y += speed * -sideWays;
          dolly.rotation.y %= 2*Math.PI;

          //before origin hax
          // this.camera.direction3d.rotation.y += speed * -sideWays;
          // this.camera.direction3d.rotation.y %= 2*Math.PI;

          //old
          // this.camera.direction3d.rotation.y += speed * -sideWays;
          // this.camera.direction3d.rotation.y %= 2*Math.PI;
        }


        //this.rotation.y = intoDomain(this.rotation.y + (speed * sideWays));


        this.moveForward(0, action.updown);
      }

      if (this._emittedIdle && process.env.VIEW != "FIRSTPERSON") {
        this.fadeToAction("walk");
      }


      this._emittedIdle = false;

      this.onMove && typeof this.onMove == "function" && setTimeout(() => this.onMove.call(this), 251);

  } else if (!this._emittedIdle) {
   this._moving      = false;
   this._emittedIdle = true;

   this.onMove && typeof this.onMove == "function" && setTimeout(() => this.onMove.call(this), 251);
   if (process.env.VIEW != "FIRSTPERSON") this.fadeToAction("idle");

 }


  // if (
  //   [
  //     "w","a","s","d","arrowup","arrowdown","arrowleft", "arrowright"
  //   ].some(
  //     key => this._keys[key]
  //   )
  // ) {
  //   this._moving = true;
  //   this._fakeRotation = 0;
  //
  //   let frontWays = ((this._keys["w"] || this._keys["arrowup"]   || 0) - (this._keys["s"] || this._keys["arrowdown"]  || 0));
  //   let sideWays  = ((this._keys["a"] || this._keys["arrowleft"] || 0) - (this._keys["d"] || this._keys["arrowright"] || 0));
  //
  //
  //   let angle;
  //
  //   if (this.controls == "mouse_rotate" || process.env.VIEW == "FIRSTPERSON") {
  //     angle = (
  //       (frontWays == -1
  //         ? -Math.PI
  //         : 0
  //       ) + (
  //           ( sideWays*(Math.PI/2) )
  //         / (
  //             (frontWays != 0 && sideWays != 0)
  //               ? 2
  //               : 1
  //           )
  //       )
  //     );
  //
  //     if (  (this._keys["s"] || this._keys["arrowdown"])
  //       &&  (this._keys["a"] || this._keys["arrowleft"])
  //       && !(this._keys["w"] || this._keys["arrowup"])
  //       && !(this._keys["d"] || this._keys["arrowright"])
  //     ){
  //       angle = Math.PI*(3/4);
  //     }
  //
  //     this.moveForward(angle - (process.env.VIEW == "FIRSTPERSON" ? Math.PI : 0), 1);
  //
  //   } else {
  //
  //     if (!this._fakeRotation) {
  //       this._realRotation = this.rotation.y;
  //       this._fakeRotation = 0;
  //     }
  //
  //     this._fakeRotation -= speed * -sideWays;
  //     this._fakeRotation %= 2*Math.PI;
  //
  //
  //     this.rotation.y = this._realRotation + this._fakeRotation;
  //
  //     if (process.env.VIEW !== "FIRSTPERSON") {
  //
  //       this.model.rotation.y += speed * -sideWays;
  //       this.model.rotation.y %= 2*Math.PI;
  //     }
  //
  //
  //     //this.rotation.y = intoDomain(this.rotation.y + (speed * sideWays));
  //
  //
  //     this.moveForward(0, frontWays);
  //   }
  //
  //   if (this._emittedIdle && process.env.VIEW != "FIRSTPERSON") {
  //     this.fadeToAction("walk");
  //   }
  //
  //
  //   this._emittedIdle = false;
  //
  //   this.onMove && typeof this.onMove == "function" && setTimeout(() => this.onMove.call(this), 251);
  //
  // } else if (!this._emittedIdle) {
  //   this._moving      = false;
  //   this._emittedIdle = true;
  //
  //   this.onMove && typeof this.onMove == "function" && setTimeout(() => this.onMove.call(this), 251);
  //   if (process.env.VIEW != "FIRSTPERSON") this.fadeToAction("idle");
  //
  // }
}
