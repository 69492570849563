console.log('./src/scene/environment.js');
import * as THREE from "three";
const textureLoader = new THREE.TextureLoader();

import { scene } from "../../core";

import {OBJLoader}  from "three/examples/jsm/loaders/OBJLoader";
import {MTLLoader}  from "three/examples/jsm/loaders/MTLLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

import Decoration from "./decorations";

import { api } from "../helpers/serverApi";

const assetLoader = (asset, environment) => {
  const {
    scale,
    scene: scenario,
    offset,
  } = environment;

  window.scene = scenario;

  if (!asset.model) return;


  let loadModel; // result promise after loading
  const mtlLoader = new MTLLoader();
  const glbLoader = new GLTFLoader();

  if (/\.obj$/.test(asset.model)) {
    //model is .obj format
    // it seems its currently unused
    loadModel = new Promise(
      (res, rej) => {
        if (asset.mtl) {
          mtlLoader.load(
            `public/environment/${scenario}/assets/${asset.mtl}`,
            res
          );
        } else res();
      }
    ).then(
      (material) => {
        const objLoader = new OBJLoader();
        if (material) {
          objLoader.setMaterials(material);
          Object.values(material.materials).forEach(
            (mat) => {
              mat.color.r = 1;
              mat.color.g = 1;
              mat.color.b = 1;
            })
        }

        return new Promise((res, rej) => objLoader.load(`public/environment/${scenario}/assets/${asset.model}`, res));
      }
    )

  } else if (/\.glb$/.test(asset.model)) {
    // model is .glb format

    loadModel = new Promise(
      (res, rej) => glbLoader.load(
        `public/environment/${scenario}/assets/${asset.model}`,
        gltf => {
          gltf.scene.traverse(child => {
            if (child.isMesh && child.material.name == "Glass") {
              child.material.opacity = 0.5;
              child.material.transparent = true;
            }
          });

        res(gltf.scene)
      })
    ); // loadModel = new Promise(
  }

  // TODO: add other necessary format loaders here

  return loadModel.then(
    model => {
      scene.add(model);


      const size = scale * (asset.scale || 1);

      model.scale.set(size, size, size);
      model.position.add(new THREE.Vector3(
        offset.x + ((asset.offset && asset.offset.x) || 0),
        offset.y + ((asset.offset && asset.offset.y) || 0),
        offset.z + ((asset.offset && asset.offset.z) || 0)
      ))

      model.rotation.y = asset.rotation || 0;

      return model;
    }); // return loadModel.then(
} // const assetLoader = (asset, environment) =>

export default function () {
  window.materials = {}
  console.log(`trying to fetch ${api}/environment`);
  return fetch(`${api}/environment`)
    .then(data => data.json())
    .then(
      (environment) => {
        const {
          assets,
          colliders,
          stands,
          decorations,
          floors,
          ambient,
        } = environment;

        let  model = Promise.all(
          assets.map(asset => assetLoader(asset, environment))
        );

        return (
          {
            colliders,
            stands,
            floors,
            ambient,
            decorations: decorations.map(deco => new Decoration(deco)),
            models     : model
          }
        ) // return (...)
      } // (environment) => { ...
    ); // .then(

} // export default function ()
