console.log('./core/player/index.js');
import * as THREE                 from 'three';
import Character                  from "../character";

import moveForward                from "./moveForward";
import input                      from "./input";
import exportFn                   from "./export";
import colliderCheck              from "./colliderCheck";
import tick                       from "./tick";
import disableMovement            from "./disableMovement";
import positionCamera             from "./positionCamera";
import getNearbyPlayers           from "./getNearbyPlayers";
import handleDistantCharacters    from "./handleDistantCharacters";

import { addToTick }              from "../tick";
import { scene, camera, origin }  from '../index';

import getSocket                  from "../socket";
import throttle                   from "../../utils/throttle";


class Player extends THREE.Group {
  constructor (props) {
    super();

    this.name       = props.name;
    this.credential = props.credential;

    if (process.env.VIEW == "FIRSTPERSON") {
      this.init();
      this.lookAround = true;
      return Promise.resolve(this);
    }

    return new Character({...props, name: undefined})
    .then(
      model => {
        /* Group that will contain all player elements  */
        this.model = model;
        this.add(model);

        this.model.add(camera.direction3d)

        /* Manual inheritance of Character        */
        this.mixer   = model.mixer;
        this.actions = model.actions;

        //group.actions.idle.play();

        this.init();

        //window.model = model;
        //window.group = group;

        return this;
      })
  } // constructor (props)

  init () {
    /* Manual binding to the group           */
    this.moveForward             = moveForward            .bind(this);
    this.input                   = input                  .bind(this);
    this.export                  = exportFn               .bind(this);
    this.colliderCheck           = colliderCheck          .bind(this);
    this.tick                    = tick                   .bind(this);
    this.disableMovement         = disableMovement        .bind(this);
    this.positionCamera          = positionCamera         .bind(this);
    this.getNearbyPlayers        = getNearbyPlayers       .bind(this);
    this.handleDistantCharacters = handleDistantCharacters.bind(this);

    /* Helper variables               */
    this._emittedIdle            = false;
    this._moving                 = false;
    this._transitionTime         = 0;
    this._tickCallbacks          = [];
    this._isPresenting           = false;
    this._receiveInput           = true;
    this._keys                   = {};
    this.maximumActiveCharacters = Number(process.env.MAX_PLAYERS);
    this._oldActiveCharacters    = [];
    this.activeCharacters        = [];

    /* Creating collider for the model        */
    this.collider = new THREE.Box3();

    // DEBUG ONLY
    if (process.env.DEBUG === "true") {
      var helper = new THREE.Box3Helper( this.collider, 0xffff00 );

      // change to move world instead of camera
      scene.add( helper );
      // origin.add( helper );
    }

  /* Initializing Player              */
    // this.add(camera)
    this.camera = camera;
    this.positionCamera();


    /* Recover last Player position        */
    const lastPos = window.sessionStorage.getItem(`last-pos-${window.scene}`);

    if (lastPos) {
      const [vector, rot] = lastPos.split(":");
      const [x,y,z]       = vector .split(",");

      this.position.set(
        Number(x) || 0,
        Number(y) || 0,
        Number(z) || 0
      );
      //this.rotation.y = isNaN(rot) ? 0 : Number(rot);
    }

  } // init()

} // class Player extends THREE.Group

let player = null;

export function init(data) {

  return new Player({
    name      : data.name,
    email     : data.email,
    character : data.model,
    credential: data.credential,
    position  : {
      x: 0,
      y: 0,
      z: 0,
    },
    rotation  : {
      x: 0,
      y: 0,
      z: 0,
    }
  })
  .then(
    (model) => {

      // change to move world instead of camera
      scene.add(model);
      // origin.add(model);

      addToTick((...params) => model.tick(...params))

      // connectToSocket(data);
      const socket  = getSocket();
      window.player = model;
      player        = model;

      return model;
    }
  );
}

export default () => player;
