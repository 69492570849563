console.log('./ui/text/index.js');
export default {
  name: "Hall 360",
  ui: {
    loading: "Loading...",
    list   : "Guests",
    listYou: "You",
    audio  : {
      "TrackStartError"      : "Cannot access device. It may be being used by another application",
      "ConstraintNotSatisfiedError": "This device doesn't meet the necessary requirements",
      "DevicesNotFoundError" : "Device not found",
      "PermissionDeniedError": "This device is not enabled. Permission denied.",
      "default"              : (err) => `There was an unexpected error with your device (${err})`,
      "welcome"              : "¡Welcome to Hall360!",
      "briefDescription"     : [
        "Hall 360 provides the ideal environment and the necessary tools for you to connect with your audience through our powerful yet simple and intuitive 3D virtual platform.",
        "You can chat and talk with other users, download or present material, watch live video presentations and much more.",
        "Hold your next meeting in the place you want: from small minimalist showrooms, through a convention center, natural landscapes, classrooms, to futuristic scenes."
      ],
      "next"                 : "Next",

      move: {
        "howToMove": "How to move",
        "move"     : "Move",
        "moveDesc" : "To move inside Hall 360 you can use standard QWEASD keys or arrows. Reference            : Q (turn left), W (forward), E (turn right), A (left), S (backward), D (right).",
        "turn"     : "Turn",
        "turnDesc" : "To rotate, you can also use the mouse pointer, pressing and dragging the left mouse button.",
        "turnTip"  : "The most natural way to move within the scene is with the QWE keys. Just press W to move forward and then use Q and E to turn left and right respectively.",
        "tp"       : "Teletransportation",
        "tpDesc"   : "To instantly transport yourself from one place to another within Hall 360, point the mouse pointer towards the ground while holding the right mouse button pressed, and then release the right mouse button where you want to instantly go.",
      },
      talk: {
        "howToTalk": "Talk to others",
        "desc"     : "You'll be able to listen and talk to people near you. They will also be able to hear you if you hear them.",
        "bold"     : "You can silence yourself at any time",
        "listen"   : "To listen to the rest, enable the audio when requested",
        "allowMic" :"Enable microphone",
        "denyMic"  : "Continue without microphone",
      }
    },
    forms: {
      titles: {
        login   : "Log in",
        register: "Sign in (I don't have an account)",
        guest   : "Log in as guest",
        subtitle: "Your new event platform"
      },
      createAccount: "Create new account",
      loginAccount : "Log in with an existing account",
      errors: {
        required         : "Complete this field",
        shortName        : "Minimum lenght is 3 characters",
        longName         : "Maximum lenght is 15 characters",
        invalidEmail     : "Email not valid",
        shortPassword    : "Password lenght must be at least 8 characters",
        longPassword     : "Password is to long",
        passwordMissmatch: "Password and confirmation must match",
        wrongCredentials : "Credentials not valid",
        emailInUse       : "This email is already registered",
      },
      labels: {
        userName: "User Name",
        email   : "Email",
        password: "Password",
        send    : "Send",
        chooseModelAgain: "Choose another Avatar",
      },
      start: "Start",
      next : "Next",
    },
    afk: {
      title      : "You were disconected for inactivity",
      description: "You have been inactive for more than 2 minutes and lost the connection. Click reconnect to return.",
      reconnect  : "Reconnect"
    },
    chat: {
      enterToSend: "Press ENTER to send",
      clickToExit: "Click outside to leave the chat",
      targetLabel: "Click on label to delete it",
      to         : "To",
      writeHere  : "Write here",
      toYou      : "For you",
    }
  }

}
