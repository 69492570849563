console.log('./core/socket.js');

import io                from 'socket.io-client';
import * as THREE        from "three";

import Character         from "./character";
import getPlayer         from "./player";

import { scene }         from "./index";
import { addToTick }     from "./tick";

import { notifyEvent }   from "./events";
import { socket_events } from "../utils/constants";

import throttle          from "../utils/throttle";

import UiComponent       from "../ui";
import audio             from "../ui/audio";
import inactiveUi        from "../ui/inactivity";
import { serverUrl }     from '../src/helpers/serverApi';

//import afkHandler from "../utils/afk";
let socket   = null;
let playerId = null;
//let characters = {};

let onDisconnect = null;

let afk = false;

let connectionRetries = 0;


function addCharacter(id, data, enabled = false) {
  if (Character.get(id) || id == playerId) return;

  //TODO determinar como distinguir conexiones sin personaje
  if(data.model && data.model==="nomodel") {
    console.log("theres no data to add :(");
    return;
  }

  console.log("Creating character for", id, "with data", data)
  const character = new Character({
    position  : data.position,
    rotation  : data.rotation,
    character : data.model,
    name      : data.name,
    email     : data.email,
    peer      : data.peer,
    animation : data.animation,
    id,
  })

  //characters[id] = character;
  notifyEvent(socket_events.join, character);
}
let welcomed = null;



export function connect({ name, email, model, credential }, callback) {
  const lastPos = window.sessionStorage.getItem(`last-pos-${window.scene}`);

  console.log(`opening socket for ${serverUrl}`);
  const query = new URLSearchParams(
    {
      name : name,
      email: email,
      model: model,
      pos  : lastPos    || "",
      cred : credential || ""
    }
  );
  console.log(serverUrl, query.toString())
  // socket = io(
  //   "http://localhost:3000/",
  //   {
  //       transports: ['websocket'],
  //       // path      : window.location.pathname+"socket.io",
  //       query     : query.toString()
  //   //     // query     : `name=${name}&email=${email}&model=${model}${ lastPos ? `&pos=${lastPos}`: ""}${ credential ? `&cred=${credential}`: ""}`
  //   }
  // );

  socket = io( {
      reconnectionDelayMax: 10000,
      transports: ['polling', 'websocket' ],
      query     : query.toString()
  });

  //   // serverUrl,
  //   {
  //     transports: ['websocket'],
  //     // path      : `ws://localhost:3000/`,
  //     // path      : window.location.pathname+"socket.io",
  //     query     : query.toString()
  //     // query     : `name=${name}&email=${email}&model=${model}${ lastPos ? `&pos=${lastPos}`: ""}${ credential ? `&cred=${credential}`: ""}`
  //   }
  // );

  addToTick((...params) => {
    Character.getList().forEach(c => {
      if (c.tick) c.tick(...params);
    })
  })


  const player = getPlayer();

  if(player) {
    player.onMove = throttle(
      function () {
        console.log("sending move", player.export() );
        socket.emit(socket_events.move,  player.export(), (res) => console.log(res) );
      },
      500
    );
  }

  welcomed = new Promise(
    (res, rej)=> {
      socket.on(
        socket_events.welcome,
        (ownId, players) => {
          console.log("receive welcome");
          playerId          = ownId;
          connectionRetries = 0;

          // Esto no funciona, pero deberia :(
          // Se supone que si se cae el server y se vuelve a levantar, el evento welcome, que se
          // vuelve a ejecutar, debería eleminar todos los personajes que quedaron obsoletos
          Character.getList().forEach(char => {
            typeof char.remove == "function" && char.remove();
            Character.remove(char.socketId);
          })

          console.log(ownId, {players});

          Object.keys(players).forEach(
            (id) => {
              console.log("loop", id)
              if (id == ownId) return;
              addCharacter(id, players[id]);
            }
          );

          onDisconnect = callback(ownId);
          res(ownId);
        }
      );
    })
  .then(() => {
    notifyEvent(socket_events.join, getCharacters());
    socket.on(socket_events.join, addCharacter);
  })

  // socket.onAny((event, ...args) => {
  //   console.log(event, args);
  // });

  socket.on(socket_events.moved, (players) => {

    Character.getList().forEach((character) => {
      if (character.update) character.update(players);
      character.recalculateDistance(player);
    });

    player.handleDistantCharacters(Character.getOrderedByDistance());
/*
    characters = {
      ...characters,
      ...players,
    }*/
    //player.getNearbyPlayers(characters, playerId);


  });

  // socket.on("visible", (ownId, players) => {
  //   Object.keys(players).forEach((id) => {
  //     //if (characters[id] || id == ownId) return;
  //     if (id == ownId) return;

  //     if (!characters[id]) addCharacter(id,players[id], true);
  //     else characters[id].enabled = true;


  //     //characters[id].visible = true;

  //     //addCharacter(id, players[id]);
  //   });

  //   Object.keys(characters).forEach((id) => {
  //     if (players[id] || id == ownId) return;
  //     characters[id].enabled = false;

  //     //characters[id] && characters[id].remove(scene);
  //     //delete characters[id];
  //   });

  // })

  // socket.on("enterroom", (id, player) => {
  //   if (!Character.get(id)) addCharacter(id,player, true);
  //   else {
  //     Character.get(id).enabled = true;
  //   }
  // });

  // socket.on("leaveroom", (id, rooms) => {
  //   welcomed.then(ownId => {
  //     if (id == ownId) return;
  //     if (!rooms.some(room => player.rooms.includes(room))) {

  //       characters[id].enabled = false;
  //       //characters[id] && characters[id].remove(scene);
  //       //delete characters[id];
  //     }
  //   })
  // });

  socket.on(socket_events.private, (...e) =>{
    console.log("private", e)
  })

  socket.on(socket_events.left, (id) => {
    Character.get(id) && Character.get(id).disconnect();
    Character.remove(id);
    notifyEvent(socket_events.left, {id: id});
  });

  socket.on(socket_events.connect_error, (err) => {
    console.error(err);

    connectionRetries++;

    if (connectionRetries > 3) {
      console.log("websocket error");
      window.location.href = "/";
      return;
    }

    if (!afk) {
      setTimeout(() => {
        socket.connect();
      }, 2000);
    }
  });

  socket.on(socket_events.disconnect, () => {

    if (typeof onDisconnect == "function") onDisconnect();

    connectionRetries++;

    if (connectionRetries > 3) {
      window.location.href = "/";
    }

    if (!afk) {
      setTimeout(() => {
        socket.connect();
      }, 500);
    }
  });

  notifyEvent(socket_events.join, player);

  // afkHandler(() => {
  //   afk = true;
  //   socket.close();

  //   player.disableMovement(true);
  //   player.lookAround = false;

  //   new UiComponent(document.getElementById("app"), inactiveUi);
  // });

} // export function connect({ name, email, model, credential }, callback) {

export function getWelcomed() {
  return welcomed;
};

export function getCharacters() {
  return Character.getAll();
};

export function getPlayerId() {
  return playerId;
}

export default () => socket;
