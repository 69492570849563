console.log('./ui/theme.js');
//TODO WHY IS THIS ALMOST EMPTY
export default {
  light: {
    color      : "black",
    placeholder: "#666",
  },
  dark: {
    color      : "white",
    placeholder: "#AAA",
  },
}
