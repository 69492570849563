console.log('./src/index.js');
import UiComponent from '../ui';
import welcome     from "../ui/welcome";
import loading     from "../ui/loading";
import * as core   from "../core";

import * as scene  from "./scene";

import loadModels, { getModels } from "../core/models";
import { render }  from 'react-dom';


const themeColor = ["#ff9d57", "#4ebeff","#ffa0ae", "#55de86", "#5449ff", "#ff8e86"][Math.floor(Math.random()*6)];

const loadingModels = new UiComponent(
  document.getElementById("app"),
  loading,
  { getThemeColor: () => themeColor}
);



loadModels()
.then(
  () => {
    loadingModels.close();

    let done = false, renderSelector;

    new UiComponent(
      document.getElementById("app"),
      welcome,
      {
        getThemeColor: () => themeColor,
        onSubmit     : function (form) {
          this.close();
          done = true;
          core.init();

          const loadingScreen = new UiComponent(
            document.getElementById("app"),
            loading,
            { getThemeColor: () => themeColor }
          );

          scene
          .init(form)
          .then(
            () => {
              console.log("done at src/index.js:scene.init()");
              loadingScreen.close()
            }
          );
        },
        tick: (cb => {
            console.log("done at src/index.js:tick");

            renderSelector = () => {
              cb();
              if (done) return;
              requestAnimationFrame(renderSelector);
            }

            renderSelector();

          })
      }
    );
  }
);
