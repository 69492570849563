console.log('./ui/calls/styles.js');
import styled, {keyframes}  from "styled-components";

export const Icon = styled.img`
  width: 28px;
  height: 28px;
  padding: 12px;
  margin-left: ${p => process.env.MODE == "AUDIO" ? 6 : 14}px;
`;

export const Wrapper = styled.div`
  position: absolute;

  color: ${p => p.theme.color};

  z-index: 7;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  background: transparent;
  color: black;
  text-align: center;
  font-weight: 500;


  bottom: 24px;
  left: 380px;
`;

export const VideoPlayer = styled.video`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
  display: none;
`;

export const Centered = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  bottom: 40px;
  margin: 0 auto;
`;

export const mainLabel = styled.label`
  &:hover {
    color: ${p => p.theme.placeholder};
  }
`;

export const PlayerRow = styled.div`
  display: flex;
  flex: 1;
  margin-right: 12px;
  transition: opacity 300ms;
  opacity: ${p => p.volume || 1};
  align-items: center;
  color: ${p => p.theme.color};
`;

export const PlayerView = styled.video`
  height: 60px;
  margin-right: 12px;

  ${p => process.env.MODE == "AUDIO" ? `
    width: 40px;
    height: 40px;
    background-color: #939b9e;
    border-radius: 60px;
    transition: border 200ms;
    margin: 12px;
  ` : null}

`;

export const SoundMeter = styled.section`
  position: absolute;
  z-index: -1;
  background-color: #effffb38;
  border-radius: 100px;

  width: 40px;
  height: 40px;
  margin-left: 12px;
  transition: transform 250ms;
`;

export const PlayerEmptyView = styled.div`
  height: 60px;
  width: 60px;
  margin-right: 12px;
  display: flex;

  ${p => process.env.MODE == "AUDIO" ? `
    width: 40px;
    height: 40px;
    background-color: #939b9e;
    border-radius: 60px;
    margin: 12px;
  ` : null}

`;

export const Indicator = styled.label`
  width: 172px;
  margin-top: 24px;
`;

export const Option = styled.div`
  position: absolute;
  transition: opacity 300ms;
  z-index: 5;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  
  ${p => p.own == true ? "" : `
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  `}
`;


export const CallPlayer = styled.label`
  
  color: ${p => p.theme.placeholder};
  width: 100px;
  text-align: start;

  &:hover {
    color: ${p => p.theme.color};
  }
  &:hover img {
    opacity: 1;
  }
`;

export const LabelIcon = styled.img`
height: 22px;
width: 22px;
  padding: 0px;
  opacity: 0;
  transition: opacity 300ms;
`;

export const Span = styled.span`
  margin-left: 6px;
  padding: 6px;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  text-align: center;

  transition: color 300ms, background-color 300ms;

  color: ${p => p.theme.color};


  &:hover {
    color: ${p => p.theme.color};
    background-color: #6a8192;
    cursor: pointer;
  }
`;

const calling = keyframes`
  from {
    opacity: 1;
    clip-path: circle(0%);
  }

  to {
    opacity: 0;
    clip-path: circle(70%);
  }


`;

export const AcceptPlayer = styled(CallPlayer)`

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 250px;
  height: 64px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  font-size: 16px;
  font-weight: 600px;

  &:before {
    content: " ";
    animation: ${calling} 2.7s ease-out infinite;
    background-color: #0000009e;
    width: 280px;
    height: 64px;
    z-index: -1;
    position: absolute;
  }


`;

export const CallRequests = styled.div`
  position: absolute;
  bottom: 70px;

`;
