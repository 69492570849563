console.log('./ui/chat/index.js');
import React, {useState, useEffect, useRef} from "react";

import chatboxHandler from './chatboxHandler';
import ControlBox     from  './components/ControlBox';
import Settings       from  './components/Settings';

import * as control               from '../../src/voiceComponent/jitsiControl.js';
import getSocket, {getCharacters} from "../../core/socket";
import getPlayer                  from "../../core/player";

import {addEvent, removeEvent}    from "../../core/events";
import {
  message_types,
  socket_events,
  conference_events,
  jitsi_events
} from "../../utils/constants";

export default function ({callbacks, Background}) {

  const socketRef = useRef(getSocket());
  const playerRef = useRef(getPlayer());

  const [getJitsiStatus,      setJitsiStatus]      = useState(control.state.status);
  const [getConferenceStatus, setConferenceStatus] = useState("nothing");

  const [getUser,       setUser]       = useState({});
  const [getConference, setConference] = useState({});
  const [getRoom,       setRoom]       = useState({});
  const [getMessages,   setMessages]   = useState({"Hall Demo": []});

  const [getSettings,   setSettings]   = useState({visible: false});

  const data = {
    user: {
      name: "Pancho Villa",
      status: "online"
    },
    conference: {
      name: "franktest",
      users: [],
    },
    room: {
      name: "hall demo",
      users: [
        {name: "Juanito",  status: "online", unread: "4"},
        {name: "Panchito", status: "offline"}
      ]
    },
    /* extra controls*/
  }


  useEffect(
    ()=>{
      console.log("checking if jitsi control in messages", control.state);

      setUser({
        name    : getPlayer().name,
        socketId: getSocket().id,
        status  : "online", // TODO: detect internet connection
      })

      let jitsiStatusListener    = addEvent(jitsi_events     .status_changed,   evtJitsiStatus);
      let conferenceParticipants = addEvent(conference_events.participants,     evtParticipants);
      let conferenceMessage      = addEvent(conference_events.message_received, evtMessage);
      let joinListener           = addEvent(socket_events    .join,              socket_join);

      //preserve the order of events added
      let listeners = [
        getSocket().on(socket_events.message, socket_message),
        getSocket().on(socket_events.join,    socket_join),
        getSocket().on(socket_events.left,    socket_left),
        getSocket().on(socket_events.private, socket_message),
        getSocket().on(socket_events.welcome, socket_message),
      ];

      // getSocket().onAny(
      //   (event, ...args)=>{
      //     console.log("onAny", event, args)
      //   }
      // );


      return(()=>{
        removeEvent(jitsi_events     .status_changed,   evtJitsiStatus);
        removeEvent(conference_events.participants,     evtParticipants);
        removeEvent(conference_events.message_received, evtMessage);

        //preserve the order of events added
        getSocket().off(socket_events.message, listeners.shift());
        getSocket().off(socket_events.join,    listeners.shift());
        getSocket().off(socket_events.left,    listeners.shift()),
        getSocket().off(socket_events.private, listeners.shift());
        getSocket().off(socket_events.welcome, listeners.shift());

      })
    },
    []
  ); // useEffect(

  useEffect(()=>{
    console.log("CONTROL:", control.state.status, getJitsiStatus)
  }, [getJitsiStatus]);

  useEffect(()=>{
    console.log("messages", getMessages)
  }, [getMessages]);

  const evtJitsiStatus = (
    (newStatus)=>setJitsiStatus(newStatus)
  );

  // setConferenceStatus(newRoomStatus.status)
  const evtParticipants = (newParticipants) => {
    console.log("evtParticipants", newParticipants)

    // let newUser = getConference.users.map(
    //   (user, i) => {
    //
    //   }
    // )

    let users = [];
    for(var user in newParticipants){
      let newUser = {
        jitsiId: newParticipants[user].getId(),
        name   : newParticipants[user].getDisplayName(),
        status : newParticipants[user].getConnectionStatus()==="active" ? "online" : "offline"
      }
      if(newUser.status === "offline"){
        setTimeout(
          () => {
            console.log("removing from conference", getConference, newUser);
            setConference(
              getConference => ({
                name : getConference.name||"Hall Demo",
                users: getConference.users.filter(u => u.jitsiId !== newUser.jitsiId)
              })
            )
          },
          5000,
          newUser
        );
      }
      users.push(newUser);
    }

    let conference = {
      name : getConference.name||"Hall Demo",
      users: users
    }
    console.log("participants", conference.users)
    setConference(conference);
  };

  const evtMessage = (message, messageList) => {
    console.log("within chat ui got a message", message, messageList)

  };

  function socket_emit(signal, socket, data){
    console.log(`emit`, signal, socket, data);

    getSocket().emit(signal, socket, data);
  }

  function socket_privmsg(conversation, data){
    conversation = (
      getSocket().id == data.author.id
        ? getPlayer()
        : getCharacters()[data.author.id]
    ).name;

    console.log("trying",
        conversation,
    )

    setRoom(
      (newRoom) => ({
        name : newRoom.name,
        users: newRoom.users
          ? newRoom.users.map(
              (x)=>(
                x.name === data.author.name
                ? ({
                    ...x,
                    unread: 1+Number(x.unread||0)
                  })
                : x
              )
            )
          : []
      })
    )

    setMessages((messages)=>({
        ...messages,
        [conversation]: (messages[conversation]||[]).concat(
          {
            author : data.author,
            message: data.message,
            date   : data.date,
          }
        ),
        last: Date.now()
      })
    );

  }

  function socket_message(data, player){
    console.log("socket_message:", data, player)

    if(Object.keys(data).length === 0) return console.warn("received socket message without data", data, player);

    //assume its a public message
    let conversation = getConference.name||"Hall Demo";

    if(data.target) return socket_privmsg(conversation, data);

    setRoom(
      (x)=>(
        {
          ...x,
          unread: 1+Number(x.unread||"")
        })
    )
    setMessages((messages)=>({
        ...messages,
        [conversation]: (messages[conversation]||[]).concat(
          {
            author : data.author,
            message: data.message,
            date   : data.date,
          }
        ),
        last: Date.now()
      })
    );

  } // const socket_message = (data, player) =>

  useEffect(
    ()=>console.log("new room data", getRoom),
    [getRoom]
  )

  function socket_left(id, character){
    console.log("left", id, character);
    const newUsers = Object.values(getCharacters());
    if(!Array.isArray(newUsers)) newUsers = [];

    //TODO: prune offline names after a while

    setRoom(
      newRoom => ({
        name : newRoom.name,
        users: newRoom.users
            ? newRoom.users.map(
                (c) => ({
                    socketId: c.socketId,
                    name    : c.name,
                    status  : c.socketId===id
                        ? "offline"
                        : "online"
                  })
                )
            : []
      })
    );
  }

  const socket_join = (id, player) => {
    console.log("socket_join:", id, player)

    let room = {
      name: "Hall Demo",
      users: Object.values(getCharacters()).map(
        (character) => ({
          socketId: character.socketId,
          name    : character.name,
          status  : "online"
        })
      )
    };

    setRoom(room);
  }

  function onSend(user, message){
    console.log("chat result", user, message)
    //TODO send private message through socket instead of jitsi because anonymouse messages get lost

    if(!user) {
    // jitsi messaging
      control.state.activeRoom.sendMessage(message);
      return true;
    }
    if(user)  {
      socket_emit(socket_events.message, message, user.socketId);
      if(user.socketId) setMessages(
        (messages)=>({
          ...messages,
          [user.name]: (messages[user.name]||[]).concat(
            {
              author : getUser,
              message: message,
              date   : Date.now(),
            }
          ),
          last: Date.now()
        })
      );
    }
    return true
  }

  function onOpenChat(user){
    console.log("chat opened for ", user, getRoom)

    setRoom(
      (newRoom) => ({
        ...newRoom,
        users: newRoom.users.map(
          (x)=>(
            x.name === user.name
              ? ({...x, unread: ""})
              : x
          )
        ),
        unread: (user.name == newRoom.name ? "" : newRoom.unread)
      })
    )
  }

  function onSettings(settings){
    console.log("fn settings")
    setSettings(
      (s) => ({
        ...s,
        visible: !s.visible
      })
    );
  }

  return (
    <>
      <ControlBox
        messages  ={getMessages}
        user      ={getUser}
        conference={getConference}
        room      ={getRoom}
        last      ={getMessages.last}
        onSend    ={onSend}
        onOpenChat={onOpenChat}
        onSettings={onSettings}
      >
      </ControlBox>
      {
        getSettings.visible
          ?
            <Settings
              callbacks ={callbacks}
              Background={Background}
              visible   ={getSettings.visible}
              onSettings={onSettings}
            />
          : "" }
    </>
  );

  return chatboxHandler({callbacks});
}
