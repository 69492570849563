console.log('./utils/getCollisionSide.js');
export default function (collider, colliding) {
  const amounts = ["z","x"]
    .map(coord => {
      let between = colliding.max[coord] > collider.min[coord] || colliding.min[coord] <= collider.max[coord];

      if (!between) return false;

      return Math.min(colliding.max[coord] - collider.min[coord], collider.max[coord] - colliding.min[coord], 1.3);

    })

  const mid = {
    x: (colliding.min.x + colliding.max.x) / 2,
    z: (colliding.min.z + colliding.max.z) / 2,
  }
  //let betweenZ = this.collider.max.z > collider.min.z || this.collider.min.z <= collider.max.z;
  //let betweenX = this.collider.max.x > collider.min.x || this.collider.min.x <= collider.max.x;

  if (amounts[0] > amounts[1]) {
    if      (amounts[0] && mid.x > collider.max.x) return  Math.PI; // -x
    else if (amounts[0] && mid.x < collider.min.x) return  0; // +x

    else if (amounts[1] && mid.z > collider.max.z) return  Math.PI/2; // -z
    else if (amounts[1] && mid.z < collider.min.z) return -Math.PI/2; // +z
  }
  else {
    if      (amounts[1] && mid.z > collider.max.z) return  Math.PI/2; // -z
    else if (amounts[1] && mid.z < collider.min.z) return -Math.PI/2; // +z

    else if (amounts[0] && mid.x > collider.max.x) return  Math.PI; // -x
    else if (amounts[0] && mid.x < collider.min.x) return  0; // +x
  }

  return null;
}
