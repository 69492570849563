console.log('./core/player/moveForward.js');

import intoDomain from "../../utils/intoDomain";
import { dolly } from "../index";

if (!process.env.FPS || isNaN(process.env.FPS)) throw new Error(".env FPS is not a valid number");

const speed = (0.12*60)/process.env.FPS;

export default function (dir, vel = 1) {
  if (this._dash) this.speed = speed*2;
  else            this.speed = speed;

  // console.log("moveForward", this.rotation.y)
  const direction       = intoDomain(dolly.rotation.y+dir);
  // const direction       = intoDomain(this.camera.direction3d.rotation.y+dir);

  if(isNaN(direction)) console.trace("bad direction")
  // const direction       = intoDomain(this.rotation.y+dir-Math.PI);
  let   preventedAngles = this.colliderCheck();


  if (!preventedAngles) throw new Error("Collider check returned null");

  //console.log(this.model.rotation.y.toFixed(2) + " -> " + dir.toFixed(2))
  if (process.env.VIEW !== "FIRSTPERSON") {

    this.model._target = {
      rotation: { y: dir },
      position: {},
    }

    this.model._last = {
      rotation: { y: dolly.rotation.y },
      // rotation: { y: this.camera.direction3d.rotation.y },
      // rotation: { y: this.model.rotation.y },
      position: {},
    }

    this.model._transitionTime = 0;
  }

  // if (preventedAngles.length > 1 && areDifferent && against) {
  //   this.position.x -= xVal;
  //   this.position.z -= zVal;

  //   return;
  // }
  // return;


  let sides = [
    ...new Set(
      preventedAngles.filter(
        (angle) => (
          Math.abs( intoDomain(angle + Math.PI/2 - direction) ) > Math.PI/2
        )
      )
    )
  ];


  //console.log(preventedValues.map(a => a.toFixed(2)).join(" "), ":", direction.toFixed(2))
  // console.log("movefroward", this.camera.direction3d.position)

  // if (sides.length > 1) {
  //   return;
  // } else if (sides.length == 1) {
  //   const modus = -this.speed * Math.cos(sides[0] - direction);
  //   //const angle = sides[0] - Math.PI;//(intoDomain(direction - sides[0]) > 0 && intoDomain(direction - Math.PI - sides[0]) < 0)
  //     //? sides[0]
  //     //: sides[0]// - Math.PI;
  //
  //   const slidingAngle = sides[0] - (modus > 0 ? Math.PI : 0);
  //
  //   const against = preventedAngles.filter((angle) => Math.abs(intoDomain(angle + Math.PI/2 - slidingAngle)) > Math.PI/2);
  //
  //   if (against.length) return;
  //
  //   //    console.log(against);
  //   //if (!preventedAngles.every((side) => Math.abs(intoDomain(side + Math.PI/2 - slidingAngle)) > Math.PI/2 || side == sides[0])) return;
  //
  //   this.camera.direction3d.position.x += modus*Math.sin(sides[0]);
  //   this.camera.direction3d.position.z += modus*Math.cos(sides[0]);
  //
  // } else {
  //
  //   const xVal = Math.sin(direction-Math.PI)*this.speed*vel;
  //   const zVal = Math.cos(direction-Math.PI)*this.speed*vel;
  //
  //   this.camera.direction3d.position.x += xVal;
  //   this.camera.direction3d.position.z += zVal;
  // }

  let oldy = dolly.position.y

  dolly.translateZ(Math.cos(dir) * (-0.3) );
  dolly.translateX(Math.sin(dir) * (-0.3) );
  // origin.translateZ(Math.cos(dir) * (-0.3) );
  // origin.translateX(Math.sin(dir) * (-0.3) );
  dolly.position.y = oldy;


  //working before origin hax
  // let oldy = this.camera.direction3d.position.y
  // this.camera.direction3d.translateZ(Math.cos(dir) * (-0.3) );
  // this.camera.direction3d.translateX(Math.sin(dir) * (-0.3) );
  // this.camera.direction3d.position.y = oldy;

  // console.log(
  //   "s", sides.length,
  //   dir,
  //   this.camera.direction3d.position.z,
  //   this.camera.direction3d.position.x
  // );


  // console.log(
  //   "direction", direction,
  //   "f", this.camera.direction3d.position.x,
  //        this.camera.direction3d.position.z
  // )

  // Revierto los valores de posicion que hayan sido prevenidos
  //if (xVal < 0) this.position.x -= xVal*(1 - preventedValues["-x"]);
  //if (xVal > 0) this.position.x -= xVal*(1 - preventedValues["+x"]);

  //if (zVal < 0) this.position.z -= zVal*(1 - preventedValues["-z"]);
  //if (zVal > 0) this.position.z -= zVal*(1 - preventedValues["+z"]);


  if (process.env.VIEW !== "FIRSTPERSON") this.actions.walk.timeScale = vel < 0 ? -1 : 1;
}

// export default function (dir, vel = 1) {
//   if (this._dash) this.speed = speed*2;
//   else            this.speed = speed;
//
//   // console.log("moveForward", this.rotation.y)
//   const direction       = intoDomain(this.camera.direction3d.rotation.y+dir);
//
//   if(isNaN(direction)) console.trace("bad direction")
//   // const direction       = intoDomain(this.rotation.y+dir-Math.PI);
//   let   preventedAngles = this.colliderCheck();
//
//
//   if (!preventedAngles) throw new Error("Collider check returned null");
//
//   //console.log(this.model.rotation.y.toFixed(2) + " -> " + dir.toFixed(2))
//   if (process.env.VIEW !== "FIRSTPERSON") {
//
//     this.model._target = {
//       rotation: { y: dir },
//       position: {},
//     }
//
//     this.model._last = {
//       rotation: { y: this.camera.direction3d.rotation.y },
//       // rotation: { y: this.model.rotation.y },
//       position: {},
//     }
//
//     this.model._transitionTime = 0;
//   }
//
//   // if (preventedAngles.length > 1 && areDifferent && against) {
//   //   this.position.x -= xVal;
//   //   this.position.z -= zVal;
//
//   //   return;
//   // }
//   // return;
//
//
//   let sides = [
//     ...new Set(
//       preventedAngles.filter(
//         (angle) => (
//           Math.abs( intoDomain(angle + Math.PI/2 - direction) ) > Math.PI/2
//         )
//       )
//     )
//   ];
//
//   //console.log(preventedValues.map(a => a.toFixed(2)).join(" "), ":", direction.toFixed(2))
//   // console.log("movefroward", this.camera.direction3d.position)
//
//   if (sides.length > 1) {
//     return;
//   } else if (sides.length == 1) {
//     const modus = -this.speed * Math.cos(sides[0] - direction);
//     //const angle = sides[0] - Math.PI;//(intoDomain(direction - sides[0]) > 0 && intoDomain(direction - Math.PI - sides[0]) < 0)
//       //? sides[0]
//       //: sides[0]// - Math.PI;
//
//     const slidingAngle = sides[0] - (modus > 0 ? Math.PI : 0);
//
//     const against = preventedAngles.filter((angle) => Math.abs(intoDomain(angle + Math.PI/2 - slidingAngle)) > Math.PI/2);
//
//     if (against.length) return;
//
//     //    console.log(against);
//     //if (!preventedAngles.every((side) => Math.abs(intoDomain(side + Math.PI/2 - slidingAngle)) > Math.PI/2 || side == sides[0])) return;
//
//     this.camera.direction3d.position.x += modus*Math.sin(sides[0]);
//     this.camera.direction3d.position.z += modus*Math.cos(sides[0]);
//
//   } else {
//
//     const xVal = Math.sin(direction-Math.PI)*this.speed*vel;
//     const zVal = Math.cos(direction-Math.PI)*this.speed*vel;
//
//     this.camera.direction3d.position.x += xVal;
//     this.camera.direction3d.position.z += zVal;
//   }
//   console.log(
//     "direction", direction,
//     "f", this.camera.direction3d.position.x,
//          this.camera.direction3d.position.z
//   )
//
//   // Revierto los valores de posicion que hayan sido prevenidos
//   //if (xVal < 0) this.position.x -= xVal*(1 - preventedValues["-x"]);
//   //if (xVal > 0) this.position.x -= xVal*(1 - preventedValues["+x"]);
//
//   //if (zVal < 0) this.position.z -= zVal*(1 - preventedValues["-z"]);
//   //if (zVal > 0) this.position.z -= zVal*(1 - preventedValues["+z"]);
//
//
//   if (process.env.VIEW !== "FIRSTPERSON") this.actions.walk.timeScale = vel < 0 ? -1 : 1;
// }
