console.log('./utils/interpolate.js');
import intoDomain from "./intoDomain";

export default function interpolate(startValue, endValue, t, duration = 0.6) {
  if (t > duration) return endValue;

  return startValue + (t * (endValue - startValue))/duration;
}

export function interpolateRot(startValue, endValue, t, duration = 0.3) {
  //const delta = Math.abs((t * (endValue - startValue))/duration);
  const steps = Math.abs(intoDomain(endValue - startValue))/duration;

  if (t > duration) return endValue;
  
  if (intoDomain(startValue - endValue) > 0 && intoDomain(startValue - Math.PI - endValue) < 0) {
    // Clockwise
    return intoDomain(startValue - steps*t);
  } else {
    // Anti-clockwise
    return intoDomain(startValue + steps*t);
  }
}