console.log('./core/tick.js');
import * as THREE from "three";
import process    from 'process';
import { camera, renderer, scene, dolly } from "./index";
import * as inputController        from "./inputController";

// import Stats from "three/examples/jsm/libs/stats.module";
// const stats = new Stats();
// document.body.appendChild(stats.dom)

var clock = new THREE.Clock();
let tickCallbacks = [];

if (!process.env.FPS || isNaN(process.env.FPS)) throw new Error(".env FPS is not a valid number");

var interval = 1/(Number(process.env.FPS));
var delta = 0;

let cameraDirection = new THREE.Vector3();

let wtf = false;

let width  = window.innerWidth;
let height = window.innerHeight;
const debugcamera = new THREE.OrthographicCamera(
  width  / -2, width  / 2,
  height /  2, height / -2,
  1, 1000
);

debugcamera.position.set(50,50,50);
debugcamera.zoom=8;
let angle = 0;

let helper = null;
let firsttick = false;

let debugtarget = new THREE.Vector3();

window.cameraSelected = "none";

export function animate() {

  if(!firsttick){
    firsttick = true;
    helper = new THREE.CameraHelper( camera );
    scene.add( helper );
  }

  // console.log(dolly.position)
  //document.getElementById("VRButton").innerHTML=JSON.stringify(dolly.position)+'<br/>'+JSON.stringify(dolly.rotation)

  if(window.cameraSelected === "debug"){
    debugcamera.position.y = 50;
    debugcamera.position.x=50*Math.cos(angle);
    debugcamera.position.z=50*Math.sin(angle);
    // angle = (angle + 0.0005) ;

    // origin.position.x = (origin.position.x+0.1) % 40;;

    camera.getWorldPosition(debugtarget);
    debugcamera.lookAt(debugtarget);
    debugcamera.updateProjectionMatrix();
  }

  const frustum = new THREE.Frustum();
  frustum.setFromProjectionMatrix(
    new THREE.Matrix4().multiplyMatrices(
      camera.projectionMatrix,
      camera.matrixWorldInverse
    )
  );

  delta += clock.getDelta();

  camera.updateMatrix(); // make sure camera's local matrix is updated
  camera.updateMatrixWorld(); // make sure camera's world matrix is updated
  camera.matrixWorldInverse.copy(camera.matrixWorld).invert();

  if(window.cameraSelected === "debug"){
    renderer.render( scene, debugcamera );
  } else {
    renderer.render( scene, camera );
  }

  // console.log(camera.magic.getWorldDirection(cameraDirection))

  if (delta > interval) {

    //stats.update();

    tickCallbacks.forEach(cb => cb({
      delta,
      frustum
    }))

    delta = 0.001;
  }

    //setTimeout(() => {
    // requestAnimationFrame( animate );

  //}, 1000/60);

  // if (player) {
  //   player.tick(delta, frustum);
  // }

  //proyector.tick(player, frustum);

  //if ( characters ) Object.values(characters).forEach(m => m.tick(delta, frustum));
}

export function addToTick(cb) {
  if (!cb || typeof cb !== "function") throw new Error("La función addToTick solo admite funciones");
  tickCallbacks.push(cb);
}
