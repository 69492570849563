console.log('./ui/calls/player.js');
import React, { useEffect, useState, useRef } from "react";

import { maxDistance, threshold, maxPeople } from "../../core/player/getNearbyPlayers";

import {
  Icon,
  PlayerRow,
  PlayerView,
  Option,
  SoundMeter,
} from "./styles";

function VolumeMeter(context) {
  this.context = context;
  this.volume = 0.0;
  this.script = context.createScriptProcessor(2048, 1, 1);
  const that = this;
  this.script.onaudioprocess = function(event) {
    const input = event.inputBuffer.getChannelData(0);
    var sum = 0.0;
    for (var i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
    }
    that.volume = Math.sqrt(sum / input.length);
  };
}

VolumeMeter.prototype.stop = function() {
  this.mic.disconnect();
  this.script.disconnect();
};

VolumeMeter.prototype.connectToSource = function(stream, callback) {
  try {
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    this.script.connect(this.context.destination);
    if (typeof callback !== 'undefined') {
      callback(null);
    }
  } catch (e) {
    // what to do on error?
  }
};

export default function ({ mode, player, speaker, options }) {
              
  const [muted, setMuted] = useState(false);
  const videoRef = useRef(null);
  const meterRef = useRef(null);

  
  useEffect(() => {
    if(videoRef.current) {
      videoRef.current.srcObject = player.stream;
      videoRef.current.play();

    }

    var audioContext = new AudioContext();

    const volumeMeter = new VolumeMeter(audioContext);

    let interval;
    volumeMeter.connectToSource(player.stream, function () {
      interval = setInterval(() => {
        const width = Math.min(Math.round(volumeMeter.volume * 25), 0.5)
        meterRef.current.style.transform = `scale(${1 + width})`;
      }, 250);
    });

    return () => {
      clearInterval(interval)
    }

  }, [videoRef])
  
  function toggleAudio() {
    videoRef.current.srcObject.getTracks().forEach(t => t.enabled = muted);
    setMuted(m => !m);
  }

  const volume =  parseFloat(Math.floor(Math.min(1 - (((player.distance || 0) - maxDistance) / threshold), 1)*100)/100);

  if (videoRef.current) videoRef.current.volume = volume;
  
  return (
    <PlayerRow volume={volume}>
      <Option own={!!options}>
        {
          options
          ||
          (muted 
            ? <Icon mode={mode} onClick={toggleAudio} src="public/icons/stophear.png"/>
            : <Icon mode={mode} onClick={toggleAudio} src="public/icons/hear.png"/>)
        }
      </Option>
      <SoundMeter ref={meterRef}/>
      {
        process.env.MODE == "AUDIO"
          ? <PlayerView mode={mode} autoPlay ref={videoRef} />
          : <PlayerView mode={mode} autoPlay ref={videoRef} muted/>
      }
      <label>
        {player.name}
        {" "}
        {speaker ? "(Presentación)" : ""}
      </label>
    </PlayerRow>
  );
};