console.log('./ui/redux/reducers/calls-reducer.js');
import { CREATE_CALL, HANG_PERSON, SET_CALL } from "../actions/calls";

const initialState = {};

// export default (state, action) => {
//   console.log("%cPrevious state","color:red")
//   console.log(state)
//   console.log("%cAction","color:orange")
//   console.log(action)
//   const newState = reducer(state, action);
//   console.log("%cNew State","color:green")
//   console.log(newState);
//   return newState;
// }

//const reducer = (state = initialState, action) => {

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALL:
      console.emoji("🤳",`Create call [${action.id}]`);
      return {
        ...state,
        [action.id]: {
          call     : action.call,
          offered  : action.offered,
          pending  : action.pending,
          name     : action.name,
          speaker  : action.speaker,
          handraise: action.handraise,
          stand    : action.stand,

        }
      };

    case HANG_PERSON:
      console.emoji("🤳",`Hang        [${action.id}]`);
      const copy = {...state};
      delete copy[action.id];
      return copy;

    case SET_CALL:
      console.emoji("🤳",`Set ${action.id} call (${Object.keys(action.call).join(", ")})`);
      return {
        ...state,
        [action.id]: {
          // Me quedo con los viejos valores
          ...state[action.id],
          // Reescribo con los nuevos valores
          ...action.call
        }
      };

    default:
      return state;
  }
}
