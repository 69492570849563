console.log('./ui/chat/message.js');
import {useState, useEffect} from "react";
import styled from "styled-components";
import {message_types} from "../../utils/constants";

export default ({ message, hovered, index, total, setPrivateTarget }) => {

  const [timed, setTimed] = useState(false);

  useEffect(
    () => {
      if (total-3 > index) return;

      const timeout = setTimeout(() => {
        setTimed(true);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      }
    },
    []
  );

  let content = null;

  if (message.message == "f" || message.message == "F") message.message = <span style={{fontSize: 32, fontWeight: "bolder"}}>F</span>

  switch(message.type) {
    // Bienvenido de alguien
    case message_types.welcome:
      content = (
        <>¡<TargetUser
            onClick={() => setPrivateTarget(message.author)}
          >
          {message.author.name}</TargetUser> se ha unido!
        </>
      );
      break;
    // Se fue alguien
    case message_types.left:
      content = (
        <><strong>{message.author.name}</strong> se ha ido.</>
      );
      break;
    // Mensajes mios (sin funcionalidades)
    case message_types.me:
      content = (
        <><strong>{message.author.name}</strong>:  {message.message}</>
      )
      break;
    case message_types.privately:
      content = (
        <><em>A{" "}
          <TargetUser onClick={() => setPrivateTarget(message.to)}>
            {message.to.name}
          </TargetUser>{" "}
         en privado</em>:  {message.message}</>
      )
      break;
    case message_types.private:
      content = (
        <><em>De{" "}
          <TargetUser onClick={() => setPrivateTarget(message.author)}>
            {message.author.name || "NONAME"}
          </TargetUser>{" "}
          en privado</em>:  {message.message}</>
      )
      break;
    // Mensaje de alguien
    case message_types.test:
      console.error("Received message test type, this is an error");
    case message_types.public:
    default:
      content = (
        <><TargetUser
            onClick={() => setPrivateTarget(message.author)}
          >
          {message.author.name}</TargetUser>:  {message.message}
        </>
      )
  }

  if (!hovered && (total - 3 > index)) return null;

  const messageDate = new Date(message.date);

  return (
    <Message
      show={hovered || (!timed && (total - 3 <= index))}
    >
      {content}
      {
        hovered
          ? <Time>{messageDate.getHours()}:{messageDate.getMinutes()}</Time>
          : null
      }
    </Message>
  )

}

const Message = styled.p`
  color: ${p => p.theme.color};
  margin: 6px 34px;
  ${
    p => true //p.show
      ? `
        opacity: 1;
      `
      : `
        opacity: 0 !important;
        transition: opacity 5000ms;
      `
  }
`;

const TargetUser = styled.strong`
  &:hover {
    text-decoration: underline;
  }
  user-select: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  cursor: pointer;
`;

const Time = styled.span`
  position: relative;
  display: none;

  &:hover {
    display: inline;
  }
`;
