console.log('./ui/inactivity/index.js');
import React  from "react";
import styled from "styled-components";
import texts  from "../text";

export default ({ close, Background, callbacks }) => {

  function reload () {
    location.reload();
  }

  return (
    <Background onClick={ev => ev.stopPropagation()} zIndex={8}>
      <Modal>
        <h1>{texts.ui.afk.title}</h1>
        <Content>
          <Parragraph>{texts.ui.afk.description}</Parragraph>
        </Content>
        <Connect onClick={reload}>{texts.ui.afk.reconnect}</Connect>
      </Modal>
    </Background>
  )
}


const Modal = styled.div`
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 440px;
  /* height: 350px; */

  /* left: calc(50vw - 210px);
  top: calc(50vh - 175px); */

  border-radius: 3px;
  box-sizing: border-box;
  padding: 24px;
  z-index: 8;
`;

const Parragraph = styled.p`
  margin-top: 0px;
`;

const Button = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  outline: none;

  align-self: center;
  font-size:14px;

  margin-top: 12px;
`;


const Connect = styled(Button)`
  color: white;
  background-color: #5353b9;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 16px;
  transition: background-color 300ms;

  &:hover {
    background-color: #6b6bce;
  }
`;

const Content = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #02020244;
  }
`;
