console.log('./core/peer.js');
import adapter from 'webrtc-adapter';
// import Peer    from "peerjs";
import socket from "./socket.js";

import io from "socket.io-client"
const peerConfig = {
  iceServers: [
    {
      url       : "turn:coturn.covrel.com:3478" ,
      username  : "covrel",
      credential: "CovrelTurn2020",
    },
  ],
  // metadata: {
  //   hola: ":)"
  // },
  host: "/",
  path: "/peerjs/",
  port: 8080, // sin este puerto usa el 443
};

let peer = socket //io("ws://localhost:3000");

export function init(id, callback) {
  // peer = {};//new Peer(null, peerConfig);
  peer().on('connection', function(socket) {
    console.log("peer.connection", socket);
    // socket.on('*', function(){ /* … */ });
  });


  peer().on("error", (err) => {
    console.error("Peer connection error\n\n", err);
  })

  peer().on("open", (id) => {
    console.log("peer.open");
    callback(peer);
  })

}

export default () => peer;

// const peerConfig = {

//   iceServers: [
//     {url:'stun:stun01.sipphone.com'},
//     {url:'stun:stun.ekiga.net'},
//     {url:'stun:stun.fwdnet.net'},
//     {url:'stun:stun.ideasip.com'},
//     {url:'stun:stun.iptel.org'},
//     {url:'stun:stun.rixtelecom.se'},
//     {url:'stun:stun.schlund.de'},
//     {url:'stun:stun.l.google.com:19302'},
//     {url:'stun:stun1.l.google.com:19302'},
//     {url:'stun:stun2.l.google.com:19302'},
//     {url:'stun:stun3.l.google.com:19302'},
//     {url:'stun:stun4.l.google.com:19302'},
//   ],
// };
