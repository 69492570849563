console.log('./core/controls.js');
import * as THREE from "three";

import getPlayer       from "./player";
import intoDomain      from "../utils/intoDomain";

import UiComponent     from "../ui";
import mobile          from "../ui/mobile";
import playerlist      from "../ui/playerlist";

import teletransport, { confirm } from "./teletransport";
import windowOnBlur               from "../utils/onblur";

import * as inputController       from "./inputController";
import { inputdevice_events }     from "../utils/constants";
import { addEvent, removeEvent }  from "./events";

import { camera, dolly } from './index';

let player      = null;
let initialized = false;
let canvas      = null;

let crosshair;

let lon = -90;
let lat =  0;

let teletransportating = false;

export function getControl () {}
getControl.prototype.setLat = function (newLat, force = true) {
  if (force) {
    lat = newLat;
    calculateCameraFromLatLon();
  }
  lat = newLat;
  calculateCameraFromLatLon();
}

getControl.prototype.setLon = function (newLon, force = true) {
  if (force) {
    lon = newLon;
    calculateCameraFromLatLon();
  }
  lon = newLon;
  calculateCameraFromLatLon();
}

getControl.prototype.getLat = function () {
  return lat;
}

getControl.prototype.getLon = function () {
  return lon;
}


var targetPosition = new THREE.Vector3(0,0,0); // avoid new object on each frame

function calculateCameraFromLatLon () {
        lat    = Math.max( -85, Math.min( 85, lat ) );
  let   phi    = THREE.MathUtils.degToRad( 90 - lat );
  const theta  = THREE.MathUtils.degToRad( lon );
  // let position = player.position;
  let position = dolly.position;

  targetPosition.x = (position.x||0) + 100 * Math.sin( phi ) * Math.cos( theta );
  targetPosition.y = (position.y||0) + 100 * Math.cos( phi );
  targetPosition.z = (position.z||0) + 100 * Math.sin( phi ) * Math.sin( theta );
//******
  // console.log("lookAt", targetPosition, position);
  dolly.lookAt( targetPosition );

  // dolly.rotation.y = -theta - Math.PI/2;

  // player.camera.direction3d.lookAt( targetPosition );
  // player.camera.direction3d.lookAt( targetPosition );
  // player.rotation.y = -theta - Math.PI/2;

  if (teletransportating) {
    teletransport.call(dolly, targetPosition);
  }
}


const mouseMove = (e) => {
  const movementX = e.movementX || e.mozMovementX || e.webkitMovementX || 0,
        movementY = e.movementY || e.mozMovementY || e.webkitMovementY || 0;

  if (!player._fakeRotation) {
    // player._realRotation = player.camera.direction3d.rotation.y;
    player._realRotation = dolly.rotation.y;
    player._fakeRotation = 0;
  }

  player._fakeRotation -= movementX/550;
  player._fakeRotation %= 2*Math.PI;

  dolly.rotation.y = player._realRotation + player._fakeRotation;
  // player.camera.direction3d.rotation.y = player._realRotation + player._fakeRotation;

  if (process.env.VIEW == "FIRSTPERSON") {

    const lookSpeed         = 0.05;
    const active            = true;
    const constrainVertical = true;
    var actualLookSpeed     = lookSpeed;

		if (!player.lookAround) return;

		lon += movementX * actualLookSpeed;
		lat -= movementY * actualLookSpeed;

    calculateCameraFromLatLon();

  } else {
    dolly.rotation.y += movementX/750;
    dolly.rotation.y %= 2*Math.PI;

    // player.model.rotation.y += movementX/750;
    // player.model.rotation.y %= 2*Math.PI;

    // camera.direction3d.rotation.y += movementX/750;
    // camera.direction3d.rotation.y %= 2*Math.PI;
  }
}

window.cameraRot = function (x,y,z,w) {
  const quat = new THREE.Quaternion(x,y,z,w);

//******
  dolly.rotation.applyQuaternion(quat);
  // player.camera.rotation.applyQuaternion(quat);
  // player.camera.direction3d.rotation.applyQuaternion(quat);
}


export const lockPointer = (ev) => {
  if (ev) ev.preventDefault();
  if (document.hasFocus()) {
    if (!ev || ev.target === canvas) canvas.requestPointerLock();
  } else if (!ev) {
    document.removeEventListener("click", lockPointer);
    document.addEventListener("click", lockPointer, false);
  }
}

export const unlockPointer = () => {
  // if(!mouselock) return;
  document.exitPointerLock();
}


const fpClick = (ev) => {
  if (ev) ev.preventDefault();
  if (ev.target.onclick) return;

  document.elementFromPoint(window.innerWidth/2, window.innerHeight/2).click();
}

const fpMouseDown = () => {
  document.elementFromPoint(window.innerWidth/2, window.innerHeight/2).dispatchEvent(new Event("mousedown"));
}

const fpMouseUp = () => {
  document.elementFromPoint(window.innerWidth/2, window.innerHeight/2).dispatchEvent(new Event("mouseup"));
}

function addFpClickListeners() {
  window.addEventListener("click",     fpClick,     false);
  window.addEventListener("mousedown", fpMouseDown, false);
  window.addEventListener("mouseup",   fpMouseUp,   false);
}

function removeFpClickListeners() {
  window.removeEventListener("click",     fpClick);
  window.removeEventListener("mousedown", fpMouseDown);
  window.removeEventListener("mouseup",   fpMouseUp);

}

function onPointerLockChange () {
  if(
        document.pointerLockElement
    ||  document.mozPointerLockElement    === canvas
    ||  document.webkitPointerLockElement === canvas
  ) {
    document.addEventListener("mousemove", mouseMove, false);

    if (process.env.VIEW == "FIRSTPERSON") {
      document.removeEventListener("click", lockPointer);
      crosshair.style.display = "";
      //addFpClickListeners();
    } else {
      document.addEventListener   ("click",    unlockPointer, false);
      document.removeEventListener("dblclick", lockPointer);
      player.controls = "mouse_rotate";
    }


  } else {
    document.removeEventListener("mousemove", mouseMove);

    if (player.lookAround) document.addEventListener("click", lockPointer);


    if (process.env.VIEW == "FIRSTPERSON") {
      //removeFpClickListeners();
      crosshair.style.display = "none";
    }
    else {
      document.removeEventListener("click",    unlockPointer, false);
      document.addEventListener   ("dblclick", lockPointer,   false);
    }

    player.controls = "wasd_rotate";
  }
}

let tabList   = null;
let mouselock = false;


/* event based input from mouse-down, keypress */
function parse_input(e){
  // console.log("controls.parse_input", e.userlist, tabList)
  if( e.userlist && !tabList) {tabList = new UiComponent(document.getElementById("app"), playerlist);}
  if(!e.userlist && tabList)  {tabList.close(); tabList=null;}

  if(              e.lookaround) { mouselock=true;  lockPointer(); }
  if(mouselock && !e.lookaround) { mouselock=false; unlockPointer(); }
}


export function init(renderer) {
  if (initialized) return;

  initialized = true;
  canvas      = document.querySelector('canvas');
  player      = getPlayer();

  window.addEventListener(
    "unload",
    () => {
      window.sessionStorage.setItem(
        `last-pos-${window.scene}`,
        `${player.position.x.toFixed(2)},${player.position.y.toFixed(2)},${player.position.z.toFixed(2)}:${lon.toFixed(2)}`
      );
    }
  );

  inputController.init(
    renderer,
    {
      keyboard : true,
      gamepad  : true,
      xrcontrol: true,
      actions  : [
        "updown",
        "leftright",
        "action",
        "cancel",
        "userlist",
        "lookaround",
        "rotateview",
      ],
      defaults: {
        keyboard: {
          updown    : { positive: [38, 87], negative: [40, 83] },
          leftright : { positive: [37, 65], negative: [39, 68] },
          rotateview: { positive: [69],     negative: [81]},
          action    : { positive: [13]},
          cancel    : { positive: [27]},
          userlist  : { positive: [ 9]},
          lookaround: { positive: [32]},
        },
        gamepad: {
          updown    : { positive: [-1], negative: [-1] },
          leftright : { positive: [-0], negative: [-0] },
          rotateview: { positive: [ 2], negative: [ 2]},
          action    : { positive: [ 2]},
          cancel    : { positive: [ 1]},
        },
        xrcontrol: { // ???
          updown    : { positive: [ 1], negative: [ 1] },
          leftright : { positive: [-0], negative: [-0] },
          action    : { positive: [ 0]},
          cancel    : { positive: [ 1]},
        }
      }
    }
  ); // inputController.init( ... )


  renderer.xr.addEventListener( 'sessionstart', function ( event ) {
    console.log("vr session start", event, renderer);
    if(Boolean(renderer.xr.getCamera)){
      console.log("getCamera in xr");
      renderer.isXR = true;
    }

    player.camera.position.copy(player.camera.direction3d.position);

  } );

  renderer.xr.addEventListener( 'sessionend', function ( event ) {
    console.log("vr session end", event)
    // player.camera.direction3d.position.y=3.4;
    player.camera.position.copy(player.camera.direction3d.position);
    renderer.isXR = false;

  } );

  console.log("adding input", inputdevice_events.changed);
  addEvent(inputdevice_events.changed, parse_input);


  // if (window.innerWidth < 500) {
  //   let pads = new UiComponent(document.getElementById("app"), mobile);
  // } else {

    /* cleanup origin hack */
    // window.addEventListener(
    //   "keydown",
    //   (event) => {
    //     if (!player._receiveInput || event.defaultPrevented || player._keys[event.key.toLowerCase()]) return;
    //
    //     player._keys[event.key.toLowerCase()] = true;
    //   },
    //   true
    // );
    //
    // window.addEventListener(
    //   "keyup",
    //   (event) => {
    //
    //     if (event.defaultPrevented || !player._keys[event.key.toLowerCase()] ) return;
    //     delete player._keys[event.key.toLowerCase()];
    //
    //   },
    //   true
    // );


    if (process.env.VIEW == "FIRSTPERSON") {
      document.addEventListener(
        "mousedown",
        (e) => {
          if (e.target !== canvas) return;

          if (document.activeElement && typeof document.activeElement.blur == "function") document.activeElement.blur();

          lockPointer(e);

          let isRightMB;
          if ("which" in e) isRightMB = e.which == 3;
          else if ("button" in e) isRightMB = e.button == 2;

          if (isRightMB) teletransportating = true;
        });

      document.addEventListener(
        "mouseup",
        (e) => {
          if (e.target !== canvas) return;
          unlockPointer(e);
          if (teletransportating) {
            teletransportating = false;
            confirm.call(player);
          }
        })

      document.addEventListener(
        "contextmenu",
        (e) => {
          if (e.target !== canvas) return;
          lockPointer(e);
          teletransportating = true;
        });

      crosshair = document.createElement("section");
      crosshair.id = "crosshair";
      crosshair.style.display = "none";

      document.getElementById("app").insertAdjacentElement("beforebegin", crosshair);
    }
    else window.addEventListener("dblclick", lockPointer, false);

    document.addEventListener('pointerlockchange',       onPointerLockChange, false);
    document.addEventListener('mozpointerlockchange',    onPointerLockChange, false);
    document.addEventListener('webkitpointerlockchange', onPointerLockChange, false);

  //}

  const lastPos = window.sessionStorage.getItem(`last-pos-${window.scene}`);

  if (lastPos) {
    const rot    = lastPos.split(":")[1];
    const newLon = isNaN(rot) ? 0 : Number(rot);

    lon = newLon;
    calculateCameraFromLatLon();

    // lon = newLon;
    // calculateCameraFromLatLon();

  }
}
