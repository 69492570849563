console.log('./src/scene/stands/rotatedCollision.js');
import * as THREE from "three";

export default (min, max, angle, offset = {x:0, z:0}) => {

  console.log(offset)
  switch (angle) {
    case 0:
      return new THREE.Box3(min, max);
    case -Math.PI/2: 
      return new THREE.Box3(
        {
          x: -max.z + offset.x,
          y: min.y,
          z: min.x + offset.z,
        },
        {
          x: -min.z + offset.x,
          y: max.y,
          z: max.x + offset.z,
        }
      );
    case Math.PI/2: 
      return new THREE.Box3(
        {
          x: min.z + offset.x,
          y: min.y,
          z: -max.x + offset.z,
        },
        {
          x: max.z + offset.x,
          y: max.y,
          z: -min.x + offset.z,
        }
      );
    case Math.PI: 
      return new THREE.Box3(
        {
          x: -max.z + offset.x,
          y: min.y,
          z: -max.x + offset.z,
        },
        {
          x: -min.z + offset.x,
          y: max.y,
          z: -min.x + offset.z,
        }
      );
    default:
      return null;
  }
}