console.log('./core/player/colliderCheck.js');
import * as THREE       from "three";
import getCollisionSide from "../../utils/getCollisionSide";
import {dolly}          from "../"

let counter = 0;
let infoDiv;

if (process.env.DEBUG == "true") {
  infoDiv = document.createElement("label");
  infoDiv.style.position = "absolute";
  infoDiv.style.right    = "12px";
  infoDiv.style.top      = "12px";

  document.getElementById("app").insertAdjacentElement("beforeend", infoDiv);
}

function evaluateColliders(colliders) {
  //let impacts = { "+x": 1, "+z": 1, "-x": 1, "-z": 1 };
  let impacts       = [];
  let tickCallbacks = [];
  let collidersIn   = [];

  let count = 0;
  for (let collider of colliders) {
    collider.name = `count_${count}_${counter}`;
    // console.log("COLLIDERS", collider);
    counter++;
    // console.log("collider", this.collider);

    if (this.collider.intersectsBox(collider)) {

      if (collider.colliders) {
        const recursiveCall = evaluateColliders.call(this, collider.colliders);
        impacts      .push(...recursiveCall.impacts);
        //Object.keys(impacts).forEach(param => impacts[param] *= recursiveCall.impacts[param]);
        tickCallbacks.push(...recursiveCall.tickCallbacks);
        collidersIn  .push(...recursiveCall.collidersIn);
      }

      if (collider.tick && typeof collider.tick == "function") tickCallbacks.push(collider.tick);

      const throughReturn = collider.through && typeof collider.through == "function" && collider.through(this)

      if (throughReturn === true) {
        collidersIn.push(collider);
        continue;
      }
      else if (throughReturn instanceof Array) {
        impacts.push(...throughReturn);
        continue;
      }

      if (collider.colliders) continue;

      const side = getCollisionSide(collider, this.collider);
      side != null && impacts.push(side);

      // const amounts = ["z","x"]
      //   .map(coord => {
      //     let between = this.collider.max[coord] > collider.min[coord] || this.collider.min[coord] <= collider.max[coord];

      //     if (!between) return false;

      //     return Math.min(this.collider.max[coord] - collider.min[coord], collider.max[coord] - this.collider.min[coord], 1.3);

      //   })



      // //let betweenZ = this.collider.max.z > collider.min.z || this.collider.min.z <= collider.max.z;
      // //let betweenX = this.collider.max.x > collider.min.x || this.collider.min.x <= collider.max.x;

      // if (amounts[0] > amounts[1]) {
      //   console.log("Va por x");
      //   if (amounts[0] && this.position.x > collider.max.x) impacts.push(Math.PI); //impacts["-x"] = 0;
      //   else if (amounts[0] && this.position.x < collider.min.x) impacts.push(0); //impacts["+x"] = 0;

      //   else if (amounts[1] && this.position.z > collider.max.z) impacts.push(Math.PI/2); //impacts["-z"] = 0;
      //   else if (amounts[1] && this.position.z < collider.min.z) impacts.push(-Math.PI/2); //impacts["+z"] = 0;
      // }
      // else {
      //   console.log("Va por z");
      //   if (amounts[1] && this.position.z > collider.max.z) impacts.push(Math.PI/2); //impacts["-z"] = 0;
      //   else if (amounts[1] && this.position.z < collider.min.z) impacts.push(-Math.PI/2); //impacts["+z"] = 0;
      //   else if (amounts[0] && this.position.x > collider.max.x) impacts.push(Math.PI); //impacts["-x"] = 0;
      //   else if (amounts[0] && this.position.x < collider.min.x) impacts.push(0); //impacts["+x"] = 0;
      // }

      /*
      if (betweenZ && this.position.x > collider.max.x) impacts.push(Math.PI); //impacts["-x"] = 0;
      else if (betweenZ && this.position.x < collider.min.x) impacts.push(0); //impacts["+x"] = 0;

      else if (betweenX && this.position.z > collider.max.z) impacts.push(Math.PI/2); //impacts["-z"] = 0;
      else if (betweenX && this.position.z < collider.min.z) impacts.push(-Math.PI/2); //impacts["+z"] = 0; */
    }
  }

  return { impacts, tickCallbacks, collidersIn };
}

export default function () {
  this.collider.set(
    new THREE.Vector3(
      (dolly.position.x || 0)-0.65,
      (dolly.position.y || 0)-1,
      (dolly.position.z || 0)-0.65
    ),
    new THREE.Vector3(
      (dolly.position.x || 0)+0.65,
      (dolly.position.y || 0)+2,
      (dolly.position.z || 0)+0.65
    ),
  );

  // this.collider.set(
  //   new THREE.Vector3(
  //     (this.camera.direction3d.position.x || 0)-0.65,
  //     (this.camera.direction3d.position.y || 0)-1,
  //     (this.camera.direction3d.position.z || 0)-0.65
  //   ),
  //   new THREE.Vector3(
  //     (this.camera.direction3d.position.x || 0)+0.65,
  //     (this.camera.direction3d.position.y || 0)+2,
  //     (this.camera.direction3d.position.z || 0)+0.65
  //   ),
  // );

  if (this.colliders) {
    counter = 0;
    const { impacts, tickCallbacks, collidersIn} = evaluateColliders.call(this, this.colliders);
    //console.log(collidersIn);

    if (process.env.DEBUG == "true") {
      infoDiv.textContent = counter;
    }

    ;(this._insideColliders || []).forEach((collider, i) => {
      !collidersIn.includes(collider) && typeof collider.left == "function" && collider.left(this);
    });

    this._tickCallbacks = tickCallbacks;
    this._insideColliders = collidersIn;
    return impacts;
  }
}


/*
const impactDeltaX = ((this.collider.min.x + this.collider.max.x)/2) -((collider.min.x + collider.max.x)/2);
const impactDeltaZ = ((this.collider.min.z + this.collider.max.z)/2) -((collider.min.z + collider.max.z)/2);

const angle = -Math.atan2(impactDeltaZ, impactDeltaX)// - Math.PI / 2;

const edgeDeltaX = collider.max.x - collider.min.x + 1.3;
const edgeDeltaZ = collider.max.z - collider.min.z + 1.3;

const edgeAngle = Math.atan2(edgeDeltaZ, edgeDeltaX )// - Math.PI / 2;

const localImpacts = [];

if (angle > edgeAngle && angle < Math.PI - edgeAngle) localImpacts.push("+z");
if (angle > Math.PI - edgeAngle || angle < -Math.PI + edgeAngle) localImpacts.push("+x");
if (angle < -edgeAngle && angle > -Math.PI + edgeAngle) localImpacts.push("-z");
if (angle > -edgeAngle && angle < edgeAngle) localImpacts.push("-x");

impacts = impacts.concat(localImpacts);
*/
