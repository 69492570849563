console.log('./ui/redux/actions/calls.js');
export const CREATE_CALL = "CREATE_CALL";
export const HANG_PERSON = "HANG_PERSON";
export const SET_CALL    = "SET_CALL";

import getPeer from "../../../core/peer";
import getSocket, { getPlayerId, getCharacters } from "../../../core/socket";

import * as stream from "../../audio";
import socket      from "../../../core/socket";


console.__proto__.emoji = function (emoji, ...rest) {
  this.log("%c"+emoji, "padding: 1px 4px; background-color: #5c00be;border-radius: 3px; font-size:15px",...rest);
}

let alreadyAddedListeners = false;

export const addListeners = () => (dispatch, getState) => {
  if (alreadyAddedListeners) return;
  alreadyAddedListeners = true;

  const peer = getPeer()();

  peer.on("call", (call) => {
    const callId = call.metadata.id;

    call.on("error", (err) => {
      console.error("\n\nError en la call con ", callId,err,"\n\n")
    })

    if (!callId) {
      console.error("Alguien te llamó sin identificacion");
      return;
    }


    //if (call.metadata.type == "presentation") dispatch(presentatorCalled(callId, call))
    //else if (call.metadata.type == "talk") dispatch(personCalled(callId, call))
    //else if (call.metadata.type == "stand") dispatch(personCalledAboutStand(callId, call))

    if (call.metadata.type == "talk") {
      console.emoji("📲", `${callId} called`);

      dispatch(personCalled(callId, call))
    }

    if (process.env.AUTOACCEPT == "true" &&
      (
           call.metadata.type == "presentation"
        || call.metadata.type == "talk"
        || call.metadata.type == "stand"
      )
    ) dispatch(acceptCall(callId));
  })

  const socket = getSocket();

  socket.on("raisedhand",        (id) => dispatch(raisedHand(id)));
  socket.on("raisedhanddismiss", (id) => dispatch(hangPerson(id)))
  socket.on("endpresentation",   ()   => dispatch(hangAllSpeakers()));
  socket.on("speakerleft",       (id) => dispatch(hangPerson(id)));
  socket.on("left",              (id) => dispatch(hangPerson(id)));
  socket.on("hang",              (id) => dispatch(hangPerson(id)));
};

export const callPerson = (id, peerId) => (dispatch, getState) => {
  if (!peerId) {
    console.warn("No hay peer de el personaje", id);
    return;
  }

  console.emoji("📞", `Calling ${id}`);

  const ownId = getPlayerId();

  if (!ownId) {
    console.warn("Intentaste llamar a alguien antes de estar conectado con el server", ownId);
    return;
  }

  const deviceStream = stream.getStream();

  if (!deviceStream) {
    console.warn("Sin haber admitido el microfono", deviceStream);
    return;
  }

  const peer = getPeer();

  const call = peer.call(
    peerId,
    deviceStream,
    {
      metadata: {
        id  : ownId,
        type: "talk",
      },
    }
  );

  call.on("close", () => {
    // Cortar
    dispatch(hangPerson(id))
  })

  call.on("stream", (stream) => {
    dispatch(
      setCall(
        id,
        {
          stream,
          accepted: true,
          pending : false,
        }
      )
    );
  })

  call.on("error", (err) => {
    console.error("\n\nError en la call con ", callId, err, "\n\n")
  })

  dispatch({
    type   : CREATE_CALL,
    id,
    call,
    pending: true,
    offered: true,
    name   : (getCharacters()[id] || {}).name,
  })
};

export const hangPerson = (id) => (dispatch, getState) => {

  const call = getState().calls[id];

  if (!call) {
    return;
  }

  console.emoji("❌",`Hang ${id}`);


  call.call.close();

  setTimeout(() => {
    if (call.call.open) {
      console.log("%cNo se cerro","font-size: 15px; color: green;")
    }
    else {
      dispatch({
        type: HANG_PERSON,
        id,
      })

    }
  }, 500)

  //call.stream.close();


};

export const setCall = (id, newCall) => ({
  type: SET_CALL,
  call: newCall,
  id,
});

export const personCalled = (id, call) => ({
  type   : CREATE_CALL,
  id,
  call,
  pending: true,
  offered: false,
  name   : (getCharacters()[id] || {}).name,
});

export const presentatorCalled = (id, callStream) => (dispatch, getState) => {

  const call = getState().calls[id];

  if (call && call.handraise) {
    dispatch(setCall(id, {
      call: callStream,
    }))
    return;
  }

  dispatch({
    type   : CREATE_CALL,
    id,
    call   : callStream,
    pending: false,
    offered: false,
    speaker: true,
    name   : (getCharacters()[id] || {}).name,
  });
}

export const raisedHand = (id) => ({
  type     : CREATE_CALL,
  id,
  pending  : true,
  offered  : false,
  handraise: true,
  name     : (getCharacters()[id] || {}).name,
});


export const acceptCall = (id) => (dispatch, getState) => {
  const call = getState().calls[id];

  //if (call.stand) dispatch(hangEveryoneExcept(id))

  if (call.handraise) {
    getSocket().emit("raisedhandaccept", id);
    if (!call.call) return;
  }

  if (!call.call) {
    console.error("Se aceptó una llamada que no fue llamada");
    return;
  }

  console.emoji("✅",`Accepted ${id}`)

  const deviceStream = stream.getStream();

  call.call.answer(deviceStream);

  dispatch(setCall(id, { accepted: true }));

  call.call.on("stream", (stream) => {
    dispatch(setCall(id, { stream }));
  });
};

export const denyCall = (id) => (dispatch, getState) => {
  const call = getState().calls[id];

  if (!call) return;

  if (call.handraise) {
    getSocket().emit("raisedhanddismiss", id);
    return;
  }

  if (!call.call) {
    console.error("Se rechazó una llamada que no fue llamada (?");
    return;
  }

  console.emoji("❌",`Denied ${id}`)

  getSocket().emit("hang", id);

  dispatch(hangPerson(id));
}

export const hangAllSpeakers = () => (dispatch, getState) => {
  const calls = getState().calls;

  Object.keys(calls).forEach(id => {
    if (calls[id] && (calls[id].speaker || calls[id].handraise)) dispatch(hangPerson(id));
  })
}

export const hangEveryoneExcept = (id) => (dispatch, getState) => {
  const calls = getState().calls;

  Object.keys(calls).forEach(key => {
    if (key == id) return;
    dispatch(hangPerson(key));
  })
}

export const hangStandPeople = () => (dispatch, getState) => {
  const calls = getState().calls;

  Object.keys(calls).forEach(key => {
    calls[key].call && calls[key].call.metadata.type == "stand" && dispatch(denyCall(key));
  })
}

export const callStandPeople = (people = []) => (dispatch, getState) => {
  people.forEach(person => {
    const call = getPeer().call(person.peer, stream.getStream(), {
      metadata: {
        type: "stand",
        id  : getPlayerId(),
      },
    });

    call.on("stream", (stream) => {
      dispatch(setCall(person.id, {
        stream,
        pending : false,
        accepted: true,
      }))

      dispatch(hangEveryoneExcept(person.id))
    })

    dispatch({
      type   : CREATE_CALL,
      id     : person.id,
      call,
      pending: true,
      offered: true,
      name   : (getCharacters()[person.id] || {}).name,
    });
  })
}

export const personCalledAboutStand = (id, call) => ({
  type   : CREATE_CALL,
  id,
  call,
  pending: true,
  offered: false,
  stand  : true,
  name   : (getCharacters()[id] || {}).name,
});
