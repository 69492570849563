console.log('./ui/presentation/microphone.js');
import React,{ useEffect, useState, useRef } from "react";
// import Peer from "peerjs";
import adapter                    from 'webrtc-adapter';
import getSocket, { getPlayerId } from "../../core/socket";
import getPeer                    from "../../core/peer";
import { getStream, isMuted}      from "../audio";

/*
const peerConfig = {
  iceServers: [
    {
      urls: ["stun:stun.l.google.com:19302"]
    }
  ]
};*/

let speaking = false;
let people   = [];
let calls    = {};
//let connections = {};

export default function () {

  const socket = getSocket();
  const peer   = getPeer();

  socket.on("public", (newPeople) => {
    const stream = getStream();

    if (!stream || !peer) return;

    people.forEach(person => {
      if (newPeople.includes(person)) return;
      // Close call
      calls[person] && calls[person].close();
      delete calls[person];
    })

    newPeople.forEach(person => {
      if (people.includes(person)) return;
      // Start call

      const conn    = peer.connect(person);
      calls[person] = peer.call(
        person,
        stream,
        {
          metadata: {
            type: "presentation",
            id  : getPlayerId(),
          }
        },
      );
      // conn.on("open", () => {
      //   connections[person] = conn;
      //   conn.send("microphone_invite");
      // })

      // conn.on("data", (data) => {
      //   if (data == "microphone_accept") {
      //   }
      // })

      calls[person].on("error", (err) => {
        console.error("\n\nConnection error", err, "\n\n");
      });

      calls[person].on("close", (err) => {
        delete calls[person];
      });

    }); // newPeople.forEach(person => {

    people = newPeople;
  }); //socket.on("public", (newPeople) => {

  function stop() {
    socket.emit("speakerleft");
    // Object.values(connections).forEach(conn => {
    //   conn.send("Close");
    //   //conn.close();
    // });
    Object.values(calls).forEach((call) => call.close());
    calls  = {};
    people = [];
  }

  function start () {
    socket.emit("speakerjoin");
  }

  return { start, stop };
}
