console.log('./ui/welcome/components/selector.js');
import * as THREE    from 'three';
import React, {useRef, useState, useEffect, useCallback} from "react";
import styled        from "styled-components";
import {FBXLoader}   from "three/examples/jsm/loaders/FBXLoader";
import { getModels } from "../../../core/models"
import texts         from "../../text";
import { api }       from '../../../src/helpers/serverApi';

export default function ({ setUserModel, callbacks, mode }) {
  const container   = useRef(null);
  const sceneRef    = useRef(null);
  const cameraRef   = useRef(null);
  const rendererRef = useRef(null);
  const modelRef    = useRef(null);

  const models     = getModels();
  const modelsList = Object.keys(models);

  const [index, setIndex]     = useState(Math.floor(Math.random() * modelsList.length));
  const [loading, setLoading] = useState(true);

  //const loader = new FBXLoader();


  useEffect(() => {
    if (!container) return;

    container.current.classList.add("active");

    //TODO: wtf is this doing in here? this needs to be taken out and put in its own place

    const scene = new THREE.Scene();
    const clock = new THREE.Clock();


    // Create a basic perspective camera
    const camera = new THREE.PerspectiveCamera( 75, container.current.clientWidth/container.current.clientHeight, 0.1, 1000 );
    camera.position.set(0, 2.7, 4);
    camera.lookAt(0,2,0)


    // Create a renderer with Antialiasing
    const renderer = new THREE.WebGLRenderer({antialias:true, alpha: true});

    scene.add(new THREE.AmbientLight("#FFFFFF", 2.8))

    // const light = new THREE.PointLight("#FFFFFF", 0.6)
    // light.position.set(5, 5, 0)
    // scene.add(light);

    // Configure renderer clear color
    renderer.setClearColor("#FFFFFF", 0);

    // Configure renderer size
    renderer.setSize( container.current.clientWidth, container.current.clientHeight );

    // Append Renderer to DOM
    container  .current.appendChild( renderer.domElement );
    sceneRef   .current = scene;
    cameraRef  .current = camera;
    rendererRef.current = renderer;

    callbacks.tick(() => {
      if (!scene) return;
      modelRef.current && modelRef.current.mixer.update(clock.getDelta());
      renderer.render(scene, camera);
      if (modelRef.current) modelRef.current.rotation.y += 0.008;
    })


  }, [])

  useEffect(()=> {
    setLoading(true);

    if (!sceneRef.current || !modelsList[index]) return;

    //console.log(selected, models[selected].clone())

    const model  = models[modelsList[index]].getNewOne();

    if (modelRef.current) sceneRef.current.remove(modelRef.current);

    window.model = model;

    sceneRef.current.add(model);

    model.actions.idle.play();

    if (modelRef.current) model.actions.idle.time = modelRef.current.actions.idle.time;

    modelRef.current = model;
    setLoading(false);
  }, [index]);

  //if (loading) return null;

  const next = useCallback(() => {

    if (mode == "local") {
      container.current.classList.remove("active");
      setTimeout(() => {
        setUserModel(modelsList[index]);
      }, 500);
      return;
    }
    setLoading(true);

    console.log(`fetch ${api}/users/model`);
    fetch(
      `${api}/users/model`,
      {
        method : "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token"  : window.sessionStorage.getItem("auth-token"),
        },
        body   : JSON.stringify({model:modelsList[index]})
      }
    )
      .then(data => data.json())
      .then(()   => {
        container.current.classList.remove("active");

        setLoading(false);
        setTimeout(() => {
          setUserModel(modelsList[index]);
        }, 500);
      })
      .catch(() => {
        setLoading(false);
      });

  }, [index]);

  /* className="material-icons"  material icons from google fonts linked in index*/
  return (
    <>
      <Container ref={container} />
      <Submit     onClick={next} disabled={loading}>{texts.ui.forms.start}</Submit>
      <ArrowLeft  onClick={() => setIndex(i => i < 1                      ? modelsList.length - 1 : i - 1 )}/>
      <ArrowRight onClick={() => setIndex(i => i >= modelsList.length - 1 ? 0                     : i + 1 )}/>
    </>
  );
}

const Container = styled.div`
  width: 600px;
  height: 600px;
  position: absolute;

  opacity: 0;
  transition: opacity 300ms;

  &.active {
    opacity: 1;
  }
`

// const Arrow({direction, onClick}){
//   return <span className="material-icons">arrow_back_ios</span>
// }


const Arrow = styled.div.attrs({
    className: 'material-icons'
})`
  border: solid ${p => p.loading == "true" ? "gray" : "white"};
  border-width: 0 12px 12px 0;
  display: inline-block;
  padding: 12px;
  position: absolute;
  font-size: 6em;
  top: 50%;
  z-index: 10;
  cursor: pointer;
`

const ArrowLeft = styled(Arrow)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: calc(50vw - 120px);
`

const ArrowRight = styled(Arrow)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: calc(50vw - 120px);
`

const Submit = styled.button`
  background-color: #0000002b;
  border: none;
  padding: 14px 22px;
  border-radius: 100px;
  color: white;
  font-weight: bold;
  font-size: 17px;
  box-shadow: 0px 3px 13px 13px #0000000f;
  cursor: pointer;
  position: absolute;
  top: 100vh; /* calc(50vh + 250px); */

  &:focus {
    outline: none;
  }
`;
