console.log('./src/scene/colliders/index.js');
import * as THREE from "three";
import { scene }  from "../../../core";

import { Stage, Stairs, Room, Seat, Portal, Rotated } from "./special";

let allColliders = [];

function parseArray (colliders) {
  if (!scene) throw new Error("Scene is undefined");
  let result = [];
  colliders.forEach(
    (collider) => {
      if (collider.length) {

        const group = parseArray(collider);
        let   box   = new THREE.Box3()

        box.setFromPoints([...group.map(a => a.max), ...group.map(a => a.min)])

        if (process.env.DEBUG == "true") {
          var helper = new THREE.Box3Helper( box, 0x0000ff );
          scene.add( helper );
        }

        box.colliders = group;

        result.push(box);

        return;
      }

      if (collider.type) {
        let specialCollider;

             if (collider.type == "stairs")  specialCollider = new Stairs (collider);
        else if (collider.type == "stage")   specialCollider = new Stage  (collider);
        else if (collider.type == "room")    specialCollider = new Room   (collider);
        else if (collider.type == "seat")    specialCollider = new Seat   (collider);
        else if (collider.type == "portal")  specialCollider = new Portal (collider);
        else if (collider.type == "rotated") specialCollider = new Rotated(collider);
        else return;

        if (process.env.DEBUG == "true") {
          let helper = null;
          if (specialCollider.colliders) {
            helper = new THREE.Box3Helper( specialCollider, 0x0000ff );

            specialCollider.colliders.forEach(
              collider => {
                scene.add(new THREE.Box3Helper( collider, 0x00ff00 ))
              }
            );

          } else {
            helper = new THREE.Box3Helper( specialCollider, 0x00ff00 );
          }
          scene.add( helper );
        } // if (process.env.DEBUG == "true") {


        result.push(specialCollider);
        return;
      } // if (collider.type) {


      const box = new THREE.Box3(
        new THREE.Vector3(
          collider.min.x,
          collider.min.y || 0,
          collider.min.z,
        ),
        new THREE.Vector3(
          collider.max.x,
          collider.max.y || 2,
          collider.max.z,
        ),
      );

      if (collider.simetrical) result.push(
        parseArray([
          {
            ...collider,
            min: {
              ...collider.min,
              x: -collider.max.x,
            },
            max: {
              ...collider.max,
              x: -collider.min.x,
            },
            simetrical: false,
          },
        ])[0]
      );
      // Debug only

      if (process.env.DEBUG == "true") {
        var helper = new THREE.Box3Helper( box, 0xff0000 );
        scene.add( helper );
      }

      result.push(box);
    } // (collider) => {

  ); // colliders.forEach(

  return result;
} // function parseArray (colliders) {


export default function (colliders) {
  const parsedColliders = parseArray(colliders);

  // if (process.env.DEBUG == "true") {

  //   specialColliders.forEach((box) => {
  //     var helper = new THREE.Box3Helper( box, 0x00ff00 );
  //     scene.add( helper );
  //   })
  // }

  allColliders.push(...parsedColliders) //, ...specialColliders);

  return allColliders;
}

export function addSpecialCollider(collider) {
  allColliders.push(collider);
}
