console.log('./ui/item/index.js');
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

export default ({ callbacks }) => {

  const [ data, setData ]   = useState({});
  const [ index, setIndex ] = useState(0);
  const descRef = useRef();

  useEffect(() => {
    const data = callbacks.init();
    setData(data);
    descRef.current.innerHTML = data.description;
  },[])

  function openWebsite() {
    const popup = window.open(data.more);
    popup.focus();
  }

  return (
    <Wrapper>
      <Content>
        <Header>
          <Title>{data.title || ""}</Title>
          <Icon onClick={() => callbacks.unfocus()} src="/public/icons/close.png"/>
        </Header>
        <Descripion ref={descRef}/>
        {
          data.more
            ? <SeeMore onClick={openWebsite}>Ver más</SeeMore>
            : null
        }
      </Content>
      {
        data.video
          ? <VideoPlayer controls src={data.video} autoplay/>
          : null
      }
      {
        data.elements && data.elements.length == 1
          ? <SingleImage src={data.elements[0].src} />
          : null
      }
      {
        data.elements && data.elements.length > 1
          ? (
            <ElementsContainer>
              {
                data.elements.map((element,i) => (
                  <Element key={i} src={ element.src} index={i} selected={index}/>
                ))
              }
              <Left onClick={() => setIndex(i => i == 0 ? data.elements.length - 1 : i-1)}/>
              <Right onClick={() => setIndex(i => i == data.elements.length - 1 ? 0 : i+1)}/>
              <Dots>
                {
                  data.elements.map((_,i) => (
                    <Dot key={i} selected={index == i} onClick={() => setIndex(i)}/>
                  ))
                }
              </Dots>
            </ElementsContainer>
          )
          : null
      }
      {
        !data.video && !(data.elements && data.elements.length == 1)
          ? <Space />
          : null
      }

    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  z-index: 1000;

  align-items: center;
  height: 100vh;
  width: 100vw;

  justify-content: center;
`;

const Content = styled.div`

  width: 35vw;

  padding: 12px;

  background-color: #000000AA;
  color: white;

  & h1 {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  margin-right: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 26px;
  height: 26px;

  padding: 6px;
`;

const Descripion = styled.p`
  max-height: 45vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #ffffff44;
  }

`;

const SeeMore = styled.a`
  padding: 12px 16px;
  background-color: white;
  border-radius: 50px;
  color: #444;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: #DDD;
    cursor: pointer;
  }
`;

const VideoPlayer = styled.video`
  width: 40vw;
  height: 23vw;
`;

const Space = styled.div`
  width: 40vw;
  overflow: hidden;
`;

const ElementsContainer = styled.div`
  position: absolute;
  top: calc(50vh - 15vw);
  height: 30vw;
  width: 40vw;
  right: 10vw;
  display: flex;
`;

const Element = styled.img`
  height: 100%;

  position: absolute;

  transition: transform 300ms, filter 300ms;

  margin-left: 50%;

  ${p => p.index == p.selected
    ? `
      transform: translateX(-50%);
      z-index: ${100 - Math.abs((p.index - p.selected))};
      `
    : `
      z-index: ${100 - Math.abs((p.index - p.selected))};
      transform: translateX(-50%) translateX(${300* Math.tanh((p.index - p.selected)*0.3)}px) scale(${1/Math.cosh(p.index - p.selected)});
      filter: brightness(0.3);

    `

  }
  /*opacity: 0.8; */
`;

const Left = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 101;

`;

const Right = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 101;
  right: 0px;
`;

const Dots = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -50px;
`;

const Dot = styled.div`
  width: 14px;
  height: 14px;
  margin: 0px 4px;

  border-radius: 50px;
  box-sizing: border-box;
  border: solid 2px #ffffffad;

  &:after {
    content: " ";
    transition: clip-path 500ms;
    position: absolute;

    width: 14px;
    height: 14px;

    margin-left: -2px;
    margin-top: -2px;
    background-color: #ffffffad;
    border-radius: 50px;

    clip-path: circle(${p => p.selected ? 100 : 0}%);
  }

`;

const Title = styled.h1`
  display: flex;
  flex: 1;
`;

const SingleImage = styled.div`
  background-image: url("${p => p.src}");
  width: 32vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
`;
