console.log('./src/scene/colliders/special/rotated.js');
import * as THREE       from "three";
import {scene}          from "../../../../core";
import getCollisionSide from "../../../../utils/getCollisionSide";
import colliders        from "..";

export default class Rotated extends THREE.Box3 {
  constructor({min, max, rotation}) {
    //if (process.env.DEBUG != "true") return;
    super();

    this.rotation  = rotation;
    const material = new THREE.LineBasicMaterial({
      color: 0xff0000
    });

    this.mid = new THREE.Vector3(
      (min.x + max.x)/2,
      (min.y + max.y)/2,
      (min.z + max.z)/2,
    );

    // console.log("mid", this);

    const points = (
      process.env.DEBUG == "true"
      ? [
          [0,1,0],
          [0,0,0],
          [1,0,0],
          [0,0,0],
          [0,0,1],
          [0,1,1],
          [0,1,0],
          [1,1,0],
          [1,0,0],
          [1,0,1],
          [1,1,1],
          [1,1,0],
          [1,1,1],
          [0,1,1],
          [0,0,1],
          [1,0,1],
        ]
      : [
          [0,0,0],
          [0,0,1],
          [1,0,0],
          [1,0,1],
          [1,1,1],
        ]
    ).map(
      ([x, y, z]) => {
        const vert = new THREE.Vector3(
          (x ? max : min).x,
          (y ? max : min).y,
          (z ? max : min).z,
        );

        vert.subVectors(vert, this.mid)
            .applyAxisAngle(new THREE.Vector3(0,1,0), this.rotation)
            .add(this.mid);

        return vert;
      }
    );

    super();
    this.setFromPoints(points);

    this.main = new THREE.Box3(min, max);

    if (process.env.DEBUG == "true") {
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const line     = new THREE.Line(geometry, material);
      scene.add(line);
    }

    this.points  = points;
    this.through = this.through.bind(this);

  }

  through(player) {
    const points = [
      [ 0, 0 ],
      [ 0, 1 ],
      [ 1, 1 ],
      [ 1, 0 ],
    ].map(
      ([x, z]) => {
        const vec = new THREE.Vector3(
          player.collider[x ? "max" : "min"].x,
          player.collider.min.y,
          player.collider[z ? "max" : "min"].z,
        );

        // console.log("subVector", vec, this);
        if(!this.mid){
          // console.log("no mid", this);
          this.mid = new THREE.Vector3(
            (this.min.x + this.max.x)/2,
            (this.min.y + this.max.y)/2,
            (this.min.z + this.max.z)/2,
          );
        }
        vec.subVectors(vec, this.mid)
           .applyAxisAngle(new THREE.Vector3(0,1,0), -this.rotation)
           .add(this.mid);

        return vec;
      }
    );

    const collider = new THREE.Box3()
    collider.setFromPoints(points);
    collider.max.y = player.collider.max.y;


    if (!collider.intersectsBox(this.main)) return true;

    const side = getCollisionSide(this.main, collider);

    return side != null ? [side + this.rotation] : []

  }
}
