console.log('./ui/mobile/index.js');
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import getPlayer from "../../core/player";

export default () => {

  const playerRef = useRef(getPlayer());

  function keydown(key) {
    playerRef.current._keys[key] = true;
  }

  function keyup(key) {
    playerRef.current._keys[key] = false;
  }

  return (
    <Bottom>
      <PadsContainer>
        <Pad onPointerDown={() => keydown("w")} onPointerOut={() => keyup("w")} style={{marginBottom: 24, marginRight: 24}} >w</Pad>
        <Pad onPointerDown={() => keydown("d")} onPointerOut={() => keyup("d")}>d</Pad>
        <Pad onPointerDown={() => keydown("a")} onPointerOut={() => keyup("a")}>a</Pad>
        <Pad onPointerDown={() => keydown("s")} onPointerOut={() => keyup("s")} style={{ marginLeft: 24}} >s</Pad>
      </PadsContainer>
    </Bottom>

  );
}

const Bottom = styled.div`
  bottom: 60px;
  width: 80vw;
  position: absolute;
  height: 80vw;
  box-sizing: border-box;
  padding: 12px;
  margin-left: 10vw;
`;

const PadsContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 100%;
  width: 100%;
  transform: rotateZ(45deg);
`;

const Pad = styled.div`
  display: inline-block;
  background-color: #00000066;
  box-sizing: border-box;
  width: calc(50% - 12px);
  height: calc(50% - 12px);
  user-select: none;
  -webkit-user-select: none;
`;
