console.log('./ui/calls/index.js');
import React,{ useEffect, useState, useRef }              from "react";
import { useSelector, useDispatch }                       from "react-redux";
import { addListeners, callPerson, acceptCall, denyCall } from "../redux/actions/calls";
import getSocket, { getCharacters, getPlayerId }          from "../../core/socket";

import Player      from "./player";
import getPlayer   from "../../core/player";
import getPeer     from "../../core/peer";
import * as stream from "../audio";
import socket      from "../../core/socket";
import { player }  from "../../core";

import {
  Icon,
  Wrapper,
  VideoPlayer,
  Centered,
  mainLabel,
  PlayerRow,
  PlayerView,
  Indicator,
  Option,
  CallPlayer,
  LabelIcon,
  Span,
  AcceptPlayer,
  PlayerEmptyView,
  CallRequests,
} from "./styles";




export default ({ parent, callbacks }) => {

  console.log("Calls component rendered");

  const peer            = useRef(null);
  const socketRef       = useRef(null);
  const playerRef       = useRef(null);

  const videoRef        = useRef(null);

  const speakersRef     = useRef([])
  const raisedhandsRef  = useRef([])

  const acceptedListRef = useRef([]);

  const [ muted, setMuted ]          = useState(false);
  const [ clicked, setClicked ]      = useState(false);
  const [ nearPeople, setNearPeople] = useState([]);

  const calls    = useSelector(state => state.calls);
  const dispatch = useDispatch();

  function onNearbyPlayers(players) {
    setNearPeople(players);
  }

  useEffect(() => {
    getPlayer().onNearbyPlayers = onNearbyPlayers;
    dispatch(addListeners());
  }, [])


  useEffect(() => {
    // Llamar a la gente que está cerca
    if (process.env.AUTOACCEPT == "true") {
      nearPeople.forEach((player) => {
        if (calls[player.id])           return;
        if (!player.id || !player.peer) return;
        dispatch(callPerson(player.id, player.peer));
      });
    }

    // Cortarle a la gente que está lejos
    Object.keys(calls).forEach(id => {
      const call = calls[id];
      if (
          !call.speaker   &&
          !call.handraise &&
          !nearPeople.some(player => player.id == id)
         ) dispatch(denyCall(id))
    });

  }, [ nearPeople ]);

  function toggleAudio() {
    const isEnabled = stream.isEnabled();

    if (isEnabled) stream.mute();
    else           stream.unmute();

    setMuted(isEnabled);
  }

  let callRequests         = [];
  let someoneHasStream     = false;
  let someSpeakerHasStream = false;

  return <>
    <Wrapper onClick={() => setClicked(true)}>
      <PlayerRow>
        <Option own={true}>
          {
            muted
              ? <Icon onClick={toggleAudio} src="public/icons/mutemic.png"/>
              : <Icon onClick={toggleAudio} src="public/icons/mic.png"/>
          }
        </Option>
        <PlayerView autoPlay muted ref={videoRef}>No tenes video, un bajon</PlayerView>
        <label>Tú</label>
      </PlayerRow>
      {
        Object.keys(calls).map((id) => {
          //if (tracks.some(t => t.id == id)) return null;
          const call = calls[id];

          if (call.stream) {
            someoneHasStream = true;
            if (call.speaker) someSpeakerHasStream = true;
          }

          if (call.accepted && call.stream && call.handraise) return (
            <Player
              speaker={speakersRef.current.includes(id)}
              key    ={id}
              player ={call}
              options={<Icon src="public/public/icons/hand.png" onClick={() => dispatch(denyCall(id))}/>}
            />
          );

          if (call.accepted && call.stream && clicked) return (
            <Player
              speaker={speakersRef.current.includes(id)}
              key    ={id}
              player ={call}
            />
          );

          if (call.handraise) callRequests.push(
            <PlayerRow key={id}>
              <AcceptPlayer key={id}>
                ¿Dejar hablar a {call.name}?
                <Span onClick={() => dispatch(acceptCall(id))}>Si</Span>
                <Span onClick={() => dispatch(denyCall(id))}>No</Span>
              </AcceptPlayer>
            </PlayerRow>
          );
          else if (call.speaker) callRequests.push(
            <PlayerRow key={id} onClick={() => dispatch(acceptCall(id))}>
              <AcceptPlayer>
                Click para escuchar a {call.name} en la presentación
              </AcceptPlayer>
            </PlayerRow>
          );
          else if (call.stand) callRequests.push(
            <PlayerRow key={id} onClick={() => dispatch(acceptCall(id))}>
              <AcceptPlayer key={id}>
                ¡{call.name} quiere charlar!
                <Span onClick={() => dispatch(acceptCall(id))}>Si</Span>
                <Span onClick={() => dispatch(denyCall(id))}>No</Span>
              </AcceptPlayer>
            </PlayerRow>
          );


          if (call.pending && !call.offered && !call.speaker && !call.handraise && !call.stand) callRequests.push(
            <AcceptPlayer key={id}>
              ¿Aceptar a {call.name}?
              <Span onClick={() => dispatch(acceptCall(id))}>Si</Span>
              <Span onClick={() => dispatch(denyCall  (id))}>No</Span>
            </AcceptPlayer>
          )

          return (
            <PlayerRow key={id}>
              <Option>

              </Option>
              <PlayerEmptyView />
              <CallPlayer>{process.env.AUTOACCEPT == "true" ? "Conectándose con" : "Esperando"} {call.name}...</CallPlayer>
            </PlayerRow>
          )
        })
      }
      {
        process.env.AUTOACCEPT !== "true"
          ? nearPeople.map(player => {
            if (calls[player.id]) return;

            return (
              <PlayerRow
                key    ={player.id}
                onClick={() => dispatch(callPerson(player.id, player.peer))}
              >
                <Option>
                  <Icon src="public/public/icons/call.png" />
                </Option>
                <PlayerEmptyView />
                <CallPlayer>Hablar con {player.name}</CallPlayer>
              </PlayerRow>
            )
          })
          : null
      }

      <CallRequests>
        { clicked || (!someoneHasStream && !someSpeakerHasStream) ? callRequests : null}
        {
          !clicked && someSpeakerHasStream
            ? (
              <PlayerRow>
                <AcceptPlayer>
                  Click para escuchar la presentación
                </AcceptPlayer>
              </PlayerRow>
            )
            : null
        }
        {
          !clicked && !someSpeakerHasStream && someoneHasStream
            ? (
              <PlayerRow>
                <AcceptPlayer>
                  Click para escuchar a los demás
                </AcceptPlayer>
              </PlayerRow>
            )
            : null
        }
      </CallRequests>
    </Wrapper>
  </>
}
