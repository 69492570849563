console.log('./core/player/export.js');
import intoDomain from "../../utils/intoDomain";
import { player, dolly } from "..";

export default function () {

  const teletransported = this._teletransported;

  if (teletransported) this._teletransported = false;

  // console.log("export", this.camera.direction3d)

  let result = {
    timestamp: new Date().getTime(),
    animation: this.animation || {
          name     : this._moving    ? "walk" : "idle",
          timeScale: this._keys["s"] ? -1     : 1,
          //time: this.action.time || 0,
        },
    teletransported,
    position : {
      x: dolly.position.x||0,
      // x: this.camera.direction3d.position.x||0,
      y: 0, //this.camera.direction3d.position.y||0, //TODO: allow for stairs and ramps
      z: dolly.position.z||0,
      // z: this.camera.direction3d.position.z||0,
    },
    rotation : {
      x: 0,
      y: intoDomain(
          // this.camera.direction3d.rotation.y + ((process.env.VIEW == "FIRSTPERSON")
          dolly.rotation.y + ((process.env.VIEW == "FIRSTPERSON")
              ? -Math.PI
              : dolly.rotation.y)
              // : this.camera.direction3d.rotation.y)
        ),
      z: 0,
    },
  }
  return result;
}
