console.log('./src/scene/colliders/floors.js');
import * as THREE from "three";
import { scene } from "../../../core";

export default function (floors) {
  return floors.map(floor => {
    let box = new THREE.Box3(
      new THREE.Vector3(floor.min.x, floor.y, floor.min.z),
      new THREE.Vector3(floor.max.x, floor.y, floor.max.z),
    );
    if (process.env.DEBUG === "true") {
      scene.add(new THREE.Box3Helper(box, "#0000ff"))
    }
    return box;
  })
}
