console.log('./src/scene/lights.js');
import * as THREE from "three";
import { scene, renderer } from "../../core";

import * as RectLightUniformsLib from "three/examples/jsm/lights/RectAreaLightUniformsLib.js";
//import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';

export default function (ambient) {

  if (ambient.lights) {

    if (ambient.sky) renderer.setClearColor(ambient.sky, 1);

    ambient.lights.forEach(data => {
      if (!THREE[data.type]) return;

      const light = new THREE[data.type](...(data.constructor || []));

      Object.keys(data).forEach((key) => {
        if (key == "type" || key == "constructor") return;
        
        let val = light;
        const path = key.split("_");
        for (let i = 0; i < path.length-1; i++) val = val[path[i]];
        val[path[path.length - 1]] = data[key];
      

      })

      // if (process.env.DEBUG == "true" && data.type == "RectAreaLight") {
        
      //   const helper = new RectAreaLightHelper(light);
      //   light.add(helper)
      // }

      // if (process.env.DEBUG == "true") console.log(light)

      scene.add(light)
    });

    ambient = new THREE.AmbientLight( 0xffffff, 0.7 )
    scene.add(ambient);

  }

}
  
// export function getLights() {
//   return {
//     light,
//     light2,
//     ambient
//   }
// }

// export function restore() {
//   light.intensity = 0.6;
//   light2.intensity = 0.6;
//   ambient.intensity = 0.7;
// }