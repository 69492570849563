console.log('./core/player/tick.js');
import * as inputController from "../inputController";

export default function ({ delta, frustum }) {
  this.input();
  // this.0.direction3d.position.y+=delta;
  // console.log(this.camera.direction3d.position);


  if (process.env.VIEW != "FIRSTPERSON") {

    if (!this.mixer) return;

    //this.mixer.update(delta);
    // this.model.position.y += 0.5;

    this.model.tick({ delta, frustum, isPlayer: true })
  }

  //this._transitionTime += delta;
  this._tickCallbacks.forEach((cb) => cb(this, {delta, frustum}));

  //stands.forEach(stand => stand.tick(this, frustrum))
}
