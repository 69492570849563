console.log('./core/character.js');
import * as THREE                      from 'three';
import {FBXLoader}                     from "three/examples/jsm/loaders/FBXLoader";
import {GLTFLoader}                    from "three/examples/jsm/loaders/GLTFLoader";
import { camera, player, scene, origin }       from "./index";
import intoDomain                      from "../utils/intoDomain";
import interpolate, { interpolateRot } from "../utils/interpolate"
import { getModels }                   from "./models";
import * as SkeletonUtils              from "three/examples/jsm/utils/SkeletonUtils";
import store                           from "../ui/redux/store";

// import { callPerson, hangPerson }      from "../ui/redux/actions/calls";

import getPlayer                       from "./player";

window.getCalls = () => store.getState().calls;

import VolumeMeter from "../utils/VolumeMeter";

const characters = {};

window.lookAtFarthest = () => {
  //*****
  // getPlayer().camera.lookAt(characters[getPlayer().farthestCharacter].position);
}

export default class Character {
  constructor(data) {
    const models     = getModels();
    // console.log(data, models)
    if(!models[data.character]) throw new Error(`Datos para el modelo de personaje ${data.character} no se han encontrado`);
    const loader     = new FBXLoader();
    const fileLoader = new THREE.FileLoader();
    const fbxLoader  = new FBXLoader();
    console.log({SkeletonUtils})
    const model      = SkeletonUtils.clone(models[data.character]);

    // OLD WORKING METHOD
    // fbxLoader.load(`${window.location.pathname}public/assets/characters/${data.character}.fbx`, (obj) => {
    //   obj.traverse((child) => {
    //     if (child.isMesh && child.material && child.material.map) {
    //       child.material.map.minFilter = THREE.LinearFilter;
    //       child.material.metalness = 0;
    //     }
    //   });
    //   // gltf.scene.children[0].traverse((child) => {
    //   //   if (child.isMesh && child.material) child.material.metalness = 0;
    //   // });
    //   res(obj)
    // })

    model.frustumCulled = false;
    model.scale.set(0.008,0.008,0.008);

    model.enabled   = true;
    model.modelName = data.character;
    model.socketId  = data.id;
    model.email     = data.email;
    model.mixer     = new THREE.AnimationMixer( model );
    model.peer      = data.peer;
    model.actions   = {};

    models[data.character].animations.forEach(animationData => {
      const name = animationData.name.split("|").pop();
      model.actions[name] = model.mixer.clipAction(animationData);
      model.actions[name].setLoop( THREE.LoopRepeat );
      model.actions[name].clampWhenFinished = true;
    });

    if (data.animation && model.actions[data.animation.name]) {
      model.actions[data.animation.name].play();
    }
    else if (model.actions.idle) {
      model.actions.idle.play();
    }


    model.tick                = this.tick               .bind(model);
    model.update              = this.update             .bind(model);
    model.remove              = this.remove             .bind(model);
    model.fadeToAction        = this.fadeToAction       .bind(model);
    model.recalculateDistance = this.recalculateDistance.bind(model);
    model.suscribeToStore     = this.suscribeToStore    .bind(model);
    model.minify              = this.minify             .bind(model);
    model.disconnect          = this.disconnect         .bind(model);
    model.interpolate         = interpolate;
    model.interpolateRot      = interpolateRot;

    model.ui = document.createElement("div");
    model.ui.insertAdjacentHTML("beforeend",`
      <div class="icon-wrapper"><section class="meter"></section><section class="circle"></section></div>
      <label>${data.name}</label>
    `);

    model.name = data.name;
    document.getElementById("app").appendChild(model.ui);

    model.ui.classList.add("character-ui");
    model.ui.style.display = "none";

    model.position.set(
      data.position.x,
      data.position.y,
      data.position.z,
    );

    model.rotation.set(
      data.rotation.x,
      data.rotation.y,
      data.rotation.z,
    );

    // model.transparent = true;

    const initialPosition = {
      position: {
        x: model.position.x,
        y: model.position.y,
        z: model.position.z,
      },
      rotation: {
        x: model.rotation.x,
        y: model.rotation.y,
        z: model.rotation.z,
      }
    };

    model._idle                 = true;
    model._transitionTime       = 0;
    model._last                 = initialPosition;
    model._target               = initialPosition;
    model._action               = model.actions.idle;
    model._activeCharacterIndex = 2;

    model.suscribeToStore();
    model.recalculateDistance();

    characters[model.socketId] = model;

    // change to move world instead of camera
    scene.add(model);
    // origin.add(model);

    return model;
  }

  update(allCharacters) {

    if (!allCharacters[this.socketId]) {
      //console.warn(`The model with socketId ${this.socketId} wasn't found in the last update`);
      //this.visible = false;

      return;
    }

    const newData = allCharacters[this.socketId];

    if (this.visible == false) {
      this._last   = newData;
      this._target = newData;
      this._transitionTime = 0;

      return;
    }

    this.peer = newData.peer;

    if (newData.teletransported) {
      //this._last = newData;

      this._last = newData;

    } else {

      this._last = {
        position: {
          x: this.position.x,
          y: this.position.y,
          z: this.position.z,
        },
        rotation: {
          x: this.rotation.x,
          y: this.rotation.y,
          z: this.rotation.z,
        }
      };
    }

    this._target = newData;
    this._transitionTime = 0;

    //return;

    if (newData.animation) {
      this.fadeToAction(newData.animation.name);

      this._action.timeScale = newData.animation.timeScale;

    }

    this.recalculateDistance();
  }

  tick({delta, frustum, isPlayer}) {

    // if (this.visible) {
    //   this.label.style.display = "none";
    //   this.visible = false;
    //   this._last = null;
    // }
    // return;


    this.mixer.update(delta);
    this._transitionTime += delta;

    if (frustum) {

      const labelVector = (new THREE.Vector3());

      this.getWorldPosition(labelVector);

      labelVector.y += 2.2;

      const isInsideFrame = frustum.containsPoint(labelVector) || frustum.containsPoint(new THREE.Vector3(labelVector.x, labelVector.y - 3.8, labelVector.z));


      // TODO: this check for visibility seems to be less useful for performance if
      //   labels become 3d objects for use within VR

      this.visible = true;

      if(isInsideFrame || isPlayer) {
        labelVector.y += 1.6;

        this.visible = true;

        if (!this.far) {

          labelVector.project(camera);
          const x = (labelVector.x *  .5 + .5) * window.innerWidth;
          const y = (labelVector.y * -.5 + .5) * window.innerHeight;

          this.ui.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
          this.ui.style.display = "";
        } else this.ui.style.display = "none";

        if (!this._last || !this._target) return;

        if (this._target.position.x != undefined) this.position.x = this.interpolate(this._last.position.x, this._target.position.x, this._transitionTime);
        if (this._target.position.y != undefined) this.position.y = this.interpolate(this._last.position.y, this._target.position.y, this._transitionTime);
        if (this._target.position.z != undefined) this.position.z = this.interpolate(this._last.position.z, this._target.position.z, this._transitionTime);

        if (this._target.rotation.y != undefined) this.rotation.y = this.interpolateRot(this._last.rotation.y, this._target.rotation.y, this._transitionTime);

        if (this._transitionTime > 0.6) this._last = null;

      } else {

        this.ui.style.display = "none";
        this.position.set(this._target.position.x, this._target.position.y, this._target.position.z);
        this.rotation.set(this._target.rotation.x, this._target.rotation.y, this._target.rotation.z);
        this._last   = null;
        // this.visible = false;

        //this.minify();

      }
    }
  }

  disconnect() {
    this.remove();

    const player = getPlayer();

    if (player.farthestCharacter) {

      const distance         = this.position.distanceToSquared(player.position);
      const farthestDistance = characters[player.farthestCharacter].position.distanceToSquared(player.position);

      if (distance < farthestDistance || this.socketId == player.farthestCharacter) player.activeCharacters = Math.max(player.activeCharacters-1, 0);

      player.farthestCharacter = null;

      console.log("%c"+player.activeCharacters,"background-color:#552288;padding: 6px; font-size:14px;border-radius:3px","Active players")
    }
  }

  remove () {
    this.ui.remove();
    scene.remove(this);
  }

  fadeToAction( name, duration = 0.3) {
    if (!this.actions[name]) throw new Error(`La animación ${name} no existe.`);


    let previousAction = this._action;
    let activeAction = this.actions[ name ];
    this._action = this.actions[name];

    if ( previousAction !== activeAction ) previousAction.fadeOut( duration );
    else return;

    activeAction.timeScale = 1;

    activeAction
      .reset()
      .setEffectiveTimeScale(1)
      .setEffectiveWeight(1)
      .fadeIn(duration)
      .play();

  }

  recalculateDistance() {
    const player = getPlayer();

    // Set player near o far
    const distance = this.position.distanceToSquared(player.position);
    this.distance = distance;

    if (!this.near && distance < 400 && this.peer) {
      store.dispatch(callPerson(this.socketId, this.peer));
    }

    if (this.near && distance > 400 && this.peer) {
      store.dispatch(hangPerson(this.socketId));
    }

    this.near = distance < 400;
    this.far  = distance > 600;


    // Handle many people minification based on
    // relative position with the player



    // const maxDistance = (1000/Object.keys(characters).length + 10)**2;

    // if (distance > maxDistance) this.minify();

    // const farthest = player.farthestCharacter
    //   ? characters[player.farthestCharacter].position.distanceToSquared(player.position)
    //   : 0;


    // if (player.farthestCharacter == this.socketId) {}
    // else if (player.maximumActiveCharacters <= player.activeCharacters && !this.active) {
    //   if (distance < farthest) {
    //     characters[player.farthestCharacter]?.minify();
    //     console.log("Minifying", characters[player.farthestCharacter] ? player.farthestCharacter : "noone", "to make space for me,", this.socketId)
    //     player.farthestCharacter = this.socketId;
    //     this.active = true;
    //   } else {
    //     console.log("Minifying myself because there is no space")
    //     this.minify();
    //   }
    // } else if (!this.active) {
    //   if (distance > farthest) {
    //     console.log("There's plenty of room for", this.socketId)
    //     player.farthestCharacter = this.socketId;
    //   }

    //   player.activeCharacters++;
    //   this.active = true;
    //   console.log("%c"+player.activeCharacters,"background-color:#552288;padding: 6px; font-size:14px;border-radius:3px","Active players")
    // }




    // Handle calls UI
    if (!this.ui || this.far || !this.stream) return;

    const volume = parseFloat(Math.max((1 - (distance / 400)),0).toFixed(2));
    this.ui.children[2].volume = volume;
    this.ui.children[0].children[1].style.opacity = volume;

    //this.ui.children[0].children[0].style.transform = `scale(${(1 + distance/200).toFixed(2)})`;

    //this.near ? this.ui.classList.add("near") : this.ui.classList.remove("near");

  }

  suscribeToStore() {

    store.subscribe(() => {
      const call = store.getState().calls[this.socketId];



      if (call && call.stream && !this.stream) {
        this.stream = call.stream;

        const videoElem = document.createElement("video");

        videoElem.classList.add("hidden");

        this.ui.insertAdjacentElement("beforeend", videoElem);

        videoElem.srcObject = call.stream;
        videoElem.play();

        var   audioContext = new AudioContext();
        const volumeMeter  = new VolumeMeter(audioContext);

        volumeMeter.connectToSource(
          call.stream,
          () => {
            this.streamInterval = setInterval(() => {
              const width = Math.min(Math.round(volumeMeter.volume * 25), 0.5);
              this.ui.children[0].children[0].style.transform = `scale(${1 + width})`;
            }, 250);
          }
        );


        this.ui.classList.add("call");

      }
      else if (!call && this.stream) {
        this.stream = null;
        this.ui.classList.remove("call");
        clearInterval(this.streamInterval);
        this.ui.children[2].remove();
      }
    })
  }

  minify() {
    this.active = false;
    this.remove();
    delete characters[this.socketId];
    return characters[this.socketId] = new MinifiedCharacter(this);
  }
}


/**
 * MinifiedCharacter is a class that contains all data necessary to
 * later reconstruct character.
 *
 * Its porpuse is to replace characters with a more light weight
 * version of them to solve for FPS and memory issues.
 *
 * We call the method .minify() on a Character to replace it
 */

class MinifiedCharacter {
  constructor (character) {

    // this.box = new THREE.Mesh(
    //   new THREE.BoxGeometry(1,1,1,1,1,1),
    //   new THREE.MeshBasicMaterial({color: "red"})
    // );
    //
    // scene.add(this.box);

    this.id        = character.socketId;
    this.peer      = character.peer;
    this.name      = character.name;
    this.character = character.modelName;
    this.position  = character.position.clone();

    //this.box.position.copy(character.position);

    this.rotation = {
      x: character.rotation.x,
      y: character.rotation.y,
      z: character.rotation.z,
    };

    this.animation = {
      name     :"idle",
      timeScale:1
    };

    return this;
  }

  update(allCharacters) {
    if (!allCharacters[this.id]) return;

    const newData = allCharacters[this.id];

    this.position.set(newData.position.x, newData.position.y, newData.position.z);
    //this.box.position.set(newData.position.x, newData.position.y, newData.position.z);
  }

  recalculateDistance() {

    const player = getPlayer();

    const distance = this.position.distanceToSquared(player.position);
    this.distance = distance;
    // Make some decision regarding distance to player
    // For example, if it is necessary to restore model


    // const maxDistance = (1000/Object.keys(characters).length + 10)**2;

    // if (distance < maxDistance) this.restore();

    // const farthest = player.farthestCharacter
    //   ? characters[player.farthestCharacter].position.distanceToSquared(player.position)
    //   : 0;

    // if (player.maximumActiveCharacters <= player.activeCharacters) {
    //   if (distance < farthest) {
    //     characters[player.farthestCharacter] != null && characters[player.farthestCharacter].minify();
    //     console.log("Minifying", player.farthestCharacter, "so I,", this.id, "can enter")
    //     player.farthestCharacter = this.id;
    //     this.restore();
    //   }
    // }
    // else {
    //   console.log("There is plenty room for ", this.id, "to enter")
    //   if (distance > farthest) {
    //     player.farthestCharacter = this.id;
    //   }

    //   player.activeCharacters++;
    //   this.restore();
    // }

    //if (distance < 400) this.restore();
  }

  restore() {
    //scene.remove(this.box);
    //return characters[this.socketId] = new Character(this);
    new Character(this);
  }

  disconnect() {
    //scene.remove(this.box);
  }
}




Character.getAll  = ()   => characters;
Character.getList = ()   => Object.values(characters);
Character.get     = (id) => characters[id];
Character.remove  = (id) => delete characters[id];


Character.getOrderedByDistance = () => (
  Object.values(characters)
    .sort((a,b) => a.distance - b.distance)
);
