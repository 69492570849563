console.log('./src/scene/colliders/special/stairs.js');
import * as THREE from "three";

export default class Stair extends THREE.Box3 {
  constructor({min, max, direction, step}) {
    super(min, { ...max, y: max.y + 1});

    this.type = "stairs";
    
    if (!direction) throw new Error("Stairs must have the direction in which it goes");
    if (direction !== "+x" && direction !== "+z" && direction !== "-x" && direction !== "-z") throw new Error("Stairs direction must be either '+x', '-x', '+z' or '-z'");

    this.direction = direction;
    this.step = step;

    const predominant = (direction == "+x" || direction == "-x") ? "x" : "z";

    this.length =  max[predominant] - min[predominant];
    this.height =  max.y - min.y -1;

    this.inclination = Math.atan2(this.height, this.length);



    return this;
  }

  through (object) {
    object.speed = object.speed - 0.02; //Math.sqrt((object.speed**2) + (this.height**2));
    
    const { yVal: targetYVal, depth: insideFactor } = this.getCorrespondingYVal(object.position);
    
    if (insideFactor < 0 || insideFactor > this.length || object.position.y + 1 < targetYVal) return true;

    object.position.y = targetYVal;



    return true;
  }

  getCorrespondingYVal(position) {
    let insideFactor;
    
    if (this.direction == "+x") insideFactor = position.x - this.min.x;
    if (this.direction == "-x") insideFactor = this.max.x - position.x;
    if (this.direction == "+z") insideFactor = position.z - this.min.z;
    if (this.direction == "-z") insideFactor = this.max.z - position.z;
        
    return { 
      yVal: Math.tan(this.inclination) * insideFactor + this.min.y,
      depth: insideFactor,
    };
  }
}