console.log('./ui/loading/index.js');
import React, {useState, useEffect} from "react";
import styled, {keyframes} from "styled-components";
import texts from "../text";

export default function ({ callbacks }) {
  const [ themeColor, setThemeColor] = useState(null);

  useEffect(() => {
    setThemeColor(callbacks.getThemeColor())
  }, [])

  return (
    <Wrapper themeColor={themeColor}>
      <LoadingContainer>
        <UpperDot />
        <LoadingLabel>
          {texts.ui.loading}
        </LoadingLabel>
      </LoadingContainer>
    </Wrapper>
  )
}



const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 10;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${p => p.themeColor || "#ffffff"};
`

const LoadingContainer = styled.div`
  width: 100px;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
`;

const upperDotAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(360deg);

  }
  100% {
    transform: rotateZ(720deg);
  
  }
`

const LoadingLabel = styled.label`
  align-self: center;
  color: white;
  font-weight: bolder;
    font-size: 22px;
    margin-top: 18px;
`

const UpperDot = styled.div`
  width: 75px;
  height: 75px;
  align-items: center;
  animation: ${upperDotAnimation} 2700ms cubic-bezier(0.56,-0.1, 0.45, 1.08) infinite;
  display: flex;
  justify-content: space-between;

  &:after {
    content: "";
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    display: block;
  }


  &:before {
    content: "";
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    display: block;

  }
`
