console.log('./ui/welcome/index.js');
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import styled      from 'styled-components';
import { useForm } from 'react-hook-form';
import ReCAPTCHA   from 'react-google-recaptcha';

//import Selector from './selector';

import { getModels } from '../../core/models';

import Title    from "./components/title";
import Form     from "./components/form";
import Selector from "./components/selector";

import { validateGuest, validateRegister } from "./validations";

export default ({ parent, Background, callbacks }) => {

  const [ themeColor, setThemeColor ] = useState(null);
  const [ formMode,   setFormMode ]   = useState(null);
  const [ userData,   setUserData ]   = useState(null);

  const modelsList = useMemo(() => Object.keys(getModels()), []);

  useEffect(
    () => {
      setThemeColor(callbacks.getThemeColor());

      const token = window.sessionStorage.getItem('auth-token');

      // if (token) {

      //   fetch("/api/users/authenticate", {
      //     method: "GET",
      //     headers: {
      //       "auth-token": token,
      //     }
      //   })
      //   .then(data => data.json())
      //   .then(user => {
      //     setUserData(user);
      //   })
      // }

      const userData = window.sessionStorage.getItem('userData');
      if (userData) {
        const data   = JSON.parse(userData);
        const errors = validateGuest(data);

        if (errors) return;

        callbacks.onSubmit(data);
      }
    },
    []
  );

  const submit = useCallback(
    (model) => {
      const data = {
        ...userData,
        model
      }
      console.log(data);

      if (formMode == "local") window.sessionStorage.setItem("userData", JSON.stringify(data));

      callbacks.onSubmit(data);

    },
    [formMode, userData]
  );


  useEffect(
    () => {
      if (  userData
        &&  userData.model
        &&  modelsList.includes(userData.model)
      ) submit(userData.model);
    },
    [userData]
  );

  return (
    <Wrapper themeColor={themeColor}>

      { userData && userData.model
          ? null
          : <Title setFormMode={setFormMode} /> }
      { formMode
          ? <Form mode={formMode} setUserData={setUserData}/>
          : null }
      { userData
          ? <Selector callbacks={callbacks} setUserModel={submit} mode={formMode}/>
          : null }
    </Wrapper>
  );
}


const Wrapper = styled.div`
  background-color: ${(p) => p.themeColor || '#ffffff'};
  height: 100%;
  width: 100%;
  position: absolute;
  zoom: 80%;
  overflow: hidden;
  overflow-y: scroll;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
