console.log('./src/scene/index.js');
import * as THREE       from "three";
import environment      from "./environment";

import * as player      from "../../core/player";
import getPlayer        from "../../core/player";

import * as socket      from "../../core/socket";
import getSocket        from "../../core/socket";

import * as stands      from "./stands";


//import * as proyector from "./proyector";

import UiComponent      from "../../ui";
import audio            from "../../ui/audio";
import chat             from "../../ui/chat";
import calls            from "../../ui/calls";

import * as peer        from "../../core/peer";
import { getPeer }      from "../../core/peer";
import { renderer }     from "../../core";

import * as controls    from "../../core/controls";

import parseColliders   from "./colliders";
import parseFloors      from "./colliders/floors";

import lights           from "./lights";

import store            from "../../ui/redux/store";
import { addListeners } from "../../ui/redux/actions/calls";

import * as control from '../voiceComponent/jitsiControl';


var data;


if(!window.JitsiMeetJS){
  console.log("cant load jitsi")
}
window.JitsiMeetJS.init()


export function init(form) {
  data = form;

  const credential = window.localStorage.getItem("credential");

  if (credential) data.credential = credential;

  return new Promise((res, rej) => {
    environment()
    .then(
      ({colliders, models, stands: standsMap, floors, ambient}) => {

        lights(ambient);

        player.init(data)
          .then((model) => {
            socket.connect(data, afterSocket);
            model.floors    = parseFloors(floors);
            model.colliders = parseColliders(colliders);
          })

        stands.init(standsMap);

        return models;
      }
    )
    .then(
      () => res()
    );
  });
}

function afterSocket (playerId) {
  console.log("afterSocket", playerId);
  peer.init(playerId, (peer) => {
    const socket = getSocket();
    socket.emit("peercreated", peer.id);
  });

  const player = getPlayer();

  // player.colliderCheck();
  console.log("player data", player)


  // let callsUI = new UiComponent(document.getElementById("app"), calls, {
  //   init: () => ({
  //     name: data.name,
  //     email: data.email,
  //     id: playerId,
  //   }),
  // });

  store.dispatch(addListeners());

  let chatUI = new UiComponent(
    document.getElementById("app"),
    chat,
    {
      init: () => ({
          name : data.name,
          email: data.email,
        }),
      getRenderer: ()=>renderer
    }
  );

  let audioUI = new UiComponent(
    document.getElementById("app"),
    audio,
    {
      done: () => {
          controls.init(renderer);
          audioUI.close()
        }
    }
  );

  control.jitsi
  .setRoomName("franktest") //figure out room name? it should match the room or a "private" conversation
  .setUserName(player.name) //how to set name? can we even do that ?
  .connect();

  return () => {
    //callsUI.close();
    chatUI.close();
  }
}
