console.log('./ui/index.js');
import React    from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider }        from "styled-components";

import { Provider } from "react-redux";
import store        from "./redux/store";
import themes       from "./theme";

export default function UiComponent (domElement, Ui, callbacks) {

  this.element = document.createElement("DIV");
  domElement.insertAdjacentElement("beforeend", this.element);

  // console.log({callbacks}, new Error() );

  const newCallbacks = callbacks && Object.keys(callbacks).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: callbacks[key].bind(this.element),
      }
    },
    {}
  );

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={themes.light}>
        <Ui
            parent    ={this.element}
            Background={Background}
            callbacks ={newCallbacks}
        />
      </ThemeProvider>
    </Provider>,
    this.element
  );

  this.element.close = function () {
    ReactDOM.unmountComponentAtNode(this)
    this.remove();
  }

  return this.element;
} // export default function UiComponent (domElement, Ui, callbacks) {


/*
  Background shows a semi-opaque black background to fill the screen
  it's sent to all UiCompoent children and can be used like <Background> ...components </Background>
*/
function Background({ children, zIndex }) {
    return (
        <BackgroundShadow key={zIndex} zIndex={zIndex}>
            { children }
        </BackgroundShadow>
    );
}

const BackgroundShadow = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #00000059;
    display: flex;
    justify-content: center;
    align-items: center;
    ${p => p.zIndex ? `z-index:${p.zIndex}` : ""}
`
