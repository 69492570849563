console.log('./ui/presentation/broadcasting.js');
import adapter from 'webrtc-adapter';
import getPeer from "../../core/peer";
import getSocket, { getPlayerId } from "../../core/socket";


let sharing = false;
let people = [];
let calls = {};

let broadcaster = null;

export default function ({ video, callbacks }) {

  console.log("BROADCASTING")

  const socket = getSocket();

  let peer = getPeer();

  function start(stream) {
    console.log("start stream")
    sharing = true;
    video.srcObject = stream;

    callbacks.play(video, true);

    socket.emit("screenshare");

    stream.getVideoTracks()[0].onended = stop;
  }

  function stop(cb) {
    callbacks.stop();
    socket.emit("endscreenshare");

    people = [];
    calls = {};
    sharing = false;
    cb && cb();

    if (!video.srcObject) return;

    let tracks = video.srcObject.getTracks();
    tracks.forEach(track => track.stop());
    video.srcObject = null;
  }

  /****************************************************/

  // Socket Listeners

  socket.on("watchers", (newPeople, ownId) => {


    if (!video.srcObject || !ownId || !peer) return;

    people.forEach(person => {
      if (newPeople.includes(person)) return;
      // Close call
      if (calls[person]) {
        calls[person].close();
        delete calls[person];
      }
    })

    newPeople.forEach(person => {
      if (people.includes(person)) return;
      // Start call
      calls[person] = peer.call(
        person,
        video.srcObject,
        {
          metadata: {
            type: "screenshare",
            id: getPlayerId(),
          }
        }
      );

      calls[person].on("error", (err) => {
        console.error("\n\nCall error", err, "\n\n");
      });
    });

    console.table(
      [...people, ...newPeople].reduce((acc, person) => {
        let status;

        if (!people.includes(person)) status = "Joined!";
        else if (!newPeople.includes(person)) status = "Left.";
        else status = "Watching";

        return {
          ...acc,
          [person]: status,
        }
      }, {})
    )

    people = newPeople;
  })

  peer.on("call", (call) => {
    if (call.metadata.type != "screenshare") return;

    const callId = call.metadata.id;


    call.answer(null);

    call.on("stream", (stream) => {

      video.srcObject = stream;

      callbacks.play(video);
    });
  });

  socket.on("endscreenshare", () => {
    if (video.srcObject) {
      let tracks = video.srcObject.getTracks();
      tracks.forEach(track => track.stop());
    }
    video.srcObject = null;
    callbacks.stop();
  });


  return { start, stop };
}
