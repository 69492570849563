import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import { notifyEvent }              from "./events";
import { inputdevice_events }       from "../utils/constants";

var input_index     = 0;
var input_state     = new Array(20).fill({});
var config_hook     = null;
var hook_filter     = null;
var hook_block      = false;

var keyboard_enabled  = false;
var gamepad_enabled   = false;
var xrcontrol_enabled = false;

var input_actions=[
  "updown",
  "leftright",
  "action",
  "cancel"
]

let keyboard_input   = [];
let keyboard_mapping = {
  updown   : { positive: [38], negative: [40] },
  leftright: { positive: [37], negative: [39] },
  action   : { positive: [13]},
  cancel   : { positive: [27]},
}

let current_gamepad = 0;
let gamepads        = [];
let gamepad_mapping = {
  updown   : { positive: [1], negative: [1] },
  leftright: { positive: [0], negative: [0] },
  action   : { positive: [2]},
  cancel   : { positive: [1]},
}

let controllerL,     controllerR;
let controllerGripL, controllerGripR;
let controls,        group;
let xrcontrol_mapping = { // ???
  updown   : { positive: [0], negative: [0] },
  leftright: { positive: [0], negative: [0] },
  action   : { positive: [0]},
  cancel   : { positive: [0]},
}


function get_actions(){
  if(input_actions && Array.isArray(input_actions)) return input_actions; //recorded actions
  return []; // no actions
}

function poll_gamepads() {
  gamepads = navigator.getGamepads
		? navigator.getGamepads()
		: (navigator.webkitGetGamepads
				? navigator.webkitGetGamepads
				: []
			);

  // console.log({gamepads});

  if(gamepads && gamepads[current_gamepad]) return readGamepad();

  if(
        (get_XRController().controllerL && get_XRController().controllerL.gamepad)
    ||  (get_XRController().controllerR && get_XRController().controllerR.gamepad)
  ) return readVRGamepad();
}

let gp         = null;

function readVRGamepad(){
  if(get_XRController().controllerL.gamepad) gp = get_XRController().controllerL.gamepad;
  if(get_XRController().controllerR.gamepad) gp = get_XRController().controllerR.gamepad;
  // console.log("gp", gp)

  if(gp) update_input_state(xrcontrol_mapping, gp);
  // gp = get_XRController().controllerR.gamepad;
}

function readGamepad(){
  if(gamepads[current_gamepad]) update_input_state(gamepad_mapping, gamepads[current_gamepad]);
}

let last_gamepad_state = { ...input_state[input_index], index: input_index };
function update_input_state(gp_mapping, gp) {
  let   input_frame = {...last_gamepad_state, index: input_index};
  // const last_input  = input_state[input_index];
  let   change      = 0;

  if(
        config_hook
    &&  (hook_filter === null || hook_filter === "gamepad" || hook_filter === "xrcontroler" )
  ){
    // console.log("hook", gp);
    return config_hook(gp);
  }


  for (var action of input_actions) {
    if(!gp_mapping[action]) {
      // console.warn(`action: '${action}' was not found in mapping object, this is a noop`);
      continue;
    }

    const neg = gp_mapping[action].negative; //button/axis index for negative value
    const pos = gp_mapping[action].positive; //button/axis index for positive value

    const poslen = pos.length; // negative can not exist; positive must always exist

    let invert = 1;
    for (var i = 0; i < poslen; i++) {
      invert = (Object.is(pos[i], -0) // check for exact value -0
          ? -1                        // cast (-0) -> -1
          : Math.sign(pos[i]||1)      // 1 | -1, casts 0->1
      );
      if( neg && Number.isInteger(neg[i]) ){ //axis has both negative and positive
        input_frame[action] = invert * Math.round(gp.axes   [Math.abs(neg[i])] * 2 ) / 2;
      } else {      //button has only positive on/off
        input_frame[action] = invert *            gp.buttons[Math.abs(pos[i])].value
      }
    }
    // if(gp_mapping[action].invert) input_frame[action] *= -1; //invert sign

    change += (
      Math.abs(
					(last_gamepad_state[action] || 0)
				- input_frame[action]
			)
    );
  }

  if(change>0) {
    // console.log(input_frame, gp)
    push_input_state(input_frame);
    last_gamepad_state = input_frame;
  }

}

let keyboard_keys = [];

function set_keyboard_state(event){
  let input_frame = {...input_state[input_index], index: input_index}

  const len          = input_actions.length;
  let   action       = ""
  let   action_count = 0;

  for (var i = 0; i < len; i++) {
    // console.log(i, input_actions[i])
    action = input_actions[i];

    if(!keyboard_mapping[action]) {
      console.warn(`keyboard action: '${action}' was not found in mapping object, this is a noop`);
      return;
    }

    const keyact = keyboard_mapping[action];
    const poslen = keyact.positive.length
    let   value  = 0;

    for (var n = 0; n < poslen; n++) {
      // console.log(keyact.positive[n], keyboard_keys[keyact.positive[n]])
      if(keyact.positive && keyboard_keys[keyact.positive[n]]) value+=1;
      if(keyact.negative && keyboard_keys[keyact.negative[n]]) value-=1;
    } // for (var i = 0; i < poslen; i++)
    // input_frame[action] = Math.sign(input_frame[action]);
    action_count+=Math.abs(value);
    input_frame[action] = value;
  } // for (var i = 0; i < len; i++)

  push_input_state(input_frame);

  if(action_count) {
    event.preventDefault();
    event.stopPropagation();
    return true;
  }

  return false;
  // console.log(input_frame)
}


function push_input_state(input_frame){
  input_state[
    input_index=(
      (input_index||20)-1
    )
  ] = input_frame;
  notifyEvent(inputdevice_events.changed, input_frame);
  console.log("input changed", input_frame)
}



function gamepad_handler(event, connecting) {
  console.log("gamepad_handler", event, connecting);

  var gamepadEvt = event.gamepad;

  if (connecting) {
    let gptest     = gamepadEvt;
    let setcurrent = false;
    let axislen    = gptest.axes.length
    let btnlen     = gptest.buttons.length

    for (var i = 0; i < axislen; i++) if( Boolean(gptest.axes[i]))             {setcurrent=true; break;}
    for (var i = 0; i < btnlen;  i++) if( Boolean(gptest.buttons[i].pressed) ) {setcurrent=true; break;}

    if(setcurrent) current_gamepad = gamepadEvt.index;

    gamepads[gamepadEvt.index] = gamepadEvt;
    notifyEvent(inputdevice_events.connected, gamepadEvt);

  } else {
    delete gamepads[gamepadEvt.index];
    notifyEvent(inputdevice_events.disconnected, gamepadEvt);
  }
}


function onDocumentKeyUp(event) {
  if(
        config_hook
    &&  (hook_filter === null || hook_filter === "keyboard")
  ){
    event.preventDefault();
    return;
  }

  keyboard_keys[event.keyCode] = false;
  set_keyboard_state(event);

  return true;
}

function onDocumentKeyDown(event) {
  if(event.repeat){
    //we know this same-key presed was handled, therefore we can prevent it
    event.preventDefault(); // prevent tab change focus when left down
    event.stopPropagation();
    return true;
  }

  if(
        config_hook
    &&  (hook_filter === null || hook_filter === "keyboard")
  ){
    // console.log("hook", event);
    event.preventDefault();
    return config_hook(event);
  }


  keyboard_keys[event.keyCode] = true;
  set_keyboard_state(event);

  return true;
};

function onSelectEnd  (...e){ console.log(e)}
function onSelectStart(...e){ console.log(e)}


let vr_controllers = {
    controllerL    : controllerL,
    controllerR    : controllerR,
    controllerGripL: controllerGripL,
    controllerGripR: controllerGripR
}

function get_XRController(){
  vr_controllers = {
      controllerL    : controllerL,
      controllerR    : controllerR,
      controllerGripL: controllerGripL,
      controllerGripR: controllerGripR
  }
  return vr_controllers;
}



function input_init_xrcontrol(renderer){
  const controllerModelFactory = new XRControllerModelFactory();

  xrcontrol_enabled = true;
  controllerL       = renderer.xr.getController(0);
  controllerR       = renderer.xr.getController(1);
  // console.log(
  //   controllerL,
  //   controllerR
  // )

  controllerL.addEventListener(
    'connected',
    (e) => {
      console.log(e)
      if(e.data.handedness === "right"){
        controllerR.gamepad = e.data.gamepad;
        console.log(controllerR.gamepad.axes);
      } else {
        controllerL.gamepad = e.data.gamepad;
        console.log(controllerL.gamepad.axes);
      }
    }
  );

  controllerR.addEventListener(
    'connected',
    (e) => {
      controllerR.gamepad = e.data.gamepad;
      console.log(controllerR.gamepad)
      console.log(controllerR.gamepad.axes)
    }
  );

  controllerGripL = renderer.xr.getControllerGrip( 0 );
  controllerGripL.add( controllerModelFactory.createControllerModel( controllerGripL ) );

  controllerGripR = renderer.xr.getControllerGrip( 1 );
  controllerGripR.add( controllerModelFactory.createControllerModel( controllerGripR ) );

  controllerL.addEventListener( 'selectstart',         onSelectStart );
  controllerL.addEventListener( 'selectend',           onSelectEnd );
  controllerR.addEventListener( 'selectstart',         onSelectStart );
  controllerR.addEventListener( 'selectend',           onSelectEnd );
}
function input_init_keyboard(){
  keyboard_enabled = true;
  document   .addEventListener( "keydown",             onDocumentKeyDown,                          true);
  document   .addEventListener( "keyup",               onDocumentKeyUp,                            true);
}
function input_init_gamepad(){
  gamepad_enabled = true;
  window     .addEventListener( "gamepadconnected",    function(e) { gamepad_handler(e, true); },  false);
  window     .addEventListener( "gamepaddisconnected", function(e) { gamepad_handler(e, false); }, false);
}


/*
options = {
  gamepad  : bool,
  keyboard : bool,
  xrcontrol: bool,
  actions: [ String, ... ],
  defaults: {
    keyboard : { action_name: { positive: Number, positive: Number }, ... }
    gamepad  : { action_name: { positive: Number, positive: Number }, ... }
    xrcontrol: { action_name: { positive: Number, positive: Number }, ... }
  }
}
if !options default input is keyboard
*/
function init(renderer, options){
  let _opt = {...options}; // avoid undefined||null||""
  if(!_opt || Object.keys(_opt).length) _opt.keyboard = true;

  // if options has actions key, we shallow-copy the array to prevent outside mutation
  if(Boolean(_opt.actions) && Array.isArray(_opt.actions)) input_actions = [..._opt.actions];
  let starting = {index: 0}
  input_actions.forEach( x=>(starting[x] = 0) )
  input_state[0] = starting;

  if(Boolean(_opt.defaults)){
    let defaults = _opt.defaults;
    keyboard_mapping  = {...defaults.keyboard};
    gamepad_mapping   = {...defaults.gamepad};
    xrcontrol_mapping = {...defaults.xrcontrol};
    console.log({
      keyboard_mapping,
      gamepad_mapping,
      xrcontrol_mapping
    });
  }

  if(Boolean(_opt.keyboard)  === true) input_init_keyboard ();
  if(Boolean(_opt.gamepad)   === true) input_init_gamepad  ();
  if(Boolean(_opt.xrcontrol) === true) {
    console.log({renderer})
    if(!renderer)    throw Error("No puede activarse control XR sin renderer");
    if(!renderer.xr) throw Error("Modo XR no esta activado en renderer");
    input_init_xrcontrol(renderer);
  }
}


function get_state(index){
  // console.log(input_state[input_index])
  if(index===-1) return (
    input_state
    .slice(input_index)
    .concat(
      input_state.slice(0, input_index)
    )
  );

  if(index >= 0) return input_state[index]

  return input_state[input_index];
}

function get_mapping(){
  return (
    {
      gamepad  : gamepad_enabled,
      keyboard : keyboard_enabled,
      xrcontrol: xrcontrol_enabled,
      actions  : input_actions,
      defaults: {
        keyboard : keyboard_mapping,
        gamepad  : gamepad_mapping,
        xrcontrol: xrcontrol_mapping
      }
    }
  );
}

function set_hook(filter, block, fnHook){
  if(config_hook) throw Error("No se pueden tener dos hooks de input a la vez, desactive el anterior");
  if(!filter)     console.warn("Filter==null, enviando todos los inputs");

  config_hook = fnHook;
  hook_filter = filter;
  hook_block  = Boolean(block);
}

function clear_hook(){
  config_hook = null;
}

export { init, poll_gamepads, get_state, get_XRController, get_mapping, set_hook, clear_hook }
