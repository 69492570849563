console.log('./src/scene/colliders/special/portal.js');
import * as THREE from "three";

import { scene, camera } from "../../../../core";
import { addToTick } from "../../../../core/tick";

import { addSpecialCollider } from "../index.js";

class Portal extends THREE.Box3 {
  constructor(data) {

    if (data.type == "item") return new Item(data);

    const boxSize = 8;
    
    super(
      new THREE.Vector3(data.x - boxSize, data.y - boxSize, data.z - boxSize),
      new THREE.Vector3(data.x + boxSize, data.y + boxSize, data.z + boxSize),
    );

    this.type = "decoration";
    
    this.position = new THREE.Vector3(data.x, data.y, data.z);

    this.tick = this.tick.bind(this);
    this.left = this.left.bind(this);

    addSpecialCollider(this);

    this.elem = document.createElement("div");

    this.elem.innerHTML = `
      <h1>Visitar showroom</h1>
    `;

    this.elem.addEventListener("click", () => window.location = data.to)

    this.elem.classList.add("uiOVerlayText");

    this.elem.style.display = "none";

    console.log(this.elem)

    document.getElementById("app").insertAdjacentElement("beforeend", this.elem);

    return this;
  }

  through () {
    return true;
  }

  tick (player, { frustum }) {

    const iconVector = this.position.clone();

    iconVector.y += 3;

    if(!this.focused && frustum && frustum.containsPoint(iconVector)) {
      
      iconVector.project(camera);
      const x = (iconVector.x *  .5 + .5) * window.innerWidth;
      const y = (iconVector.y * -.5 + .5) * window.innerHeight;

      this.elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
      this.elem.style.display = "";
    } else {
      this.elem.style.display = "none";

    }
  }


  left () {
    this.elem.style.display = "none";
  }
}

export default Portal;