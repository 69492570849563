console.log('./ui/presentation/screenshare.js');
export default function () {

  const gdmOptions = {
    video: {
      cursor: "always",
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100
    },
  }

  return navigator.mediaDevices
    .getDisplayMedia(gdmOptions)
}
