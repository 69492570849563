console.log('./ui/presentation/index.js');
import React,{ useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";

import broadcasting from "./broadcasting";

import screenshare from "./screenshare";
import camerashare from "./camerashare";

import microphone from "./microphone";

import { AcceptPlayer } from "../calls/styles";

export default function ({ callbacks }) {
  const [ isFocused, setIsFocused ] = useState(false);
  const [ className, setClassName ] = useState("");
  const [ sharing, setSharing] = useState(false);
  const [ camera, setCamera] = useState(false);
  const [ isPresenting, setIsPresenting] = useState(false);
  const [ presenting, setPresenting] = useState(false);
  const [ handraised, setHandraised ] = useState(false);
  const [ laser, setLaser ] = useState(false);

  const videoRef = useRef(null);

  const playerRef = useRef(null);

  const socketRef = useRef(null);

  

  const [ speaking, setSpeaking ] = useState(false);
  const [ broadcast, setBroadcast ] = useState(null);

  const [ speak, setSpeak ] = useState(null);

  useEffect(() => {
    const { socket, player, className } = callbacks.init();
    socketRef.current = socket;
    playerRef.current = player;
    setClassName(className);

    const broadcast = broadcasting({
      video: videoRef.current,
      callbacks,
    });

    const speak = microphone();

    setBroadcast(broadcast);
    setSpeak(speak);

    addListeners();
  }, [])

  useEffect(() => {
    if (!presenting) setIsFocused(false);
    //else setIsFocused(false);
  }, [presenting])

  useEffect(() => {
    if (!isPresenting && presenting) {
      if (isFocused) callbacks.focus();
      else callbacks.unfocus();
    }
  }, [isFocused])

  function startPresentation() {
    socketRef.current.emit("presentation");
    setPresenting(true);
    setIsPresenting(true);
  }
  
  function stopPresentation() {
    socketRef.current.emit("endpresentation");
    setPresenting(false);
    setIsPresenting(false);
    if (speaking) stopSpeaking();
    if (sharing) stopCapture();

  }

  function startSpeaking() {
    speak.start()
    setSpeaking(true);
  }
  
  function stopSpeaking() {
    speak.stop();
    setSpeaking(false);
  }

  function startCapture() {
    setSharing(true);
    screenshare()
      .then(stream => broadcast.start(stream))
      .catch(() => {
        setSharing(false);
      })
  }

  function startCamera() {
    setCamera(true);
    camerashare()
      .then(stream => broadcast.start(stream))
      .catch((err) => {
        console.error(err)
        setCamera(false);
      })
  }

  function stopCapture() {
    broadcast.stop(() => {
      setSharing(false);
    });
  }

  function stopCamera() {
    broadcast.stop(() => {
      setCamera(false);
    });
  }

  function addListeners() {

    socketRef.current.on("presentation", () => {
      setPresenting(true);
    });

    socketRef.current.on("endpresentation", () => {

      setPresenting(false);
      callbacks.unfocus();
    });

    socketRef.current.on("raisedhanddismiss", () => {
      setHandraised(false);
    });

  }

  useEffect(() => {
    if (handraised) socketRef.current.emit("raisehand");
    else {
      socketRef.current.emit("raisedhanddismiss");
      speak && speak.stop && speak.stop();
    }
  }, [ handraised ]);

  useEffect(() => {
    if (laser) callbacks.startLaserBeam();
    else callbacks.stopLaserBeam();
  }, [ laser ]);

  return <>
    <Wrapper className={className || "none"}>
      {
        presenting
          ? <Icon onClick={stopPresentation} src="public/icons/close.png" />
          : <Icon onClick={startPresentation} src="public/icons/present.png" />
      }

      {
        presenting && sharing
          ? <ActiveIcon onClick={stopCapture} src="public/icons/share.png" />
          : null
      }
      {
        presenting && !sharing && !camera
          ? <Icon onClick={startCapture} src="public/icons/share.png" />
          : null
      }

      {
        presenting && camera
          ? <ActiveIcon onClick={stopCamera} src="public/icons/video.png" />
          : null
      }
      {
        presenting && !sharing && !camera
          ? <Icon onClick={startCamera} src="public/icons/video.png" />
          : null
      }

      {
        presenting && !laser && (sharing || camera) 
          ? <Icon onClick={() => setLaser(true)} src="public/icons/laser.png" />
          : null
          
      }
      {
        presenting && laser
          ? <ActiveIcon onClick={() => setLaser(false)} src="public/icons/laser.png" />
          : null
          
      }

      {
        presenting && speaking
          ? <ActiveIcon onClick={stopSpeaking} src="public/icons/mic.png" />
          : null
      }
      {
        presenting && !speaking 
          ? <Icon onClick={startSpeaking} src="public/icons/mic.png" />
          : null
          
      }
    </Wrapper>
    <VideoPlayer muted height={300} ref={videoRef}>
      Your browser does not support the video tag.
    </VideoPlayer>
    <Centered>
      {
        isFocused && presenting && !isPresenting
          ? (
            <Gestures>
              {
                handraised 
                  ? <ActiveIcon onClick={() => setHandraised(false)} src="public/icons/hand.png" />
                  : <Icon onClick={() => setHandraised(true)} src="public/icons/hand.png" />
              }
              <Icon onClick={() => setIsFocused(false)} src="public/icons/close.png" />
            </Gestures>
          )
          : null
      }
      {
        !isFocused && presenting && !isPresenting
          ? <AcceptPlayer onClick={() => setIsFocused(true)}>Haz click aquí para ver la presentación</AcceptPlayer>
          : null
      }
      {
        presenting && isPresenting
          ? <label onClick={() => stopPresentation()}>Dejar de presentar</label>
          : null
      }
    </Centered>
  </>
}


const Icon = styled.div`
  width: 28px;
  height: 28px;

  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;

  background-image: url(${p=>p.src});
  background-size: cover;

  &:before {
    content: " ";
    background: #00000044;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    padding: 12px;

    position: absolute;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

const ActiveIcon = styled(Icon)`
  filter: invert(1);

  &:hover {
    filter: brightness(0.8) invert(1);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
`

const VideoPlayer = styled.video`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
  display: none;
`
const Centered = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  bottom: 145px;
`;


const Gestures = styled.div`
  display: flex;
`;
