import config from './config';
import {message_types, conference_events, jitsi_events } from "../../utils/constants.js";
import { notifyEvent }  from "../../core/events";

// import _                from 'lodash'
import { v4 as uuidv4 } from 'uuid'

let connection = null;

function debug_log(){
  return; //if DEBUG delet this
  var args = Array.from(arguments).join(' ');
  console.log("jitsiControl", args);
}


let state = {
  serverURL              : 'jitsi.covrel.com',
  // serverURL              : 'meet.covrel.com',
  roomId                 : "franktest",
  media                  : {available:{}, selected: {}, loaded: false},

  status                 : 'uninitialized',
  lastError              : '',
  remoteTrackIds         : [],
  loaded                 : false,
  activeRoom             : null,
  trackList              : [],
  rooms                  : [],

  toString               : () => Object.entries(state).map(
                              (x,y)=>{
                                if(!x[1])               return `${x[0]}, Empty\n`;
                                if(x[0] === "toString") return ""; //dont show this ugly uwu
                                if(x[0] === "media")    return `media:\n${JSON.stringify(x[1], null, 2)}\n`;
                                if(x[0] === "rooms")    return (
                                  `rooms:\n${
                                    x[1] == null
                                        ? "Empty"
                                        : Object.entries(x[1]).map(
                                            (m, n)=>`${m[0]}: ${m[1].toString()}\n`
                                          )
                                        // : x[1].map(y=>Object.keys(y)).join(", ")
                                  }\n`
                                );
                                if(typeof x[1] === "object") return (
                                  `obj: ${x[0]}, `+(
                                    Object.values(x[1]).map(x=>x && Object.keys(x) )
                                  )+"\n"
                                );

                                return x.toString()+"\n"
                              }
                            )
};


/*
TRACE
DEBUG
INFO
LOG
WARN
ERROR
*/
window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.WARN);

function Room (connection, roomId, objUser) {
    debug_log("Room constructor", connection, roomId, objUser);

    this.conference = {status: "uninitialized"}; /* status info */
    this.roomId     = roomId;           /* room name */
    this.room       = null;             /* jitsiConference internal */


    this.changeStatus = (newStatus) => {
      this.conference.status = newStatus;
      notifyEvent(conference_events.status_changed, newStatus);
    }
    /* own jitsi user data, jitsi does not have own user JitsiParticipant */
    this.user       = {
      ...objUser,
      getDisplayName     : ()=>(this.user.name||"NO NAME"),
      getId              : ()=>(this.user.id||"NO ID"),
      getConnectionStatus: ()=>"active", //this should reflect actual internet status
    };

    /* conference participant audio events */
    this.onAudioInputStateChange      = (...e) => {debug_log("onAudioInputStateChange",      e); };
    this.onAuthStatusChanged          = (...e) => {debug_log("onAuthStatusChanged",          e); };
    this.onBeforeStatisticsDisposed   = (...e) => {debug_log("onBeforeStatisticsDisposed",   e); };

    /* conference specific general events */
    this.onConferenceError            = (...e) => {debug_log("onConferenceError",            e); console.trace() };
    this.onConferenceFailed           = (...e) => {debug_log("onConferenceFailed",           e); };
    this.onConferenceUniqueIdSet      = (...e) => {debug_log("onConferenceUniqueIdSet",      e); };

    this.onConferenceLeft             = (...e) => {
      debug_log("onConferenceLeft", e);
      this.changeStatus({status: "left"});
      notifyEvent(conference_events.user_left, e)
    };
    this.onConferenceJoined           = (...e) => {
      debug_log("onConferenceJoined", e);

      this.room.setDisplayName( (this.user && this.user.name) || "Unnamed" );

      this.getParticipants();

      //user microphone goes to conference
      if(Array.isArray(state.trackList) && state.trackList.length) {
        state.trackList.forEach(
          (track, i) => {
            debug_log(
              "Trying to add",
              track&&(track.deviceId+","+(track.getType?"has getType":"DOES NOT have getType")),
              this.room,
              track
            );
            this.room.addTrack(track)
          }
        );
      } else {
        debug_log("NO LOCAL TRACKS!")
      }

      notifyEvent(conference_events.user_left, e)

    };

    /* general participant data events */
    this.onConnectionStats            = (...e) => {debug_log("onConnectionStats",            e); };
    this.onDisplayNameChanged         = (...e) => {debug_log("onDisplayNameChanged",         e); this.getParticipants() };
    this.onDominantSpeakerChanged     = (...e) => {debug_log("onDominantSpeakerChanged",     e); };
    this.onDtmfSupportChanged         = (...e) => {debug_log("onDtmfSupportChanged",         e); };
    this.onEndpointMessageReceived    = (...e) => {/*debug_log("onEndpointMessageReceived",    e);*/};
    this.onKicked                     = (...e) => {debug_log("onKicked",                     e); };
    this.onLastNEndpointsChanged      = (...e) => {debug_log("onLastNEndpointsChanged",      e); };
    this.onNoAudioInput               = (...e) => {debug_log("onNoAudioInput",               e); };
    this.onNoisyMic                   = (...e) => {debug_log("onNoisyMic",                   e); };
    this.onParticipantKicked          = (...e) => {debug_log("onParticipantKicked",          e); };
    this.onParticipantPropertyChanged = (...e) => {debug_log("onParticipantPropertyChanged", e); };


    this.html = {}; /* HTML audio tracks */

    /* manages HTML object and internal data */
    this.onRoomTrackAdded             = (e) => {
      debug_log("onRoomTrackAdded", e);
      if(e.isLocal())        return debug_log("Local track, doing nothing");
      if(e.type === "video") return debug_log("Video track, not supported");

      // debug_log("appending", sound, e)

      var sound      = document.createElement('audio');
      sound.id       = 'audio-player';
      sound.controls = 'controls';
      sound.autoplay = true;
      e.attach(sound)

      let text = document.createElement("div");
      text.innerHTML = `
      UserId: ${e.ownerEndpointId}<br/>
      Name: ${this.room.getParticipantById(e.ownerEndpointId).getDisplayName()}
      Type: ${e.type}<br/>
      `;
      text.appendChild(sound);

      this.html[e.ownerEndpointId] = text;
      document.getElementById('media').appendChild(text);

      // debug_log("added track", this.html);
    };

    /* clean HTML audio */
    this.onRoomTrackRemoved           = (e) => {
      // debug_log("onRoomTrackRemoved", e);
      // if(Array.isArray(e.containers)) debug_log("OK containers are array");

      e.containers.forEach((track, i) => {
        e.detach(track)
        if(track.remove) {
          // debug_log("removing element");
          track.parentNode.remove();
        }
      });

      e.dispose()
      debug_log(this.html);

    };

    /* conference management events */
    this.onStartMutedPolicyChanged    = (...e) => {debug_log("onStartMutedPolicyChanged",    e); };
    this.onStartedMuted               = (...e) => {debug_log("onStartedMuted",               e); };
    this.onSubjectChanged             = (...e) => {debug_log("onSubjectChanged",             e); };
    this.onTalkWhileMuted             = (...e) => {debug_log("onTalkWhileMuted",             e); };
    this.onTrackAdded                 = (...e) => {debug_log("onTrackAdded",                 e); };
    this.onTrackAudioLevelChanged     = (...e) => {/* debug_log("onTrackAudioLevelChanged",     e);*/ };
    this.onTrackMuteChanged           = (...e) => {debug_log("onTrackMuteChanged",           e); };
    this.onTrackRemoved               = (...e) => {debug_log("onTrackRemoved",               e); };
    this.onUserRoleChanged            = (...e) => {debug_log("onUserRoleChanged",            e); };
    this.onUserStatusChanged          = (...e) => {debug_log("onUserStatusChanged",          e); };

    this.participants = {}; /* structure for holding jitsi participants with key by id */

    this.onUserJoined                 = (id, userData) => {
      debug_log("onUserJoined", id, userData);
      this.getParticipants();
    };
    this.onUserLeft                   = (id, jitsiUser) => {
      debug_log("onUserLeft", id, jitsiUser, this.participants);
      this.participants[id]._connectionStatus = "disconnected";
      this.getParticipants();
    };

    this.messages     = []; /* structure for jitsi -> hall message list */

    /* updates conference messages */
    this.createMessage = (who, message, type) => {
      debug_log("createMessage", who, message, this);
      let author = who
        ? { //TODO: transform control internal to user defined
            name: who.getDisplayName(),
            id  : who.getId()
          }
        : { name: "noname", id: "noid" }

      let result = {
        date   : new Date,
        type   : type||message_types.public,
        author : author,
        message: "(control)"+message
      };
      this.messages.push(result);
      return jitsi.messageNotification ? jitsi.messageNotification(result, this.messages) : undefined;
    }

    /* event for receiving a public message in the conference */
    this.onPrivateMessageReceived = (msgId, text) =>{
      this.onMessageReceived(msgId, text, message_types.private)
    }

    this.onMessageReceived = (msgId, text, type) => {
      debug_log("onMessageReceived", msgId, text, this.participants);

      if(!this.participants[msgId]) this.getParticipants();

      this.createMessage(
        this.participants[msgId],
        text,
        type
      );

    };

    /* creates and sends a message to jitsi conference (TODO: make something for private messages) */
    this.sendMessage = (text, privateId) => {
      debug_log("sendMessage", text, privateId, Boolean(privateId))
      if(privateId) {
        this.room.sendTextMessage(
        // this.room.sendPrivateTextMessage(
          text,
          privateId,
          true
        );
      } else {
        this.room.sendTextMessage(
          text
        );
      }

      this.room.room.connection.flush();
    }; // this.sendMessage

    this.getParticipants = () => {
      this.participants = [];
      this.user.id = this.room.myUserId();
      this.participants[this.user.id] = this.user;

      this.room.getParticipants().forEach(
        (jitsiParticipant, i) => this.participants[jitsiParticipant.getId()] = jitsiParticipant
      );

      notifyEvent(conference_events.participants, this.participants);
    }; // this.getParticipants

    try {
      debug_log("new Room() create");

      this.room = connection.initJitsiConference(roomId, {
        openBridgeChannel: true
      });

      this.getParticipants();

      debug_log("participants", this.participants);
      debug_log("activeRoom",   this.room);


      this.room.addEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_ERROR            , this.onConferenceError);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_FAILED           , this.onConferenceFailed);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED           , this.onConferenceJoined);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT             , this.onConferenceLeft);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_UNIQUE_ID_SET    , this.onConferenceUniqueIdSet);


      this.room.addEventListener(window.JitsiMeetJS.events.conference.MESSAGE_RECEIVED            , this.onMessageReceived);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED    , this.onPrivateMessageReceived);

      this.room.addEventListener(window.JitsiMeetJS.events.conference.TRACK_ADDED                 , this.onRoomTrackAdded)
      this.room.addEventListener(window.JitsiMeetJS.events.conference.TRACK_ADDED                 , this.onTrackAdded);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED   , this.onTrackAudioLevelChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED          , this.onTrackMuteChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.TRACK_REMOVED               , this.onRoomTrackRemoved)
      this.room.addEventListener(window.JitsiMeetJS.events.conference.TRACK_REMOVED               , this.onTrackRemoved);

      this.room.addEventListener(window.JitsiMeetJS.events.conference.USER_JOINED                 , this.onUserJoined);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.USER_LEFT                   , this.onUserLeft);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.USER_ROLE_CHANGED           , this.onUserRoleChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.USER_STATUS_CHANGED         , this.onUserStatusChanged);

      this.room.addEventListener(window.JitsiMeetJS.events.conference.AUTH_STATUS_CHANGED         , this.onAuthStatusChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.AUDIO_INPUT_STATE_CHANGE    , this.onAudioInputStateChange);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.BEFORE_STATISTICS_DISPOSED  , this.onBeforeStatisticsDisposed);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.CONNECTION_STATS            , this.onConnectionStats);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED        , this.onDisplayNameChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED    , this.onDominantSpeakerChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.DTMF_SUPPORT_CHANGED        , this.onDtmfSupportChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED   , this.onEndpointMessageReceived);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.KICKED                      , this.onKicked);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.LAST_N_ENDPOINTS_CHANGED    , this.onLastNEndpointsChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.NOISY_MIC                   , this.onNoisyMic);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.NO_AUDIO_INPUT              , this.onNoAudioInput);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.PARTICIPANT_KICKED          , this.onParticipantKicked);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, this.onParticipantPropertyChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.STARTED_MUTED               , this.onStartedMuted);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED  , this.onStartMutedPolicyChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.SUBJECT_CHANGED             , this.onSubjectChanged);
      this.room.addEventListener(window.JitsiMeetJS.events.conference.TALK_WHILE_MUTED            , this.onTalkWhileMuted);

      this.conference.started = true;

      // this.activeRoom.on(
      //     window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      //     onConferenceJoined);
      //     this.activeRoom.on(JitsiMeetJS.events.conference.USER_JOINED, id => {
      //     debug_log('user join');
      //     remoteTracks[id] = [];
      // });
      // this.activeRoom.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
      // this.activeRoom.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
      //     debug_log(`${track.getType()} - ${track.isMuted()}`);
      // });
      // this.activeRoom.on(
      //     JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
      //     (userID, displayName) => debug_log(`${userID} - ${displayName}`));
      //     this.activeRoom.on(
      //     JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
      //     (userID, audioLevel) => debug_log(`${userID} - ${audioLevel}`));
      //     this.activeRoom.on(
      //     JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
      //     () => debug_log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`));

      this.room.join()

      this.changeStatus({
        status      : 'open',
        activeRoomId: uuidv4()
      });

    } catch (error) {
      debug_log(connection, roomId, objUser);
      debug_log(error);
      this.changeStatus({
        status   : 'closed',
        lastError: error.message
      });
    } // try catch


    this.toString = () => (
      `started: ${this.conference.started}
      status:\n${JSON.stringify(this.conference.status, null, 2)}
      user:\n${JSON.stringify(this.user)}
      messages:\n${JSON.stringify(this.messages, null, 2)}
      `
    );
}; // function Room (connection, roomId, objUser)





let jitsi = {
    version     : "zaida-jitsi 0.0",
    currentState: state,

    init        :
      async function (){
        return this;
      }
    ,
    joinRoom    :
      async function (){
        return this;
      }
    ,
    getMedia    :
      async function (){
        debug_log("trying to get media");
        if(navigator.mediaDevices.getUserMedia){
          const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })
          debug_log("default", mediaStream);
        }

        const tracks = await window.JitsiMeetJS.createLocalTracks({ devices: ["audio"], deviceId: state.media.selected.idMic });
        state.media.selected.idMic = tracks.deviceId;
        debug_log("getMedia:createLocalTracks", state.media.selected, tracks);
        tracks.forEach(track =>
          state.trackList.push(track)
        );

        return state.media.selected;
      } // async function getMedia()
    ,
    setupLocalTracks:
      async function () {
        debug_log("setupLocalTracks",state.media.loaded)
        if(state.media.loaded === false) return console.error("media is not loaded");

        debug_log("searching for available media")

        const tracks = await window.JitsiMeetJS.createLocalTracks({ devices: ["audio"], deviceId: state.media.selected.idMic });
        state.media.selected.idMic = tracks.deviceId;
        debug_log("JitsiMeetJS.createLocalTracks", tracks);
        state.trackList.push(tracks);

        if (state.activeRoom) {
          for(let i in state.trackList){
            state.activeRoom.room.addTrack(i)
          }
        } // if (state.activeRoom)
      }
    ,

    updateLocalTrack:
      async function (mediaDevice, action = 'update') { // TODO remove action
        debug_log("updateLocalTrack", action, mediaDevice);
        if(!state.media.available) {
          debug_log("Sorry, no devices available!")
          return;
        }

        if (state.media.audioElement) {
          debug_log("clearing audioElement", state.trackList, state.media.audioElement);
          let clearTrack = state.trackList.pop();
          clearTrack.detach(state.media.audioElement);
          clearTrack.removeEventListener(window.JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,        this.onTrackStoppedEvent);
          clearTrack.removeEventListener(window.JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onTrackAudioOutputChangedEvent);
          clearTrack.dispose()
        }

        // if(state.trackList.length === 0) return console.error("media is not ready");

        debug_log("setting audioElement", tracks);
        let something = document.getElementById("media");
        tracks.attach(state.media.audioElement);
        tracks.addEventListener(window.JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,        this.onTrackStoppedEvent);
        tracks.addEventListener(window.JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onTrackAudioOutputChangedEvent);
        state.trackList.push(tracks);

        // let thingy = document.createElement("span");
        // thingy.innerHTML = 'whats this';
        // something.parentNode.insertBefore(thingy, something);
        state.media.audioElement = something;
        debug_log("set audioElement", state.trackList);

      }
    ,

    setRoomName:
      function (name){
        state.roomId = name;
        return this;
      }
    ,

    setUserName: function (username){
      if(!state.user) state.user = {};
      state.user.name = username;
      return this;
    }
    ,

    changeStatus: (newStatus)  => {jitsi.status = newStatus; notifyEvent(jitsi_events.status_changed, newStatus)}
    ,

    connect:
      function(){
        connection = new window.JitsiMeetJS.JitsiConnection(
          null,
          null,
          config
        );
        connection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,  onSuccess)
        connection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED,       onFailed)
        connection.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, onDisconnect)
        connection.addEventListener(window.JitsiMeetJS.events.connection.WRONG_STATE,             onWrongState)
        debug_log("here we go~ do we have this?", this);
        jitsi.changeStatus("connecting");
        connection.connect()
        debug_log("connect", JSON.stringify(state.status));
      }
} // control.jitsi {}


function onTrackStoppedEvent           (...e){debug_log("onTrackStoppedEvent",            e); };
function onTrackAudioOutputChangedEvent(...e){debug_log("onTrackAudioOutputChangedEvent", e); };

function onWrongState(...e){debug_log("onWrongState", e); jitsi.changeStatus("wrong state");};
function onFailed    (...e){debug_log("onFailed",     e); jitsi.changeStatus("failed");};
function onDisconnect(...e){debug_log("onDisconnect", e); jitsi.changeStatus("disconnected");};
function onSuccess   (...e){
  debug_log("onSuccess", e, connection, state.roomId, state.user)
  let conference = new Room(connection, state.roomId, state.user);

  jitsi.getMedia();

  state.rooms[state.roomId] = conference;
  state.activeRoom          = conference;
  debug_log("rooms", state.rooms);

  jitsi.changeStatus("connected");
  debug_log("onSuccess end", JSON.stringify(conference.conference.status));
}

export { jitsi, state } ;
