console.log('./ui/audio/index.js');
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import texts  from "../text";

let stream = null;

export default ({ close, Background, callbacks }) => {
  const videoRef = useRef(null);
  const [ error, setError ] = useState(null);
  const [ index, setIndex ] = useState(0);

  const cb_done = (msg) => {
    // console.trace(msg)
    window.sessionStorage.setItem("microphone_access", msg);
    callbacks.done()
  }

  useEffect(
    ()=>{
      console.log("next page", index);
    },
    [index]
  );

  useEffect(
    () => {
      let access = window.sessionStorage.getItem("microphone_access");
      if (access && access == "granted") {
        // microphone access granted, open media and close html welcome screen
        accessMicrophone().then(()=>cb_done());
      } else {
        // mic is had not been allowed, get rid of the html welcome screen
        cb_done();
      }
    },
    []
  ); // useEffect(

  function accessMicrophone() {
    return navigator.mediaDevices
    .getUserMedia({
      audio: process.env.MODE == "AUDIO",
      video: process.env.MODE == "VIDEO"
    })
    .then((deviceStream) => {
      videoRef.current.srcObject = deviceStream;
      stream = deviceStream;

      window.sessionStorage.setItem("microphone_access", "granted");

      cb_done("granted");
    })
    .catch(err => {
      if (window.sessionStorage.getItem("microphone_access") == "granted") window.sessionStorage.setItem("microphone_access", null);
      switch (err.name) {
        case "NotReadableError":
        case "TrackStartError" :
          setError(texts.ui.audio.TrackStartError);
          break;
        case "OverconstrainedError"       :
        case "ConstraintNotSatisfiedError":
          setError(texts.ui.audio.ConstraintNotSatisfiedError);
          break;
        case "NotFoundError"       :
        case "DevicesNotFoundError":
          setError(texts.ui.audio.DevicesNotFoundError);
          break;
        case "NotAllowedError"      :
        case "PermissionDeniedError":
          setError(texts.ui.audio.PermissionDeniedError);
          break;
        default:
          setError(texts.ui.audio.default(err.name));
      }
      return null;
    });

  } // function accessMicrophone() {

  function denyMicrophone() {
    cb_done("denied");
  }

  console.log("microphone_access", window.sessionStorage.getItem("microphone_access") )
  //TODO: this is all wrong and it should be in anywhere else except /ui/audio/ these texts should be clearly marked
  return (
    <>
      <NoDisplay>
        <video autoPlay muted ref={videoRef}>
          This browser does not support HTML5 video API
        </video>
      </NoDisplay>
      <Background>
        <Modal>
          {
            index == 0
              ? (
                <>
                  <h1>{texts.ui.audio.welcome}</h1>
                  <Content>
                    <Parragraph>
                      {texts.ui.audio.briefDescription[0]}<br/><br/>
                      {texts.ui.audio.briefDescription[1]}<br/><br/>
                      {texts.ui.audio.briefDescription[2]}
                    </Parragraph>
                  </Content>
                  <Connect onClick={() => setIndex(1)}>{texts.ui.audio.next}</Connect>
                </>
              )
              : null
          }
          {
            index == 1
              ? (
                <>
                  <h1>{texts.ui.audio.move.howToMove}</h1>
                  <Content>
                    <h3>{texts.ui.audio.move.move}</h3>
                    <Parragraph>{texts.ui.audio.move.moveDesc}</Parragraph>
                    <h3>{texts.ui.audio.move.turn}</h3>
                    <Parragraph>{texts.ui.audio.move.turnDesc}</Parragraph>
                    <GifContainer>
                      <img src="/public/assets/how_to_move.gif"/>
                    </GifContainer>
                    <Parragraph>{texts.ui.audio.move.turnTip}</Parragraph>
                    <h3>{texts.ui.audio.move.tp}</h3>
                    <Parragraph>{texts.ui.audio.move.tpDesc}</Parragraph>
                  </Content>
                  <Connect onClick={() => setIndex(2)}>{texts.ui.audio.next}</Connect>
                </>
              )
              : null
          }
          {
            index == 2
              ? (
                <>
                  <h1>{texts.ui.audio.talk.howToTalk}</h1>
                  <Parragraph>{texts.ui.audio.talk.desc}</Parragraph>
                  <Parragraph><strong>{texts.ui.audio.talk.bold}</strong></Parragraph>
                  <Parragraph>{texts.ui.audio.talk.listen}</Parragraph>
                  <Connect onClick={accessMicrophone}>{texts.ui.audio.talk.allowMic}</Connect>
                  <Button  onClick={denyMicrophone  }>{texts.ui.audio.talk.denyMic}</Button>
                  { error && <Error>{error}</Error>}
                </>
              )
              : null
          }
        </Modal>
      </Background>
    </>
  ); // return (
} // export default ({ close, Background, callbacks }) => {

export function getStream() {
  return stream;
}

export function mute() {
  stream.getTracks().forEach(t => t.enabled = false);
}

export function unmute() {
  stream.getTracks().forEach(t => t.enabled = true);
}

export function isEnabled() {
  console.log(stream.getTracks().map(a => a.enabled), !stream.getTracks().every(t => t.enabled == false))
  return !stream.getTracks().every(t => t.enabled == false);
}

const NoDisplay = styled.div`
  display: none;
`;

const Modal = styled.div`
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 540px;
  /* height: 350px; */

  /* left: calc(50vw - 210px);
  top: calc(50vh - 175px); */

  border-radius: 3px;
  box-sizing: border-box;
  padding: 24px;
`;

const Parragraph = styled.p`
  margin-top: 0px;
`;

const Button = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  outline: none;

  align-self: center;
  font-size:14px;

  margin-top: 12px;
`;

const Error = styled.p`
  text-align: center;
  align-self: center;
  font-size:14px;

  color: red;

`;

const Connect = styled(Button)`
  color: white;
  background-color: #5353b9;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 16px;
  transition: background-color 300ms;

  &:hover {
    background-color: #6b6bce;
  }
`;

const Content = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #02020244;
  }
`;

const GifContainer = styled.div`
  width: 245px;
  height: 150px;
  overflow: hidden;
  margin-bottom: 12px;

  & img {
    position: relative;
    transform: scale(1.2) translate(-22%,-27%);
  }
`;
