console.log('./src/scene/stands/index.js');
import * as THREE from "three";
import { scene, camera } from "../../../core";
import getPlayer from "../../../core/player";

import UiComponent from "../../../ui";
import stand from "../../../ui/stand";
import videoUI from "../../../ui/stand/video";
import Character from "../../../core/character";

import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { addToTick } from "../../../core/tick";
import { addSpecialCollider } from "../colliders";

import { getControl } from "../../../core/controls";

import Rotated from "../colliders/special/rotated";
import rotatedCollision from "./rotatedCollision";

class Stand extends THREE.Group {
  constructor ({
    title,
    size,
    position,
    rotation,
    material,
    trigger,
    block,
    body,
    brief,
    video,
    videos,
    npc,
    model,
    people,
    description,
    website,
    mirrored
  }) {
    super();

    this.title = title;
    this.description = description || brief;
    this.website = website;
    this.size = size;
    this.mirrored = mirrored;
    this.people = people;
    this.material = material || "stand-blive";
    this.model = model || "basic";
    this.rotation.set(0,rotation || 0,0)
    this.position.set(position.x, position.y || 0, position.z);
    if (npc) this.npc = npc;

    this.className = `stand-${title.replace(/ /g, "")}-${Math.floor(Math.random()*999)}`;

    const triggerMin = new THREE.Vector3(
      trigger.min.x + position.x,
      position.y || 0,
      trigger.min.z + position.z,
    );
    const triggerMax = new THREE.Vector3(
      trigger.max.x + position.x,
      (trigger.max.y || 0) + (position.y || 0) + 5,
      trigger.max.z + position.z,
    );

    this.blockingColliders = block.map(collider => {

      let box;

      const min = new THREE.Vector3(
        position.x + collider.min.x,
        (position.y || 0) + (collider.min.y || 0),
        position.z + collider.min.z,
      );

      const max = new THREE.Vector3(
        position.x + collider.max.x,
        collider.max.y ? collider.max.y + (position.y || 0) : (position.y || 0) + 5,
        position.z + collider.max.z,
      );

      if (collider.type == "rotated") box = new Rotated(collider);
      else box = new THREE.Box3(min, max)

      const oldThrough = typeof box.through == "function" ? box.through.bind(box) : null;

      box.through = (player) => {
        //if (typeof box.through == "function") return (this.people && this.people.includes(player.credential)) ||
        return (this.people && this.people.includes(player.credential)) || (oldThrough && oldThrough(player));
      }

      return box;
    });


    this.collider = new THREE.Box3();

    const points = this.blockingColliders.reduce((acc, collider) => [...acc, collider.min, collider.max], []);

    this.collider.setFromPoints([
      triggerMin,
      triggerMax,
      ...points,
    ]);

    this.trigger = new THREE.Box3(triggerMin, triggerMax);

    this.collider.colliders = [
      this.trigger,
      ...this.blockingColliders,
    ];


    this.trigger.through = this.through.bind(this);
    this.trigger.left = this.left.bind(this);
    this.trigger.tick = this.tick.bind(this);

    this.trigger.play = this.play.bind(this);
    this.trigger.stop = this.stop.bind(this);

    this.visible = false;
    this.isPlaying = false;

    this.through = this.through.bind(this);
    this.tick = this.tick.bind(this);
    this.addToScene = this.addToScene.bind(this);


    if (process.env.DEBUG == "true") {
      this.helpers = [
        //new THREE.Box3Helper(this.blockingCollider, 0xff0000),
        new THREE.Box3Helper(this.trigger, 0x0000ff),
        ...this.blockingColliders.map(collider => new THREE.Box3Helper(collider))
      ];
    }

    if (npc) this.npc.tick = function (delta) {
      this.mixer.update(delta);
    }

    this.videos = videos || [];
    this.videos.forEach((video) => {

      video.position = new THREE.Vector3(
        video.position.x,
        video.position.y,
        video.position.z,
      );

      video.icon = document.createElement("img");
      video.icon.src = window.location.pathname+"public/icons/play.png";
      video.icon.classList.add("icon");
      video.icon.style.display = "none";
      video.icon.setAttribute("draggable", false)

      document.getElementById("app").insertAdjacentElement("beforeend", video.icon);

      video.icon.onclick = () => this.play(video);

      //video.icon.addEventListener("mousedown", video.focus);
    })

    return this;
  }

  through() {
    if (!this.visible) {
      this.ui = new UiComponent(document.getElementById("app"), stand, {
        init: () => ({
          title: this.title,
          description: this.description,
          website: this.website,
          people: this.people,
        }),
      });
      this.ui.style.display = "";

      this.visible = true;
    }
    return true;
  }

  tick(player, {frustum}) {

    this.videos.forEach((video) => {
      const iconVector = video.position.clone().add(this.position);

      if(this.visible && frustum && frustum.containsPoint(iconVector)) {

        iconVector.project(camera);
        const x = (iconVector.x *  .5 + .5) * window.innerWidth;
        const y = (iconVector.y * -.5 + .5) * window.innerHeight;

        video.icon.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
        video.icon.style.display = "";
      } else {
        video.icon.style.display = "none";
      }
    })


    // const labelVector = (new THREE.Vector3(
    //   this.position.x,
    //   this.position.y + 3.5,
    //   this.position.z,
    // ));

    // if (this.isPlaying) {
    //   this.styleClass.innerHTML = `
    //     .${this.className} {
    //       transform: translate(0px, -50%);
    //       top: ${window.innerHeight*.5}px;
    //       right: 48px;
    //       position: absolute;
    //     }
    //   `;
    // } else if(frustum && frustum.containsPoint(labelVector) && this.visible) {

    //   labelVector.project(camera);
    //   const x = (labelVector.x *  .5 + .5) * window.innerWidth;
    //   const y = (labelVector.y * -.5 + .5) * window.innerHeight;

    //   this.styleClass.innerHTML = `
    //     .${this.className} {
    //       transform: translate(-50%, -50%) translate(${x}px,${y}px);
    //       position: absolute;
    //     }
    //   `;
    // } else {

    //   this.styleClass.innerHTML = `
    //     .${this.className} {
    //       display: none;
    //       position: absolute;
    //     }
    //   `;
    // }

  }

  left() {
    if (this.ui) {
      this.ui.close();
      this.ui.hang && this.ui.hang();
      this.ui = null;
    }
    this.visible = false;

    this.videos.forEach(video => {
      video.icon.style.display = "none";
    })
  }

  play(video) {
    console.log(video);

    new UiComponent(document.getElementById("app"), videoUI, {
      init: () => ({
        url: video.url,
        title: video.title,
        description: video.description,
      }),
      stop: () => this.stop(),
    })

    const screenVector = this.position.clone().add(video.position);

    const player = getPlayer();

    player.disableMovement(true);
    player.lookAround = false;

//********
    // player.camera.position.copy(
    //   player.worldToLocal(
    //     new THREE.Vector3(
    //       screenVector.x - (video.distance || 2.5)*Math.sin(video.rotation),
    //       screenVector.y,
    //       screenVector.z - (video.distance || 2.5)*Math.cos(video.rotation)
    //     )
    //   )
    // );

    player.camera.lookAt(screenVector);

    const controls = new getControl();

    this.viewerRot = {
      lat: controls.getLat(),
      lon: controls.getLon(),
    }

    // this.camera = player.children[1];
    // player.remove(this.camera);

    // this.camera.position.copy(screenVector);

    //player.disableMovement(true);
    // player.lookAt(new THREE.Vector3(
    //   this.screen.position.x,
    //   player.position.y,
    //   this.screen.position.z,
    // ));
    // player.children[1].position.set(0, 3, 2); // += -2; //(-2,0,3);
    // player.children[1].lookAt(this.screen.position);

    this.visible = false;
    this.ui.close();

  }

  stop() {
    const player = getPlayer();

    player.positionCamera();
    player.disableMovement(false);
    player.lookAround = true;

    const controls = new getControl();

    controls.setLat(this.viewerRot.lat, true);
    controls.setLon(this.viewerRot.lon, true);

    this.through();


  }

  addToScene() {
    if (this.helpers) this.helpers.forEach((obj) => {
      scene.add(obj)
    });

    scene.add(this);

    const loader = new GLTFLoader();
    const mtlLoader = new MTLLoader();

    //mtlLoader.load(`assets/stands/${this.material}.mtl`, (material) => {

      //loader.setMaterials(material)

      loader.load(`${window.location.pathname}public/assets/stands/${this.model}.glb`, ({scene: stand}) => {
        scene.add(stand);

        //console.log("Stand!",stand);
        stand.scale.set(this.size, this.size, this.size*(this.mirrored ? -1 : 1));
        stand.position.copy(this.position)

        stand.traverse((child) => {
          if (child.isMesh && child.material && child.material.map) {
            child.material.map.minFilter = THREE.LinearFilter;
            child.material.metalness = 0;
          }
        });

        stand.rotation.y = this.rotation.y + Math.PI/2;

      });

    //});


    if (this.npc) this.npc = new Character({
      character: this.npc.model,
      position: {
        x: this.position.x + (this.npc.position?.x || 1),
        y: this.position.y + (this.npc.position?.y || 0),
        z: this.position.z + (this.npc.position?.z || -1),
      },
      rotation: {
        x: 0,
        z: 0,
        y: this.npc.rotation || -this.rotation.y,
      },
      id: this.className,
    })
    .then(model => {
      scene.add(model);
      addToTick((...params) => model.tick(...params))
    })
  }
}

export function init(stands) {
  stands.map(data => {
    const stand = new Stand(data);
    stand.addToScene();
    addSpecialCollider(stand.collider);
    return stand;
  })
}
