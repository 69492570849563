console.log('./core/player/getNearbyPlayers.js');
export const maxDistance = 12;
export const threshold   = 10;
export const maxPeople   = 6;

let lastResult = [];

export default function (players, ownId) {

  console.log("Get nearby players called!");

  let ids = [];
  let unmuted = 0;

  // Evalua todos los jugadores
  for (let key in players) {
    // Si es uno mismo lo descarta
    if (key == ownId || players[key].enabled !== true || !players[key].position) continue;

    let dist = Math.sqrt(
      (this.position.x - players[key].position.x)**2 +
      (this.position.y - players[key].position.y)**2 +
      (this.position.z - players[key].position.z)**2
    );

    // Si esta muy lejos lo descarta y no estaba antes en la conversacion no tan lejos
    if (dist > maxDistance && !(lastResult.includes(key) && dist < (maxDistance + threshold))) continue;

    if (!players[key].muted) unmuted++; // Si no está muteado añado uno a los no-muteados
    else if (unmuted >= maxPeople) continue; // Si está muteado y ya hay suficientes no-muteados lo descarto

    // Pusheo al array temporal su key con una importancia
    ids.push({
      id:key,
      priority: (!players[key].muted)*2 + lastResult.includes(key),
      name: players[key].name,
      peer: players[key].peer,
      distance: dist,
    });


    // Orden de importancia
    // 1. Los que tienen microfono
    // 2. Los que estaban de antes
    // 3. Los cercanos
    // 4. Random
  }

  const result = ids
    .sort((a, b) => b.priority - a.priority)
    .slice(0, maxPeople);

  lastResult = result.map(p => p.id);

  if (typeof this.onNearbyPlayers == "function") this.onNearbyPlayers(result);

  return result;
}
