console.log('./ui/playerlist/index.js');
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import texts from "../text";
import getPlayer from "../../core/player";
import Character from "../../core/character";

export default function ({ callbacks }) {

  const [ players, setPlayers] = useState([]);

  useEffect(() => {
    setPlayers([
      { name: getPlayer().name, me: true },
      ...Character.getList().map(a => ({name: a.name}))

    ]);
    
  }, []);

  let columns = 1;
  
  if (players.length > 45) columns = 4;
  else if (players.length > 30) columns = 3;
  else if (players.length > 15) columns = 2;
  
  const cant = Math.floor(players.length / columns);

  if (!players.length) return null;

  return (
    <Wrapper width={Math.max(columns*180, 320)}>
      <ListHeader>{texts.ui.list} ({players.length})</ListHeader>
      <List>
        {
          new Array(columns).fill(0).map((_, i) => (
            <Column key={i}>
              {
                players.slice(cant*i, cant*(i+1)).map((player,x) => (
                  <Row me={player.me} key={x+i*cant}>{player.name}</Row>
                ))
              }
            </Column>
          ))
        }
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  background-color: #00000066;

  color: white;

  width: ${p => p.width}px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  left: calc(50vw - ${p => p.width/2}px);
  top: calc(25vh - 14px);

  box-sizing: border-box;

  padding: 12px;


`;

const List = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: row;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00000044;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000000BB;

  }
`;

const ListHeader = styled.h1`
  font-size: 28px;
  margin-top: 0px;
  line-height: 28px;
`;

const Column = styled.div`
  flex: 1;
`;

const Row = styled.p`
  margin: 6px 0px;
  ${
    p => p.me ? `
      &:after {
        content: " (${texts.ui.listYou})";
      }
    
      font-weight: bold;
    `
    : ""
  }
`
