console.log('./utils/intoDomain.js');
export default function intoDomain(val) {
  let result = val;
  if (val < -Math.PI) result += 2*Math.PI;
  if (val >  Math.PI) result -= 2*Math.PI;

  if (val < -Math.PI) return intoDomain(result);
  if (val >  Math.PI) return intoDomain(result);

  return result;
  // return val % Math.PI; // fit value inside PI
}
