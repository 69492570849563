console.log('./src/scene/colliders/special/stage.js');
import * as THREE from "three";
import getSocket, { getWelcomed } from "../../../../core/socket";
import getPlayer from "../../../../core/player";

import UiComponent from "../../../../ui";
import presentation from "../../../../ui/presentation";

import { scene, camera } from "../../../../core";
import { addToTick } from "../../../../core/tick";

import throttle from "../../../../utils/throttle";
import interpolate from "../../../../utils/interpolate";

import { getLights, restore } from "../../lights";

export default class Stage extends THREE.Box3 {
  constructor({name, min, max, controls, screen, focus, room}) {
    super(min, max);
    this.name   = name;
    this.room   = room;
    this.inside = false;
    this.type   = "stage";

    this.controls = new THREE.Vector3(controls.x, controls.y, controls.z);
    this.focusVector = new THREE.Vector3(focus.x, focus.y, focus.z);
    this.screenVector = new THREE.Vector3(screen.x, screen.y - screen.height/2, screen.z);

    this.visible = false;
    this.className = name+"-stage-controls";
    this.screenHeight = screen.height;

    const player = getPlayer();
    const socket = getSocket();

    const proyector = new THREE.Mesh(
      new THREE.PlaneGeometry(screen.height, 1, 1, 1),
      new THREE.MeshBasicMaterial({
        color: 0xffffff,
      })
    );

    proyector.scale.y = 0;
    proyector.position.set(screen.x, screen.y, screen.z);
    proyector.scale.set(screen.ratio, 1, 1);
    //proyector.scale.y = 0.001;

    proyector.rotation.y = screen.rotation;

    scene.add(proyector);

    this._transitionTime = 0;

    this.proyector = proyector;
    window.proyector = proyector;

    this.style = document.createElement("style");
    document.getElementsByTagName("HEAD")[0].insertAdjacentElement("beforeend", this.style);

    this.startLaserBeam = this.startLaserBeam.bind(this);
    this.stopLaserBeam = this.stopLaserBeam.bind(this);
    this.onPointer = this.onPointer.bind(this);

    getWelcomed()
    .then(() => {
      new UiComponent(
        document.getElementById("app"),
        presentation,
        {
          init: () => ({
            socket,
            player,
            className: this.className
          }),
          play: (video) => this.play(video),
          stop: () => this.stop(),
          focus: () => this.focus(),
          unfocus: () => this.unfocus(),
          startLaserBeam: this.startLaserBeam,
          stopLaserBeam: this.stopLaserBeam,
        }
      );
    })

    addToTick(this.animate.bind(this));

    socket.on("laserbeam", (visible, from, to) => {
      if (!this.laserBeam) this.createLaserBeam();

      this._transitionTime = 0;

      //this.updateBeam(visible, from, to);
      this._last = this._target;
      this._target = { visible, from, to };

      console.log("arrived laser beam")
    })
  }

  through() {
    if (this.inside) return true;

    const socket = getSocket();
    socket.emit("joinstage", this.name);

    this.inside = true;
    return true;
  }

  left() {
    const socket = getSocket();

    socket.emit("leftstage", this.name);

    this.stop();

    this.inside = false;
  }

  stop () {
    this.visible = false;
    this.proyector.material = new THREE.MeshBasicMaterial({ color: 0xffffff });
  }

  play (video) {
    const texture = new THREE.VideoTexture(video);

    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBFormat;

    this.proyector.material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      map: texture,
    });

    video.play();
  }

  animate({ frustum, delta }) {
    if (!this.proyector) return;

    const player = getPlayer();



    if (this.proyector.material.map && this.proyector.material.map.image && this.proyector.material.map.image.srcObject) {
      const aspectRatio = this.proyector.material.map.image.srcObject.getVideoTracks()[0].getSettings().aspectRatio;

      if (!this.visible && aspectRatio !== undefined) {
        this.proyector.scale.x = aspectRatio;
        this.visible = true;

      }

      if (this.visible && this.proyector.scale.y < this.screenHeight) {
        this.proyector.position.y -= 0.05;
        this.proyector.scale.y += 0.1;
      }


      this.proyector.material.map.update();
    }

    if (!this.visible && this.proyector.scale.y > 0.1) {
      this.proyector.position.y += 0.05;
      this.proyector.scale.y -= 0.1;
    }

    const sharePoint = this.controls.clone();


    if(player && player.position.distanceTo(sharePoint) < 3.5 && frustum.containsPoint(sharePoint)) {
      sharePoint.project(camera);

      const x = (sharePoint.x *  .5 + .5) * window.innerWidth;
      const y = (sharePoint.y * -.5 + .5) * window.innerHeight;

      this.style.innerHTML = `
        .${this.className} {
          transform: translate(-50%, -50%) translate(${x}px,${y}px);
        }
      `;
    }
    else {
      this.style.innerHTML = `
        .${this.className} {
          display: none;
        }
      `;
    }

    if (this._last && this._target) {
      this._transitionTime += delta;

      if (this._transitionTime > 0.3 ) {
        this._last = null;
        return;
      }


      this.laserBeam.visible = this._target.visible;

      if (this._target.visible == false) return;


      if (this._last.visible == false) {
        this.laserBeam.geometry.setFromPoints([
          new THREE.Vector3(this._target.from.x, this._target.from.y, this._target.from.z),
          new THREE.Vector3(this._target.to.x, this._target.to.y, this._target.to.z),
        ]);
        return;
      }


      this.laserBeam.geometry.setFromPoints([
        new THREE.Vector3(
          interpolate(this._last.from.x, this._target.from.x, this._transitionTime, 0.25),
          interpolate(this._last.from.y, this._target.from.y, this._transitionTime, 0.25),
          interpolate(this._last.from.z, this._target.from.z, this._transitionTime, 0.25),
        ),
        new THREE.Vector3(
          interpolate(this._last.to.x, this._target.to.x, this._transitionTime, 0.25),
          interpolate(this._last.to.y, this._target.to.y, this._transitionTime, 0.25),
          interpolate(this._last.to.z, this._target.to.z, this._transitionTime, 0.25),
        ),
      ]);

      this.laserBeam.geometry.computeBoundingSphere();
    }
  }


  focus() {
    const player = getPlayer(), socket = getSocket();

    player.camera.updateMatrix();

    //player.position.set(this.focusVector.x, this.focusVector.y, this.focusVector.z)
    //********
    // player.camera.position.copy(player.worldToLocal(this.focusVector.clone()));
    // player.camera.lookAt(this.screenVector);

    player.focusingPesentation = true;

    //this._emittedIdle = false;
    player.disableMovement(true);

    player.lookAround = false;

    socket.emit("focuspresentation", this.name, this.room);

    // const lights = getLights();

    // lights.light.intensity = 0;
    // lights.light2.intensity = 0;
    // lights.ambient.intensity = 0.2;

  }

  unfocus() {

    const player = getPlayer(), socket = getSocket();

    player.positionCamera();
    player.disableMovement(false);
    player.rotation.x = 0;
    player.rotation.z = 0;
    player.focusingPesentation = false;

    player.lookAround = true;

    socket.emit("unfocuspresentation", this.name, this.room)
  }

  createLaserBeam() {
    if (!this.raycaster) this.raycaster = new THREE.Raycaster();
    if (!this.laserBeam) {
      this.laserBeam = new THREE.Line(
        new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(0, 0, 0),
          new THREE.Vector3(2, 2, 2),
        ]),
        new THREE.LineBasicMaterial({
          linewidth: 2,
          color: 0xfb5d93,
        }),
      );
      scene.add(this.laserBeam);
    }
  }

  startLaserBeam() {
    if (!this.proyector) return;

    window.addEventListener("mousemove", this.onPointer);

    const socket = getSocket();

    this.createLaserBeam();

    if (!this.emitBeam) this.emitBeam = throttle(
      (visible, from, to) => {
        if (visible ){
          console.log("Emitting laser!")
          socket.emit(
            "laserbeam",
            visible,
            {
              x: from.x,
              y: from.y,
              z: from.z,
            },
            {
              x: to.x,
              y: to.y,
              z: to.z,
            },
            this.name,
          );
        } else {
          console.log("Emitting invisible laser!")
          socket.emit("laserbeam", visible);
        }
      },
      250
    );

  }

  stopLaserBeam() {
    window.removeEventListener("mousemove", this.onPointer);
  }

  onPointer(e) {
    const mouse = new THREE.Vector2(
      ( event.clientX / window.innerWidth ) * 2 - 1,
	    -( event.clientY / window.innerHeight ) * 2 + 1,
    )

    this.raycaster.setFromCamera( mouse, camera );
    const _from = camera.getWorldPosition(new THREE.Vector3()).add(new THREE.Vector3(0,-0.5, 0));

    var intersects = this.raycaster.intersectObject( this.proyector )[0];

    if (!intersects) {

      if (this.lastWasEmpty) return;
      this.lastWasEmpty = true;

      this.emitBeam(false);

      return this.updateBeam(false);
    }
    this.lastWasEmpty = false;


    this.emitBeam(true, _from, intersects.point);

    this.updateBeam(
      true,
      _from,
      intersects.point,
    );
  }

  updateBeam(visible, from, to) {


    if (!visible) {
      this.laserBeam.visible = false;
      return;
    }
    this.laserBeam.visible = true;

    this.laserBeam.geometry.setFromPoints([ from, to ]);
    this.laserBeam.geometry.computeBoundingSphere();
  }
}
