import React, {useState, useEffect, useRef} from "react";
import styled    from "styled-components";

import getPlayer from "../../../core/player";
import { lockPointer, unlockPointer } from "../../../core/controls";


export default function ({
  messages, user, conference, room, children, last,
  onSend,
  onOpenChat,
  onSettings
}) {
  // console.log("ControlBox", "messages", messages, "user", user, "conference", conference, "room", room)

  const [getUserChat,     setUserChat]     = useState(null);
  const [getConversation, setConversation] = useState([]);
  const [getLastMessage,  setLastMessage]  = useState(last);

  const defaultData = {
    user: {
      name: "Default UserName",
      status: "online"
    },
    conference: {
      name: "Default Conference",
      users: []
    },
    room: {
      name: "Default Room",
      users: []
    },
    /* extra controls*/
  }

  useEffect(
    ()=>{
      // console.log("messages changed", getConversation, messages);
      if(getUserChat && onOpenChat) onOpenChat(getUserChat)
      setLastMessage(Date.now())
      if(messages && getUserChat && getUserChat.name && messages[getUserChat.name])
        setConversation([...messages[getUserChat.name]])
    },
    [messages]
  );

  useEffect(
    ()=>{
      console.log("effect",
        "getUserChat,", getUserChat,
        "messages[getUserChat],", messages[getUserChat],
        "messages,", messages,
        "getConversation", getConversation
      )
      if(getUserChat && getUserChat.name) setConversation(messages[getUserChat.name]);
    },
    [getUserChat]
  )

  useEffect(
    ()=>{
      console.log("ControlBox: change in user", user);
    },
    [user]
  )

  useEffect(
    ()=>{
      console.log("ControlBox: change in conference", conference);
    },
    [conference]
  )

  useEffect(
    ()=>{
      console.log("ControlBox: change in room", room);
    },
    [room]
  )



  let username     = user.name        || defaultData.user.name;
  let userstatus   = user.status      || defaultData.user.status;

  let header       = conference.name  || defaultData.conference.name;
  let roomUsers    = conference.users || defaultData.room.users;

  let label        = room.name        || defaultData.room.name;
  let hallUsers    = room.users       || defaultData.conference.users;

  let textMessages = messages         || {};

  const onUserLabelClick = (user) => {
    console.log("onUserLabelClick", user)
    if(onOpenChat) onOpenChat(user)
    setUserChat(
      (old) => (old && (old.name == user.name)
        ? null
        : ({...user})
      )
    );
  }

  return (
    <ChatArea>
      <Container>
        <Header>{header}</Header>
        <Content>
          {roomUsers.map(
            (user, index) => (
              <UserEntry
                key             ={index}
                online          ={user.status==="online"}
                user            ={user}
                icon            ="mic"
                // alertCount      ={user.unread}
              />
            )
          )}
          <SeparatorLabel>{label}</SeparatorLabel>
          {Array.isArray(hallUsers) && hallUsers.map(
            (user, index) => (
              <UserEntry
                key             ={index}
                online          ={user.status==="online"}
                user            ={user}
                notificationIcon="chat_bubble"
                onClick         ={onUserLabelClick ? ()=>onUserLabelClick(user) : null}
                alertCount      ={user.unread}
              />
            )
          )}
        </Content>
        <Footer>
          <div
            title={"SocketId: "+user.socketId}
            style={{flexGrow: 1, display: "flex"}}>
            <Label>{username}</Label>
          </div>
          {console.log("notif room", room)}

          <IconClickable title="Go to VR mode"     color="darkgrey" >screenshot_monitor</IconClickable>
          <IconClickable title="Microphone on/off" color="darkgrey" >mic</IconClickable>

          <IconClickable
            title="Open chat window"
            color="darkgrey"
            onClick={onUserLabelClick ? ()=>onUserLabelClick(room) : null}
            textAlign="left"
          >
            forum
            <IconText color="white">{room.unread||""}</IconText>
          </IconClickable>

          <IconClickable
            title="Open Settings"
            color="darkgrey"
            onClick={onSettings ? ()=>onSettings() : null}
          >
            settings
          </IconClickable>

        </Footer>
      </Container>
      <ChatWindow
        user      ={getUserChat}
        messages  ={getConversation}
        onClose   ={setUserChat ? ()=>setUserChat(null) : null}
        onSend    ={onSend}
      />
      {children}
    </ChatArea>
  );
}

const ChatArea = styled.div`
  display: flex;
  flex-direction: row;
  ${'' /* background-color: #6b4646; */}
  position: fixed;
  bottom: 0px;
  left: 0px;
  gap: 8px;
  padding-bottom: 1em;
  padding-left: 1em;
`

const ChatWindow = ({last, user, messages, onClose, onSend}) => {
  console.log("ChatWindow", "user", user, "messages", messages)
  const [intmsg, setintmsg] = useState("nothing here");

  useEffect(
    ()=>{
      // console.log("chat window changed messages")
      if(!Array.isArray(messages)) return;
      setintmsg(
        messages.map(
          (message, index)=>(
            <Message
              self           ={message.author.name === getPlayer().name}
              displayName    ={message.author.name}
              backgroundColor={message.author.name === getPlayer().name ? "beige" : "lightgray"}
              key            ={`${index}${last}`}
            >
              {message.message.split("\n").map(
                (x,i)=><React.Fragment key={i}>{x}<br/></React.Fragment>
              )}
            </Message>
          )
        )
      );
    },
    [messages, user]
  );

  useEffect(()=>{
    const el = document.getElementById("chat_text_messages")
    if(el) el.scrollTop = el.scrollHeight
  })

  return (
    user
      ? (
        <Container
          key  ={last}
          style={{left: "28vw", borderRadius: "12px", display: "flex", minHeight: "15em"}}
        >
          <Header style={{display: "inline-flex", marginBottom: "auto"}}>
            <div style={{flexGrow: "1"}}>{user.name || "Name not set"}</div>
            <IconClickable onClick={onClose} style={{fontSize: "1.25em"}} >cancel</IconClickable>
          </Header>
          <ChatMessageHolder id="chat_text_messages">
            {intmsg}
          </ChatMessageHolder>
          <FancyInput
            user    ={user}
            onFocus ={()=>getPlayer().disableMovement(true)}
            onBlur  ={()=>getPlayer().disableMovement(false)}
            onSend  ={onSend}
          />
        </Container>
      )
      : ""
  )
};

const ChatMessageHolder = styled.div`
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`

const FancyInput = ({user, onSend}) => {
  const [getText, setText] = useState("");
  const doSend = () => {
    if(getText.trim()=="") return; //empty input, do nothing
    if(onSend && onSend(user, getText.trim())) {
      document.getElementById("chat_input_editable").innerHTML=""
      setText("")
    }
  }
  return (
    <Header style={{display: "flex"}}>
      <StyledInput
        id="chat_input_editable"
        contentEditable="true"
        onFocus  ={()=>getPlayer().disableMovement(true)}
        onBlur   ={()=>getPlayer().disableMovement(false)}
        onInput  ={(e)=>setText(e.target.innerText)}
        onKeyDown={
          (e)=>{
            if(e.code==="Enter" && e.shiftKey===false){
              e.preventDefault();
              doSend();
              return;
            }
          }
        }
      />
      <IconClickable color="" onClick={doSend}>send</IconClickable>
    </Header>
  );
}

const StyledInput = styled.div`
  word-break: break-word;
  text-align: left;
  margin: 4px;
  border-radius: 6px;
  border-style: hidden;
  font-size: 1.25em;
  padding-left: 8px;
  background-color: beige;
  color: black;
  flex-grow: 1;
`

const UserEntry = ({online, user, alertCount, notificationIcon, onClick, icon="circle"}) => (
  <DataContainer onClick={onClick}>
    <Icon
      outlined={!online}
      color   ={online?"chartreuse":"darkgray"}
    >{icon}</Icon>
    <Label>{user && user.name || "..."}</Label>
    <IconClickable color="darkslategray" textAlign="left">
      {notificationIcon}
    <IconText>{alertCount}</IconText></IconClickable>
  </DataContainer>
);

const Message =({children, self, backgroundColor, displayName}) => (
  <MessageRow self={self}>
    <MessageText backgroundColor={backgroundColor}>
      <SimpleNameLabel>
        {displayName||"Friend"}:
      </SimpleNameLabel><br/>
      {children}</MessageText>
  </MessageRow>
);

const SimpleNameLabel = styled.span`
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
`


const MessageRow = styled.div`
  align-self: ${p => p.self ? "self-end" : "self-start"};
  text-align: ${p => p.self ? "end" : "start"};
`

const MessageText = styled.div`
  background-color: ${p => p.backgroundColor || "beige"};
  border-radius: 1em;
  margin: 0.5em;
  padding: 0.5em;

  font-size: 1.2em;
  font-weight: bold;
  display: inline-block;
  flex-shrink: 0;
`

const Label = styled.span`
  cursor: pointer;
  color: lightgray;
  align-self: center;
  text-align: left;
  font-weight: bold;
  border-radius: 6px;
  padding: 4px;
  ${p => p.grow && "flex-grow: 1"};

  &:hover {
    color: floralwhite;
    background-color: darkgray;
  }
`

const SeparatorLabel = styled.div`
  background-color: #41414163;
  grid-column-start: 1;
  grid-column-end: 4;
  text-align: center;
  color: lightgray;
  align-self: center;
  font-weight: bold;
`
const Icon = styled.div`
  font-family: '${p => p.outlined ? "Material Icons Outlined" : "Material Icons"}';
  color: ${p => p.color || "darkgray"};
  font-weight: normal;
  font-style: normal;
  font-size: 2em;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-align: ${p => p.textAlign||"center"};
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  border-radius: 6px;


  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
`
const IconClickable = styled(Icon)`
  cursor: pointer;
  width:1em;
  padding: 2px;
  font-size: 2em;
  margin: 2px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    color: floralwhite;
    background-color: darkgray;
  }
`

const IconText = styled.span`
  position: relative;
  text-align: center;
  top: -0.7em;
  left: -1.15em;
  color: ${p => p.color || "white"};
  font-family: monospace;
  font-size: 0.7em;
`

const DataContainer = styled.div`
  display: contents;
`

const Container = styled.div`
  overflow: hidden;
  user-select: none;
  pointer-events: visible;

  bottom : 24px;
  left   : 24px;
  min-width: 15em;
  max-width: 23vw;
  max-height: 80vh;
  ${'' /* width: -webkit-fill-available;
  width: -moz-available; */}


  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  margin-top: auto;
  /* flex-flow: column; */

  border-radius: 30px;
  background-color: ${p => "#ffffff44" /* p.hovered ? "#ffffff44" : "transparent"*/};
`

const Header = styled.div`
  /* flex:0 1 auto; */
  flex-grow:0;
  flex-shrink:1;
  padding: 0.6em;
  padding-bottom: 0px;
  padding-top: 5px;
  text-align: center;
  align-items: center;
  color: lightgray;
  font-weight: bold;

  background-color: #41414163;
`

const Content = styled.div`
  display: grid;
  flex-grow: 1;

  grid-template-columns: 2em 1fr 3em;
  grid-auto-rows: 2em;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;
`

const Footer = styled.div`
  /* flex:0 1 auto; */
  align-self:auto;
  padding: 0.6em;
  text-align: center;

  background-color: #41414163;
  display: flex;
`
