console.log('./ui/stand/index.js');
import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";

import { callStandPeople, hangStandPeople } from "../redux/actions/calls";
import { useDispatch } from "react-redux";

export default function ({ parent, callbacks }) {

  const [data, setData] = useState({});
  const bodyRef = useRef(null);
  const [players, setPlayers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = callbacks.init();
    setData(data);

    if (bodyRef.current) bodyRef.current.innerHTML = data.description;

    if (!data.people) return;

    fetch(`players?users=${data.people.join(",")}`)
      .then(data => data.json())
      .then(players => {
        setPlayers(players);
        parent.hang = () => {
          dispatch(hangStandPeople());
        };
      });
  }, []);

  if (!data) return null;

  function openWebsite() {
    const popup = window.open(data.website);
    popup.focus();
  }

  function talkWithStandPeople () {
    dispatch(callStandPeople(players))
  }

  return (
    <Wrapper>
      <Background>
        <Title>{data.title}</Title>
        <Description ref={bodyRef}/>
      <Options>
        <Option onClick={openWebsite} >
          <Icon src="public/icons/website.png" />
          <OptionLabel>Visitar</OptionLabel>
        </Option>
        <Option onClick={talkWithStandPeople}>
          <Icon src="public/icons/user.png" />
          <OptionLabel>Conversar ({players.length || 0})</OptionLabel>
        </Option>
      </Options>
      </Background>
    </Wrapper>
      
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  max-width: 580px;
  color: white;
`;

const Background = styled.div`
  padding: 20px;
  border-radius: 3px;
  background-color: #00000066;
`;

const Title = styled.h1`
  font-size: 50px; 
  font-weight: 100;
  margin: 0px;
  letter-spacing: 2px;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
  padding: 12px;
  border-radius: 60px;

  &:hover {
    filter: brightness(0.8);
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: row;
`;

const Option = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  margin-top: 12px;
`;

const OptionLabel = styled.label`
  font-size: 16px;
  margin-top: 12px;

`;

const Description = styled.p`
  font-size: 20px;
`;
