import React, {useState, useEffect, useRef} from "react";
import styled       from "styled-components";
import TabContainer from "./TabContainer";

import { get_mapping, set_hook, clear_hook } from "/core/inputController";

let keynames = {
    8	: "backspace",
    9	: "tab",
   13	: "enter",
   16	: "shift(left)",
   16	: "shift(right)",
   17	: "ctrl(left)",
   17	: "ctrl(right)",
   18	: "alt(left)",
   18	: "alt(right)",
   19	: "pause/break",
   20	: "caps lock",
   27	: "escape",
   32	: "space",
   33	: "page up",
   34	: "page down",
   35	: "end",
   36	: "home",
   37	: "left arrow",
   38	: "up arrow",
   39	: "right arrow",
   40	: "down arrow",
   44	: "print screen",
   45	: "insert",
   46	: "delete",
   48	: "0",
   49	: "1",
   50	: "2",
   51	: "3",
   52	: "4",
   53	: "5",
   54	: "6",
   55	: "7",
   56	: "8",
   57	: "9",
   65	: "a",
   66	: "b",
   67	: "c",
   68	: "d",
   69	: "e",
   70	: "f",
   71	: "g",
   72	: "h",
   73	: "i",
   74	: "j",
   75	: "k",
   76	: "l",
   77	: "m",
   78	: "n",
   79	: "o",
   80	: "p",
   81	: "q",
   82	: "r",
   83	: "s",
   84	: "t",
   85	: "u",
   86	: "v",
   87	: "w",
   88	: "x",
   89	: "y",
   90	: "z",
   91	: "left window key",
   92	: "right window key",
   93	: "select key (Context Menu)",
   96	: "numpad 0",
   97	: "numpad 1",
   98	: "numpad 2",
   99	: "numpad 3",
  100	: "numpad 4",
  101	: "numpad 5",
  102	: "numpad 6",
  103	: "numpad 7",
  104	: "numpad 8",
  105	: "numpad 9",
  106	: "multiply",
  107	: "add",
  109	: "subtract",
  110	: "decimal point",
  111	: "divide",
  112	: "f1",
  113	: "f2",
  114	: "f3",
  115	: "f4",
  116	: "f5",
  117	: "f6",
  118	: "f7",
  119	: "f8",
  120	: "f9",
  121	: "f10",
  122	: "f11",
  123	: "f12",
  144	: "num lock",
  145	: "scroll lock",
  173	: "audio volume mute",
  174	: "audio volume down",
  175	: "audio volume up",
  181	: "media player",
  182	: "launch application 1",
  183	: "launch application 2",
  186	: "semi-colon",
  187	: "equal sign",
  188	: "comma",
  189	: "dash",
  190	: "period",
  191	: "forward slash",
  192	: "Backquote/Grave accent",
  219	: "open bracket",
  220	: "back slash",
  221	: "close bracket",
  222	: "single quote",
}

let actions = [
  {
    key      : "updown",
    title    : "adelante / atraz",
    content  : "Mueve la camara hacia la direccion que se esta viendo",
  },
  {
    key      : "leftright",
    title    : "izquierda / derecha",
    content  : "Mueve hacia los lados manteniendo la vista hacia adelante",
  },
  {
    key      : "rotateview",
    title    : "Girar izquierda / Girar derecha",
    content  : "Gira el punto de vista sin moverse",
  },
  {
    key      : "lookaround",
    title    : "vista libre",
    content  : "Cambiar la vista hacia cualquier direccion",
  },
  {
    key      : "action",
    title    : "Aceptar",
    content  : "Acepta opcion / activar objeto seleccionado",
  },
  {
    key      : "cancel",
    title    : "Cancelar",
    content  : "Cancela seleccion / Sale de vista",
  },
  {
    key      : "userlist",
    title    : "Lista de usuarios",
    content  : "Muestra la lista de usuarios",
  },
]

export default function ({
  Background,
  callbacks,
  visible,
  onSettings
}){
  const [Mapping, setMapping] = useState(get_mapping());
  const [ShowKey, setShowKey] = useState(false);

  useEffect(
    ()=>{
      console.log("trying to init input from data")
      setMapping(get_mapping());
      // init_input(callbacks.getRenderer())
    },
    [visible]
  );

  if(!visible) return "";

  return (
    <Background zIndex="1000">
      <Modal>
        <Content>
          <TabContainer
            tabs={{
              "Controls"   : {
                title  : "Controls",
                content:
                  <Controls
                    Mapping   ={Mapping}
                    setMapping={setMapping}
                    ShowKey   ={ShowKey}
                    setShowKey={setShowKey}
                  />
              },
              "Voice"      : { title: "Voice",       content: <div>supps</div> },
              "Performance": { title: "Performance", content: <div>noni </div> }
            }}
          />
        </Content>

        <div style={{display: "flex"}}>
          <Connect
            onClick={onSettings ? ()=>onSettings(true) : null}
          >
            Apply
          </Connect>
          <Connect
            onClick={onSettings ? ()=>onSettings(false) : null}
          >
            Cancel
          </Connect>
        </div>
      </Modal>
      {ShowKey
        ? <FloatingInput ShowKey={ShowKey} setShowKey={setShowKey} />
        : ""
      }
    </Background>
  )
}

const FloatingInput = ({ShowKey, setShowKey}) => {
  const [getInput, setInput] = useState("");

  useEffect(
    ()=>{
      if(ShowKey){
        console.log("ShowKey", ShowKey)
        clear_hook();
        set_hook(
          ShowKey[4],
          true,
          ShowKey[4] === "keyboard"
            ? (e)=>setInput(keynames[e.keyCode])
            : (e)=>{
              for (var i = 0; i < e.axes.length; i++) {
                if(e.axes[i]){
                  return setInput(
                    `axis ${i} ${
                      Math.sign(e.axes[i])>0
                        ? "positive"
                        : "negative"
                    }`
                  )
                } // if(e.axes[i])
              }

              for (var i = 0; i < e.buttons.length; i++) {
                if( Boolean(e.buttons[i].value) ){
                  return setInput(`Botón ${i}`)
                } // if(e.axes[i])
              }
            }
        )
      } else {
        console.log("false");
        return;
      }

      return ()=>{
        console.log("close")
        clear_hook();
      }
    },
    [ShowKey]
  );

  let sk     = ShowKey[0].split('/');
  let posneg = ShowKey[5] === "negative" ? 1 : 0;

  console.log(sk, ShowKey)

  return (
    <FloatKeyPress style={{left: ShowKey[1]+"px", top: ShowKey[2]+"px"}}>
      <p>
        Presiona cualquier <KeyLabel>{
          {
            keyboard : "tecla del teclado",
            gamepad  : "boton o palanca del gamepad",
            xrcontrol: "eje o boton del mando VR",
          }[ ShowKey[4] || "keyboard" ]
        }</KeyLabel>
        &nbsp;para definir la acci&oacute;n&nbsp;
        <KeyLabel>{
          sk[posneg]
        }</KeyLabel>
      </p>
      <ActionKey>{getInput||"none"}</ActionKey>
      <button onClick={()=>setShowKey(false)}>Cancelar</button>
    </FloatKeyPress>
  );
};

function Controls({
  Mapping, setMapping,
  ShowKey, setShowKey
}){

  const showKey = (e, k, i, y, sign) => (
    setShowKey([
      k.title,
      e.clientX-100,
      e.clientY-100,
      i,
      y,
      sign
    ])
  ); // const showKey = (e, k, i, y, sign)

  const trSingle = (dev, k, i) => {
    const bothsides = Boolean(k.title.split('/').length > 1); // Axis desc for positive / Axis desc for negative
    const axis      = Mapping.defaults[dev][k.key];
    const positive  = (
      axis && axis.positive
        ? axis.positive.map(
            (m,n)=>(
              <ActionKey
                key    ={`set-pos-${k.key}-${m}${(i*n)+n}`}
                id     ={`set-${k.key}-${m}${(i*n)+n}`}
                onClick={(e)=>showKey(e, k, i, dev, "positive")}
              >
                { dev === "keyboard"
                    ? keynames[m]
                    : `axis ${m}` }
              </ActionKey>
            )
          )
        : []
    );

    const negative = (
      axis && axis.negative
        ? axis.negative.map(
            (m,n)=>(
              <ActionKey
                key    ={`set-neg-${k.key}-${m}${(i*n)+n}`}
                id     ={`set-${k.key}-${m}${(i*n)+n}`}
                onClick={(e)=>showKey(e, k, i, dev, "negative")}
              >
                { dev === "keyboard"
                    ? keynames[m]
                    : `axis ${m}` }
              </ActionKey>
            )
          )
        : []
    );

    return (
      <tr key={`${k.key}-${i}`} >
        <td width="20%">{dev}:</td>
        <td colSpan={bothsides?"1":"2"}>
          <ActionKey
            key    ={`add-neg-${k.key}-X${(i*9)+9}`}
            id     ={`add-${k.key}-X${(i*9)+9}`}
            onClick={(e)=>showKey(e, k, i, dev, "positive")}
          >
            M&aacute;s
          </ActionKey>
          {positive}
        </td>
        {bothsides
          ?
            <td>
              <ActionKey
                key    ={`add-neg-${k.key}-X${(i*9)+9}`}
                id     ={`add-${k.key}-X${(i*9)+9}`}
                onClick={(e)=>showKey(e, k, i, dev, "negative")}
              >
                M&aacute;s
              </ActionKey>
              {negative}
            </td>
          : null }
      </tr>
    );
  }; // const trSingle = (dev, k, i)

  const trData = (k, i) => (
     <React.Fragment key={k.title}>
       <tr key={`${k.title}-0`} >
         <td rowSpan="4" width="30%">
           {k.title}:<br/>
           <span style={{fontSize: "0.75em", fontStyle: "italic"}}>{k.content}</span>
         </td>
       </tr>
       {trSingle("keyboard",  k,    i)}
       {trSingle("gamepad",   k, 10+i)}
       {trSingle("xrcontrol", k, 20+i)}
     </React.Fragment>
  ); // const trData = (k, i)

  return(
    <div>
      <table >
        <thead>
          <tr>
            <th>Descripci&oacute;n</th>
            <th>Dispositivo</th>
            <th>Positivo</th>
            <th>Negativo</th>
          </tr>
        </thead>
        <tbody>
          {actions.map(trData)}
        </tbody>
      </table>
    </div>
  );
} // function Controls({...})

const KeyLabel = styled.span`
    font-weight: bold;
    font-style: italic;
`

const Modal = styled.div`
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 55vh;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 24px;
`;

const FloatKeyPress = styled(Modal)`
    background-color: seashell;
    position: absolute;
    width: 300px;
    min-height: 100px;
    height: auto;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
`

const ActionKey = styled.div`
  cursor: pointer;
  display: inline-block;
  background-color: lightgray;
  border-radius: 8px;
  border: solid 1px gray;
  padding: 3px;
  margin-left: 2px;
  font-size: small;
  font-weight: bold;
  text-align: center;
`



const Parragraph = styled.p`
  margin-top: 0px;
`;

const Error = styled.p`
text-align: center;
align-self: center;
font-size:14px;

color: red;

`;

const Button = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  outline: none;

  align-self: center;
  margin: auto;
  font-size:14px;

  margin-top: 12px;
`;


const Connect = styled(Button)`
  color: white;
  background-color: #5353b9;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 16px;
  transition: background-color 300ms;

  &:hover {
    background-color: #6b6bce;
  }
`;

const Content = styled.div`
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #02020244;
  }
`;

const GifContainer = styled.div`
  width: 245px;
  height: 150px;
  overflow: hidden;
  margin-bottom: 12px;

  & img {
    position: relative;
    transform: scale(1.2) translate(-22%,-27%);
  }
`;
