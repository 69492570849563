console.log('./src/constants/characters.js');

export const characters = [
    "bird",
    "crocodile",
    "linx",
    "llama",
    "men-01",
    "men-02",
    "mrfox",
    "penguin",
    "women-01",
    "women-02"
];