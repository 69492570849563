console.log('./ui/chat/index.js');
import React, {useState, useEffect, useRef} from "react";
import styled    from "styled-components";

import Message   from "./message";
import getSocket from "../../core/socket";
import texts     from "../text";
import getPlayer from "../../core/player";
import { lockPointer, unlockPointer } from "../../core/controls";


export default function ({callbacks}) {

  const [socket, setSocket]     = useState(null);
  const [name, setName ]        = useState(null);
  const [hovered, setHovered]   = useState(false);
  const [focused, setFocused]   = useState(false);
  const [messages, setMessages] = useState([]);
  const [text, setText]         = useState("");
  const input     = useRef(null);
  const scroll    = useRef(null);
  const playerRef = useRef(null);

  const [ privateTarget, setPrivateTarget ] = useState(null);

  useEffect(
    () => {
      const { name }    = callbacks.init();
      const socket      = getSocket();
      playerRef.current = getPlayer();
      console.log({socket})

      setSocket(socket);
      setName(name);

      console.log("checking if jitsi control in messages", control.state);
      control.jitsi.addMessageListener(
        (message, messageList) => {
          console.log("within chat ui got a message", message, messageList)

          setMessages(m => [
            ...m,
            message,
          ]);

        }
      );

      socket.on("join", (id, player) => {
        setMessages(m => [
          ...m,
          {
            type: "welcome",
            author: {
              name : player.name,
              email: player.email,
              id   : id
            }
          },
        ]);
      });

      socket.on("message", ( message) => {
        setMessages(m => [
          ...m,
          message,
        ]);
      });

      socket.on("private", ( message) => {
        setMessages(m => [
          ...m,
          message,
        ]);
      });

      socket.on("left", (id, player) => {
        setMessages(m => [
          ...m,
          {
            type: "left",
            author: {
              name: player.name,
              id  : id
            }
          },
        ]);
      });
  }, //() => {
  []
); // useEffect(


  useEffect(
    () => {
      if (focused) playerRef.current.disableMovement(true);
      else         playerRef.current.disableMovement(false);
    },
    [focused]
  );

  useEffect(
    () => {
      scroll.current.scrollTop = scroll.current.scrollHeight;
    },
    [ hovered ]
  );


  useEffect(
    () => {
      if (hovered) return;

      // Overkill method to get this running after render
      let timer = setTimeout(
        () => {
          scroll.current.scrollTop = scroll.current.scrollHeight;
        },
        1
      );

      return () => clearTimeout(timer);
    },
    [messages]
  );

  useEffect(
    () => {
      if (privateTarget) input.current.focus();
    },
    [privateTarget]
  );


  function socket_emit(...obj){
    console.log(`emit`, obj.flat().join(", "), getSocket());
    // console.log(`emit, '${tag}', '${text}', '${extra}'`);
    getSocket().emit(...obj);
  }


  function handleSubmit(ev) {
    ev.preventDefault();

    if (!input.current.value) return;
    if (privateTarget) control.state.activeRoom.sendMessage(input.current.value, privateTarget.id);
    else               control.state.activeRoom.sendMessage(input.current.value);
    // if (privateTarget) socket_emit("private", input.current.value, privateTarget.id);
    // else               socket_emit("message", input.current.value);

    console.log("mensaje", input.current.value, privateTarget)
    //TODO: figure out how to prevent double showing the message on local message
    // setMessages(m => [
    //   ...m,
    //   {
    //     type   : privateTarget ? "privately" : "me",
    //     message: input.current.value,
    //     author : {
    //         name,
    //       },
    //     ...({to: privateTarget} || {})
    //   },
    // ]);

    // Overkill method to get this running after render
    setTimeout(
      () => {
        scroll.current.scrollTop = scroll.current.scrollHeight;
      },
      1
    );

    setText("");
  }

  return (
    <BottomLeft
      onMouseLeave={() => setHovered(false)}
      onFocus     ={() => setFocused(true)}
      onBlur      ={() => setFocused(false)}
      hovered     ={hovered}
    >
      <MessagesContainer
        ref    ={scroll}
        hovered={hovered}
      >
        { messages.map(
            (message, i) => (
              <Message
                hovered={hovered}
                message={message}
                total  ={messages.length}
                index  ={i}
                key    ={i}
                setPrivateTarget={setPrivateTarget}
              />
            )
          )
        }
      </MessagesContainer>
      <Indications>
        { focused && text && !privateTarget
            ? <label>{texts.ui.chat.enterToSend}</label>
            : null
        }
        { focused && !text && !privateTarget
            ? <label>{texts.ui.chat.clickToExit}</label>
            : null
        }
        { privateTarget
            ? <label>{texts.ui.chat.targetLabel}</label>
            : null
        }
      </Indications>
      <ChatForm
        onSubmit    ={handleSubmit}
        onMouseEnter={() => setHovered(true)}
        hovered     ={hovered}
      >
        <InputWrapper>
          { privateTarget
              ? (
                  <TargetLabel
                    onClick={() => setPrivateTarget(null)}
                  >
                    [{texts.ui.chat.to} <strong>{privateTarget.name}</strong>]
                  </TargetLabel>
                )
              : null
          }
          <ChatInput
            ref        ={input}
            placeholder={texts.ui.chat.writeHere}
            onChange   ={(ev) => setText(ev.target.value)}
            value      ={text}
          />
        </InputWrapper>
      </ChatForm>
    </BottomLeft>
  );
}

const BottomLeft = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  display: flex;
  flex-direction: column;
  width: 350px;

  border-radius: 30px;
  background-color: ${p => "#ffffff44" /* p.hovered ? "#ffffff44" : "transparent"*/};
`;

const ChatForm = styled.form`
background-color: ${p => "#ffffff44" /* p.hovered ? "transparent" : "#ffffff44" */};
  padding: 7px 34px 0px;
  border-radius: 30px;
`;

const MessagesContainer = styled.div`
  color: #000000BB;

  max-height: calc(100vh - 200px);
  width: 300px;

  overflow-y: scroll;
  overflow-x: hidden;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  ${p => true && p.hovered
    ? `
    opacity: 1;
    `
    : `
    & p:nth-last-child(3) {
      opacity: 0.3;
    }
    & p:nth-last-child(2) {
      opacity: 0.6;
    }

    & p {
      transition: opacity 300ms;
    }
  `}

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${p => "#ffffff44" /*p.hovered ? "#ffffff44" : "transparent"*/};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${p => "#ffffffBB" /*p.hovered ? "#ffffffBB" : "transparent"*/};
  }
`;

const InputWrapper = styled.span`
  display: flex;
  flex: 1;
  margin-bottom: 12px;
  align-items: center;
`;

const ChatInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 12px 0;
  flex: 1;
  color: ${p => p.theme.color };

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${p => p.theme.placeholder};
  }

  &::before {
    content: "[${texts.ui.chat.toYou}]";
  }
`;

const TargetLabel = styled.label`
  margin-right: 6px;
  &:hover {
    text-decoration: underline;
  }
`;

const Indications = styled.h5`
  color: ${p => p.theme.color };
  font-size: 12px;
  margin: 6px 34px 6px;
  height: 12px;
  line-height: 12px;
  overflow: hidden;
`;
